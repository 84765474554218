import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import MainFooter from '../../shared/components/MainFooter';
import ProductHeader from './components/ProductHeader';
import ProductInfo from './components/ProductInfo';
import usePageData from '../../shared/hooks/usePageData';
import MetaTags from '../../shared/components/MetaTags';
import { SET_ACTIVE_PRODUCT } from '../../store/ecommerce.reducer';
import NotFound from 'Features/NotFound/NotFound';

export default function ProductContainer({ match, location }) {
  const [selectedProd, setSelectedProd] = useState(null);
  const collection = location.search?.split('=');
  const [fetched, , generic, boxesObj, prodData, lanci] = usePageData(
    `${endpoints.product}/${match.params.slug}${
      collection[1] ? `?tags=${collection[1]}` : ''
    }`,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_ACTIVE_PRODUCT,
      product: prodData,
    });
  }, [dispatch, prodData]);

  // console.log('---- ', fetched, prodData);
  if (fetched) {
    if (prodData) {
      return (
        <div>
          <main className="l-main">
            <MetaTags data={generic} />
            <ProductHeader
              title={prodData.title}
              image={prodData.image}
              selectedProd={
                prodData.products && prodData.products[selectedProd]
                  ? prodData.products[selectedProd]
                  : null
              }
            />
            <Breadcrumbs />
            <ProductInfo
              slug={generic.slug}
              info={prodData}
              lanci={lanci}
              selectedProd={selectedProd}
              setSelectedProd={setSelectedProd}
            />
          </main>
          <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
        </div>
      );
    } else {
      return <NotFound />;
    }
  }

  return <Loader />;
}
