import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JwtDecode from 'jwt-decode';

import { getProfile, getNewsletter, getToken } from '../../store/app.reducer';
import {
  fetchProfile,
  setNewsSubscription,
} from './store/profile.action-creators';
import { logout } from '../Access/access.action-creators';
import HeaderInfo from './components/HeaderInfo/HeaderInfo';
import SlidingSection from './components/SlidingSection/SlidingSection';
import Anagraphic from './components/Anagraphic/Anagraphic';
import MyOrders from './components/MyOrders/MyOrders';
import MyCourses from './components/MyCourses/MyCourses';
import styles from './components/HeaderInfo/HeaderInfo.module.css';
import MyDownloads from './components/MyDownloads/MyDownloads';
import Api from '../../shared/services/api';
import * as endpoints from '../../shared/constants/endpoints';
import AddAddress from './components/AddAddress/AddAddress';

export default function Profile({ location, history }) {
  const profile = useSelector(getProfile);
  const token = useSelector(getToken);
  const newsletter = useSelector(getNewsletter);
  const dispatch = useDispatch();

  const [sales, setSales] = useState([]);

  const jwt = token && JwtDecode(token);

  useEffect(() => {
    dispatch(fetchProfile());

    async function fetchSales() {
      try {
        const data = await Api.get(endpoints.sales).promise;
        setSales(data);
      } catch (e) {
        console.log(e);
      }
    }

    if (!jwt.can_buy) {
      fetchSales();
    }
  }, [dispatch, jwt.can_buy]);

  return (
    <div className="l-container" style={{ margin: '1em auto' }}>
      <HeaderInfo
        profile={profile || {}}
        logout={() => dispatch(logout(history))}
        title="Scheda Anagrafica"
      />
      <SlidingSection title="scheda anagrafica" larger>
        <Anagraphic
          profile={profile || {}}
          newsletter={newsletter}
          setNewsSubscription={() => dispatch(setNewsSubscription(!newsletter))}
        />
      </SlidingSection>
      <SlidingSection title="i miei ordini">
        <MyOrders />
      </SlidingSection>
      <SlidingSection title="academy corsi svolti">
        <MyCourses />
      </SlidingSection>
      <SlidingSection title="download" noPadding>
        <MyDownloads />
      </SlidingSection>
      <SlidingSection
        title="Indirizzi di spedizione"
        noPadding
        startOpen={location.search.includes('address')}
      >
        <AddAddress />
      </SlidingSection>

      {!jwt.can_buy && (
        <div style={{ margin: '2em auto', maxWidth: '15em' }} id="venditori">
          <p className="c-card__content__title">Venditori</p>
          <ul>
            {sales.map(s => (
              <li key={s.contact_id}>
                <p>{s.ragsoc}</p>
                <p>
                  {s.desc} - {s.telefono}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div style={{ textAlign: 'center', marginTop: '4em' }}>
        <div className={styles.links}>
          <a href="#">Servizio clienti</a>
          <a href="#">Normativa privacy</a>
        </div>
      </div>
    </div>
  );
}
