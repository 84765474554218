/* eslint-disable prefer-promise-reject-errors */
export const formatUrl = url => (url.endsWith('/') ? url : `${url}/`);

const makeCancelable = promise => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject('Request canceled') : resolve(val)),
      error => (hasCanceled ? reject('Request canceled') : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

class Api {
  static buildOptions = (method, auth) => ({
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(auth && Api.token && { Authorization: `Bearer ${Api.token}` }),
    },
  });

  static token = localStorage.getItem('token') || null;

  static setToken(t) {
    Api.token = t;
  }

  static get(route, params, auth = true) {
    return this.call(route, params, 'GET', auth);
  }

  static put(route, params) {
    return this.call(route, params, 'PUT');
  }

  static patch(route, params, auth = true) {
    return this.call(route, params, 'PATCH', auth);
  }

  static post(route, params, auth = true) {
    return this.call(route, params, 'POST', auth);
  }

  static delete(route, auth = true) {
    return this.call(route, null, 'DELETE', auth);
  }

  static call(route, params, method, auth) {
    const host = formatUrl(process.env.REACT_APP_API_URL);

    let url = host + route;

    const options = Api.buildOptions(method, auth);

    if (params) {
      if (method === 'GET') {
        url += '?';
        Object.keys(params).forEach(key => (url += `${key}=${params[key]}&`));
        url = url.substring(0, url.length - 1);
      } else {
        options.body = JSON.stringify(params);
      }
    }

    const currentFetch = fetch(url, options).then(resp => {
      const json = resp.json();
      if (resp.ok) {
        return json;
      }
      return json.then(err => {
        // alert(JSON.stringify(err));
        console.log('API ERROR: ', err);
        if (err.code === 401 && err.message !== 'Credenziali non valide.') {
          window.location.reload();
          return;
        }
        throw err;
      });
    });

    return makeCancelable(currentFetch);
  }

  static async downloadFile(path) {
    const res = await fetch(formatUrl(process.env.REACT_APP_API_URL) + path, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return res.blob();
  }
}

export default Api;
