import React from 'react';

import { formatLink } from '../../../shared/components/MainMenu/MenuItem';
import Link from '../../../shared/components/Link';

export default function HomeItemSmall({ items }) {
  return (
    <section className="l-menu">
      {items.map(el => (
        <Link
          className="c-menu-item"
          to={formatLink(el)}
          key={JSON.stringify(el)}
        >
          <figure className="c-picture">
            <img src={el.image} alt="item" />
          </figure>
          <h2 className="c-title">{el.title}</h2>
        </Link>
      ))}
    </section>
  );
}
