import { useEffect, useState } from 'react';

import * as endpoints from '../constants/endpoints';
import Api from '../services/api';

export default function useAgents(match) {
  const [agents, setAgents] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');

  useEffect(() => {
    setSelectedRegion(match.params.search);
  }, [match.params.search]);

  useEffect(() => {
    const request = Api.get(
      endpoints.distribution,
      { search: selectedRegion },
      false,
    );

    async function fetchFunction() {
      try {
        const result = await request.promise;
        if (Array.isArray(result)) {
          setAgents(result.filter(el => el.group !== 'distributori'));
          setDistributors(result.filter(el => el.group === 'distributori'));
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (selectedRegion) {
      fetchFunction();
    }

    return request.cancel;
  }, [selectedRegion]);

  return [agents, distributors];
}
