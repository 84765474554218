/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from './SlidingSection.module.css';

export default function SlidingSection({
  title,
  noPadding,
  light,
  children,
  larger,
  startOpen = false,
}) {
  const [opened, setOpened] = useState(startOpen);
  return (
    <div>
      <div
        tabIndex={0}
        role="button"
        className={styles.container}
        style={{
          backgroundColor: light ? '#fff' : '#cecece',
          outline: 0,
          ...(light && { border: '1px solid #222222' }),
        }}
        onClick={() => setOpened(!opened)}
      >
        <h2>{title}</h2>
        <div className={styles['icon-container']}>
          <FontAwesomeIcon
            icon={opened ? faMinus : faPlus}
            className={styles['expand-icon']}
          />
        </div>
      </div>
      <div
        className={[
          styles['info-container'],
          opened ? (larger ? styles['opened-larger'] : styles.opened) : [],
        ].join(' ')}
      >
        <div
          style={{
            ...(!noPadding && {
              padding: '1em 0',
            }),
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
