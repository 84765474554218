import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';

import styles from './PaymentTypes.module.css';

export default function PaymentTypes({ onRadioPress, payType, style }) {
  return (
    <div className={styles.payment} style={style}>
      {/*               
      <div className={styles['payment-types']}>
          <FontAwesomeIcon
            icon={faCreditCard}
            style={{ fontSize: '1.2em', color: '#212121' }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              value="credit_card"
              name="payType"
              checked={payType === 'credit_card'}
              onChange={onRadioPress}
            />
            <p>Carta di Credito</p>
          </div>
        </div> 
      */}
      <div className={styles['payment-types']}>
        <FontAwesomeIcon
          icon={faMoneyCheckAlt}
          style={{ fontSize: '1.2em', color: '#212121' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="radio"
            value="bank_transfer"
            name="payType"
            checked={payType === 'bank_transfer'}
            onChange={onRadioPress}
          />
          <p>Bonifico Bancario</p>
        </div>
      </div>
      <div className={styles['payment-types']}>
        <FontAwesomeIcon
          icon={faPaypal}
          style={{ fontSize: '1.2em', color: '#212121' }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="radio"
            value="paypal"
            name="payType"
            checked={payType === 'paypal'}
            onChange={onRadioPress}
          />
          <p>Paypal / Carta di credito</p>
        </div>
      </div>
    </div>
  );
}
