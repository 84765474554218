export const SET_CATALOG = 'CATALOG@SET_CATALOG';

export default (state = [], action) => {
  switch (action.type) {
    case SET_CATALOG: {
      return action.catalog;
    }
    default: {
      return state;
    }
  }
};

export const getCatalog = state => state;
