import React, { Fragment, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

import MainFooter from '../../../shared/components/MainFooter';
import useMufeCenters from '../../../shared/hooks/useMufeCenters';
import { CentersAutoComplete } from '../../../shared/components/Distribution/CentriBox';
import ListItem from './components/ListItem';
import Filters from './components/Filters';
import ContactForm from '../../Contacts/components/ContactForm';
import { trimString } from 'shared/services/utils';

let markers = [];

export default function MufeMap({
  match,
  excludeFooter = false,
  excludeContact = false,
  lancioSlug = false,
}) {
  const [
    selectedProv,
    setSelectedProv,
    rawCenters,
    { mCenters, sCenters, aCenters },
  ] = useMufeCenters(lancioSlug);

  const [selectedCenter, setSelectedCenter] = useState(null); // TODO improve naming
  const [selectedType, setSelectedType] = useState('all');
  const [googleApi, setGoogleApi] = useState({});

  useEffect(() => {
    setSelectedProv(decodeURI(match.params.search));
  }, [match.params.search, setSelectedProv]);

  function onProvChange(e) {
    e.persist(); // todo
    if (e.target.value !== '') {
      setSelectedProv(e.target.value);
    } else {
      setSelectedProv(decodeURI(match.params.search));
    }
  }

  function onTypeChange(e) {
    e.persist();
    setSelectedType(e.target.value);
  }

  useEffect(() => {
    const { map, maps } = googleApi;

    if (map && maps) {
      markers.forEach(m => {
        m.setMap(null);
        markers = [];
      });

      const mufeToCenterOnMap = rawCenters.find(
        c => c.latitudine && c.latitudine,
      );
      if (mufeToCenterOnMap) {
        const latLng = new maps.LatLng(
          parseFloat(mufeToCenterOnMap.latitudine),
          parseFloat(mufeToCenterOnMap.longitudine),
        );
        map.panTo(latLng);
      }

      rawCenters.forEach(c => {
        if (selectedType !== 'all' && c.type !== selectedType) {
          return;
        }
        markers.push(
          new maps.Marker({
            position: {
              lat: parseFloat(c.latitudine),
              lng: parseFloat(c.longitudine),
            },
            map,
            title: trimString(c.ragsoc, 20),
          }),
        );
      });
    }
  }, [googleApi, rawCenters, selectedType]);

  return (
    <div>
      <section className="c-distribution-map">
        <div className="c-map">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_MAPS_KEY,
            }}
            defaultCenter={{ lat: 44.4982692, lng: 11.3397673 }}
            defaultZoom={9}
            onGoogleApiLoaded={api => setGoogleApi(api)}
            yesIWantToUseGoogleMapApiInternals
          />
        </div>

        <article
          className="c-agenti-distributori"
          style={{ overflowY: 'scroll', height: '600px' }}
        >
          <div className="l-container">
            <Filters
              selectedProv={selectedProv}
              selectedType={selectedType}
              onTypeChange={onTypeChange}
              onProvChange={onProvChange}
              provs={decodeURI(match.params.search)?.split('|')}
            />
            {!!mCenters.length &&
              (selectedType === 'all' || selectedType === 'M') && (
                <Fragment>
                  <header className="c-agenti-distributori__header">
                    <h2 className="c-title">make up center</h2>
                  </header>

                  <ul className="c-agenti-distributori__list">
                    {mCenters.map((m, i) => (
                      <ListItem item={m} key={i} />
                    ))}
                  </ul>
                </Fragment>
              )}

            {!!aCenters.length &&
              (selectedType === 'all' || selectedType === 'A') && (
                <Fragment>
                  <header className="c-agenti-distributori__header">
                    <h2 className="c-title">les artistes du maquillage</h2>
                  </header>

                  <ul className="c-agenti-distributori__list">
                    {aCenters.map((a, i) => (
                      <ListItem item={a} key={i} />
                    ))}
                  </ul>
                </Fragment>
              )}

            {!!sCenters.length &&
              (selectedType === 'all' || selectedType === 'S') && (
                <Fragment>
                  <header className="c-agenti-distributori__header">
                    <h2 className="c-title">les stylistes du visage</h2>
                  </header>
                  <ul className="c-agenti-distributori__list">
                    {sCenters.map((s, i) => (
                      <ListItem item={s} key={i} />
                    ))}
                  </ul>
                </Fragment>
              )}
          </div>
        </article>
      </section>

      <div className="c-distribution-search c-distribution-search--special">
        <div className="c-distribution-search__content l-container">
          <p>Effettua un'altra ricerca.</p>
          <CentersAutoComplete
            style={{ margin: '2rem auto' }}
            lancioSlug={lancioSlug}
          />
        </div>
      </div>
      {!excludeContact && (
        <div className="l-container">
          <section className="c-distribution">
            <header className="c-distribution__header">
              <h2 className="c-distribution__header__title">
                sei interessato a diventare un nostro agente o rivendere i
                prodotti make up for ever?
              </h2>
              <p>
                Compila il form sottostante con i tuoi riferimenti e sarai
                ricontattato al più presto dal nostro Staff per ricevere
                informazioni e assistenza.
              </p>
            </header>

            <ContactForm
              hideCat
              catID={process.env.REACT_APP_CATEGORY_FORM_CENTRI_MUFE}
              style={{
                backgroundColor: '#dddddd',
                padding: '30px',
                width: '100%',
                justifyContent: 'center',
              }}
              FooterComp={({ onClick }) => (
                <footer
                  className="c-agent-form__footer"
                  style={{ margin: '2em' }}
                >
                  <button type="button" className="c-button" onClick={onClick}>
                    Invia
                  </button>
                </footer>
              )}
            />
          </section>
        </div>
      )}
      {!excludeFooter && <MainFooter dynamicFooter={{}} />}
    </div>
  );
}
