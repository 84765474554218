import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { ClipLoader } from 'react-spinners';
import { withRouter } from 'react-router-dom';

import logoImg from './assets/logo.png';
import MenuItem from './MenuItem';
import Logo from '../Logo';
import LoginForm from './components/LoginForm';
import SearchOverlay from './components/SearchOverlay';
import LoginButton from './components/Buttons/LoginButton';
import ProfileButton from './components/Buttons/ProfileButton';
import * as endpoints from '../../constants/endpoints';
import {
  getProfile,
  getToken,
  getLoginLoading,
} from '../../../store/app.reducer';
import Api from '../../services/api';
import CartButton from './components/Buttons/CartButton';
import Link from '../Link';

function MainMenu({ location, history, setLoginVisible, loginVisible }) {
  const [searchVisible, setSearch] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);

  const loading = useSelector(getLoginLoading);
  const token = useSelector(getToken);
  const profile = useSelector(getProfile);

  useEffect(() => {
    setLoginVisible(false);
  }, [location.pathname, setLoginVisible]);

  useEffect(() => {
    if (!mobileMenu) {
      setLoginVisible(false);
    }
  }, [mobileMenu, setLoginVisible]);

  useEffect(() => {
    async function fetchMenu() {
      try {
        setMenuData(await Api.get(endpoints.menu).promise);
      } catch (e) {
        console.log(e);
      }
    }
    fetchMenu();
  }, []);

  function toggleLogin() {
    setLoginVisible(open => !open);
  }

  function toggleSearch() {
    setSearch(!searchVisible);
    /*TODO set focus all'input quando apro l'overlay di ricerca */
  }

  if (menuData) {
    return (
      <div className="l-header">
        <header
          className={`c-site-header ${loginVisible ? 'j-login-open' : ''} ${
            searchVisible ? 'j-search-open' : ''
          } ${mobileMenu ? 'j-menu-open' : ''}`}
        >
          <div className="l-container l-container--large">
            <Logo img={logoImg} />
            {!mobileMenu ? (
              <a
                role="button"
                tabIndex={0}
                onClick={() => setMobileMenu(!mobileMenu)}
                className="c-site-header__toggle j-open"
              >
                <span className="c-site-header__toggle__item" />
                <span className="c-site-header__toggle__item" />
                <span className="c-site-header__toggle__item" />
              </a>
            ) : (
              <a
                role="button"
                tabIndex={0}
                onClick={() => setMobileMenu(!mobileMenu)}
                className="c-site-header__close"
              >
                X
              </a>
            )}

            <nav className="c-site-header__nav">
              <ul className="c-site-header__menu">
                {Object.keys(menuData).map(section => {
                  const link = menuData[section];
                  let itemType;
                  if (link.children && link.children.length > 0) {
                    itemType = 'smallDropdown';
                    if (
                      link.children.some(
                        sublink =>
                          sublink.children && sublink.children.length > 0,
                      )
                    ) {
                      itemType = 'largeDropdown';
                    }
                  }
                  return (
                    <MenuItem
                      closeMenu={() => {
                        setMobileMenu(false);
                      }}
                      type={itemType}
                      link={link}
                      key={link.text}
                      mobileMenu={mobileMenu}
                    />
                  );
                })}
              </ul>

              <div className="c-site-header__actions">
                {loading && (
                  <ClipLoader size={20} color="#fff" css={{ margin: 10 }} />
                )}

                {!token ? (
                  <LoginButton toggle={toggleLogin} />
                ) : (
                  <Fragment>
                    <ProfileButton profile={profile} />
                    <CartButton />
                    <Link
                      to="/wishlist"
                      className="c-site-header__actions__item"
                    >
                      <FontAwesomeIcon icon={faHeart} />
                    </Link>
                  </Fragment>
                )}
                {/* <SearchButton toggle={toggleSearch} /> */}
              </div>
            </nav>
          </div>

          <LoginForm
            history={history}
            visible={loginVisible}
            toggle={toggleLogin}
            location={location}
          />
          <SearchOverlay
            visible={searchVisible}
            toggle={toggleSearch}
            history={history}
          />
        </header>
      </div>
    );
  }
  return null;
}

export default withRouter(MainMenu);
