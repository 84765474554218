import React from 'react';
import { Link } from 'react-router-dom';

import { formatLink } from '../../../shared/components/MainMenu/MenuItem';

const ServiceItem = ({ item }) => (
  <article className="c-product-item c-product-item--boutique">
    <Link to={`/boutique/${item.link.split(':')[1]}`}>
      <figure className="c-product-item__picture">
        <img alt="servizio" src={item.image} />
      </figure>
      <h3 className="c-title">{item.title}</h3>
    </Link>
    <div
      dangerouslySetInnerHTML={{
        __html: item.body,
      }}
    />
    <footer className="c-product-item__footer">
      <Link className="c-button" to={`/boutique/${formatLink(item)}`}>
        Scopri
      </Link>
    </footer>
  </article>
);

export default function BoutiqueServices({ services }) {
  return (
    <div className="l-container l-products l-3col">
      {services &&
        Object.keys(services).map((serviceName, index) => (
          <ServiceItem item={services[serviceName]} key={index} />
        ))}
    </div>
  );
}
