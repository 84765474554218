import React from 'react';
import { Link as RRLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import { getCatalog, getDatastatic } from '../../store/app.reducer';

export default function Link(props) {
  const { children, to } = props;
  const catalog = useSelector(getCatalog);
  const { academyTypes } = useSelector(getDatastatic);

  if (to) {
    let formattedLink = to
      .replace('external:', '')
      .replace('page:', '')
      .replace('centroMufe:', 'centro_mufe/')
      .replace('product:', 'product/')
      .replace('academyCourse:', 'academy/corso/')
      .replace('boutique:', 'boutique/');

    if (to.includes('boutique_')) {
      formattedLink = 'boutique/' + formattedLink;
    }

    if (to.includes('academy-locations')) {
      formattedLink = 'academy_homepage/dove/' + formattedLink;
    }

    if (to.includes('external:')) {
      return (
        <a
          {...props}
          href={formattedLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    } else if (to.includes('catalog:') && catalog) {
      const requestedCat = to.replace('catalog:', '');
      const cats = [requestedCat];

      let currentCat = catalog.find(c => c.slug === requestedCat);
      while (currentCat?.content?.parent) {
        const parentId = currentCat.content.parent;
        currentCat = catalog.find(c => c.content.cat_id === parentId);
        if (currentCat) {
          cats.unshift(currentCat.slug);
        }
      }

      formattedLink = `/catalog/${cats.join('/')}`;
    } else if (to.includes('academyType:')) {
      const requestedType = academyTypes.find(
        aT => aT.slug === to.replace('academyType:', ''),
      );
      if (requestedType) {
        if (requestedType?.content?.type === 'gbpro') {
          formattedLink = `/academy-gb-pro/${requestedType.slug}`;
        } else {
          formattedLink = `/academy-la-truccheria/${requestedType.slug}`;
        }
      }
    }

    if (!formattedLink.startsWith('/')) {
      formattedLink = `/${formattedLink}`;
    }

    if (to.split(':')?.[2]) {
      formattedLink = formattedLink
        .replace(`:${to.split(':')?.[2]}`, `#${to.split(':')?.[2]}`)
        .replace(`/${to.split(':')?.[2]}`, `#${to.split(':')?.[2]}`);
    }

    if (!formattedLink.includes('#')) {
      return (
        <RRLink {...props} replace to={{ pathname: formattedLink, search: '' }}>
          {children}
        </RRLink>
      );
    }

    return (
      <HashLink {...props} replace to={formattedLink} smooth>
        {children}
      </HashLink>
    );
  }

  return null;
}
