import React, { useState, useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import LinkWrapper from '../Link';

export const formatLink = el => {
  let lnk = el?.link;
  if (lnk) {
    lnk = lnk.replace('page:', '');
    lnk = lnk.replace('catalog:', 'catalog/');
  }

  return lnk;
};

export default function MenuItem({ type, link, mobileMenu, closeMenu }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (mobileMenu) {
      document.body.classList.add('overflow-blocked');
    } else {
      document.body.classList.remove('overflow-blocked');
    }
    setOpen(false);
  }, [mobileMenu]);

  const onMouseEnter = () => setOpen(true);
  const onMouseLeave = () => setOpen(false);

  const smallerThan1024 = useMediaPredicate('(max-width: 1024px)');

  const Link = props => {
    const { children, hasDropdown, className, to, onClick } = props;

    if (smallerThan1024 && hasDropdown) {
      return (
        // <a className={className} onClick={onMouseEnter}>
        //   {children}
        // </a>
        <LinkWrapper to={to} onClick={() => {
            closeMenu();
            setOpen(false);
          }} className={className}>
          {children}
        </LinkWrapper>
      );
    }
    return (
      <LinkWrapper to={to} onClick={onClick} className={className}>
        {children}
      </LinkWrapper>
    );
  };

  if (type === 'smallDropdown') {
    return (
      <li
        className={`c-site-header__menu__item c-site-header__menu__item--has-dropdown ${
          open ? 'j-active' : ''
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Link className="j-submenu" to={link.link} hasDropdown>
          {link.text}
        </Link>
        <nav
          className="c-dropdown-menu"
          style={{ display: open ? 'block' : 'none' }}
        >
          <ul className="c-menu-list">
            {link.children.map(subLink => (
              <li key={subLink.text} className="c-menu-list__title">
                <Link
                  onClick={() => {
                    closeMenu();
                    setOpen(false);
                  }}
                  to={`/${formatLink(subLink)}${
                    subLink.fragment ? `#${subLink.fragment}` : ''
                  }`}
                >
                  {subLink.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </li>
    );
  }
  if (type === 'largeDropdown') {
    return (
      <li
        className={`c-site-header__menu__item c-site-header__menu__item--has-dropdown-wide ${
          open ? 'j-active' : ''
        }`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Link hasDropdown className="j-submenu" to={link.link}>
          {link.text}
        </Link>
        <nav
          className="c-dropdown-menu"
          style={{ display: open ? 'block' : 'none' }}
        >
          <div className="l-container">
            {link.children.map(
              subLink =>
                subLink.children &&
                subLink.children.length > 0 && (
                  <ul key={subLink.text} className="c-menu-list">
                    <li className="c-menu-list__title">
                      <Link
                        onClick={() => {
                          closeMenu();
                          setOpen(false);
                        }}
                        to={subLink.link || ''}
                      >
                        {subLink.text}
                      </Link>
                    </li>

                    {subLink.children.map(deepLink => (
                      <li key={deepLink.text} className="c-menu-list__item">
                        <Link
                          onClick={() => {
                            closeMenu();
                            setOpen(false);
                          }}
                          to={deepLink.link}
                        >
                          {deepLink.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ),
            )}
            <ul className="c-menu-list">
              {link.children.map(
                subLink =>
                  !subLink.children && (
                    <li key={subLink.text} className="c-menu-list__title">
                      <Link
                        onClick={() => {
                          closeMenu();
                          setOpen(false);
                        }}
                        to={subLink.link}
                      >
                        {subLink.text}
                      </Link>
                    </li>
                  ),
              )}
            </ul>
          </div>
        </nav>
      </li>
    );
  }
  return (
    <li
      onClick={() => {
        closeMenu();
        setOpen(false);
      }}
      className="c-site-header__menu__item"
    >
      <Link to={link.link}>{link.text}</Link>
    </li>
  );
}
