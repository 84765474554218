import React, { Fragment } from 'react';
import { Link, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getActiveProduct } from '../../store/app.reducer';

const BreadcrumbsItem = ({ match }) => {
  const activeProd = useSelector(getActiveProduct);

  return (
    <Fragment>
      <span className={`c-breadcrumbs__item ${match.isExact ? 'current' : ''}`}>
        <Link style={{ color: '#000' }} to={match.url || ''}>
          {match.path.startsWith('/product/')
            ? activeProd.title
            : match.params.path}
        </Link>
      </span>
      <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
    </Fragment>
  );
};

export default function Breadcrumbs({ white }) {
  return (
    <section
      className={`c-breadcrumbs ${white ? 'white' : ''}`}
      style={{ color: white ? '#fff !important' : '#000' }}
    >
      <div className="l-container">
        <Link to="/" className="c-breadcrumbs__item">
          Home
        </Link>
        <Route path="/:path" component={BreadcrumbsItem} />
      </div>
    </section>
  );
}
