import React from 'react';

export default function Header({ image, title }) {
  return (
    <div style={{ backgroundColor: '#e40043', position: 'relative' }}>
      <h1
        style={{
          position: 'absolute',
          transform: 'translateY(-50%)',
          top: '50%',
          left: 180,
          zIndex: 10,
          color: '#fff',
          fontSize: 42,
        }}
        className="c-title"
      >
        {title}
      </h1>

      <header
        className="c-hero c-hero--academy"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: image ? 'top' : 'center',
        }}
      >
        <div className="c-hero__container l-container" />
      </header>
    </div>
  );
}
