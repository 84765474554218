import React from 'react';
import Slider from 'react-slick/lib';

function Prev({ onClick }) {
  return (
    <button
      type="button"
      className="slick-truccheria-prev slick-arrow"
      onClick={onClick}
    >
      <span className="icon icon-arrow" />
    </button>
  );
}
function Next({ onClick }) {
  return (
    <button
      type="button"
      className="slick-truccheria-next slick-arrow"
      onClick={onClick}
    >
      <span className="icon icon-arrow" />
    </button>
  );
}

// todo need to adjust in case of window resize
export default function ArtworkSlider({ figures }) {
  let settings = {};
  if (window.innerWidth < 1024) {
    settings = {
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 2,
      slidesPerRow: 2,
      prevArrow: <Prev />,
      nextArrow: <Next />,
    };
  } else {
    settings = {
      dots: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: <Prev />,
      nextArrow: <Next />,
    };
  }
  return (
    <section className="c-slider">
      <Slider {...settings}>
        {figures.map(el => (
          <figure className="c-slider__item" key={Date.now()}>
            <img alt="artworks" src={el.image} />
          </figure>
        ))}
        {figures.map(el => (
          <figure className="c-slider__item" key={Date.now()}>
            <img alt="artworks" src={el.image} />
          </figure>
        ))}
      </Slider>
    </section>
  );
}
