import React from 'react';

import styles from './HeaderInfo.module.css';
import profileIcon from '../../../../assets/img/profile-icon.png';

export default function HeaderInfo({ profile, logout }) {
  return (
    <div className={styles.headerInfoContainer}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <img
          alt="icona profilo"
          src={profileIcon}
          style={{ width: '50px', height: '50px', margin: '0 1em' }}
        />
        <div>
          <h3>Ciao</h3>

          <h1>
            {profile.nome && profile.cognome
              ? `${profile.nome} ${profile.cognome}`
              : 'Utente'}
          </h1>
          <p className={styles.category}>
            {profile.prof || 'Nessuna professione'}
          </p>
        </div>
      </div>
      <div className={styles.links}>
        <a onClick={logout}>Esci</a>
      </div>
    </div>
  );
}
