import React from 'react';

export default function TitleBar({ color, title }) {
  return (
    <div
      style={{
        padding: '1.2em 0',
        backgroundColor: color || '#E4002B',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <h1
        style={{ fontSize: '24px', textTransform: 'uppercase', color: '#fff' }}
      >
        {title}
      </h1>
    </div>
  );
}
