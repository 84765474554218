import React, { useEffect, useState } from 'react';

import styles from './MyCourses.module.css';
import courseItem from '../../../../assets/img/course-item.png';
import Api from '../../../../shared/services/api';
import { doneCourses } from '../../../../shared/constants/endpoints';
import { months } from '../../../../shared/constants/dates';

export default function MyCourses() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await Api.get(doneCourses).promise;
        setCourses(res);
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
  }, []);

  return (
    <div className={styles.container}>
      {courses.map(course => {
        const date = new Date(course.date);
        return (
          <div className={styles['item-container']} key={course.id}>
            <img alt="course" src={course.type.image || courseItem} />
            <div className={styles.info}>
              <h2>{course.type.name}</h2>
              <p className={styles.subtitle}>{course.type.sub_title}</p>
              <p className={styles.description}>
                {`${date.getDate()} ${
                  months[date.getMonth()]
                } ${date.getFullYear()} - ${course.location.city}`}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
