import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SubscribeTopSection from './components/SubscribeTopSection';
import Api from '../../../shared/services/api';
import * as endpoints from '../../../shared/constants/endpoints';
import Loader from '../../../shared/components/Loader';
import MainFooter from '../../../shared/components/MainFooter';

const SubscriptionPayment = ({ match, location }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [html, setHtml] = useState('');
  const payId = match.params.id;

  const payResult = location.search.split('?')?.[1];

  useEffect(() => {
    async function fetchPaymentRes(id) {
      setLoading(true);
      try {
        const res = await Api.get(endpoints.payment + id).promise;
        setHtml(res.body);
      } catch (e) {
        setError('Link di pagamento sbagliato');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (payId) {
      fetchPaymentRes(payId);
    }
  }, [payId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <main className="l-main" style={{ paddingBottom: '2em' }}>
        <SubscribeTopSection />

        {payId && !error && (
          <section
            style={{
              textAlign: 'center',
            }}
            className="c-content-block l-container"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}

        {payId && error && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 className="c-title" style={{ margin: '1em auto' }}>
              {error}
            </h1>
            <Link className="c-button" to="/">
              Torna alla home
            </Link>
          </div>
        )}

        {!payId && payResult === 'success_paypal' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 className="c-title" style={{ margin: '1em auto' }}>
              Pagamento riuscito
            </h1>
            <Link className="c-button" to="/">
              Torna alla home
            </Link>
          </div>
        )}
        {!payId && payResult === 'error_paypal' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h1 className="c-title" style={{ margin: '1em auto' }}>
              Pagamento Fallito
            </h1>
            <p style={{ margin: '1em auto 2em auto' }}>
              Hai ricevuto un link nella mail per il pagamento. Utilizza quel
              link per ripetere l'operazione
            </p>
            <Link className="c-button" to="/">
              Torna alla home
            </Link>
          </div>
        )}
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
};

export default SubscriptionPayment;
