/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import JwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';

import { page } from '../../../shared/constants/endpoints';
import Link from '../../../shared/components/Link';
import {
  ADD_WISH_PROD,
  DEL_WISH_PROD,
  ADD_CART_PROD,
  EDIT_CART_PROD,
} from '../../../store/ecommerce.reducer';
import { getWishlist, getToken, getCart } from '../../../store/app.reducer';
import IVA from '../../../shared/constants/iva';
import usePageData from '../../../shared/hooks/usePageData';

const checkStatus = (info, selProd, status) =>
  selProd !== null
    ? typeof info?.products?.[selProd]?.ref_colore?.[status] !== 'undefined'
    : typeof info?.ref_colore?.[status] !== 'undefined';

export default function ProductInfo({
  slug,
  info,
  selectedProd,
  setSelectedProd,
  lanci,
}) {
  // fetch the relative collection if there is one
  const [, , , boxesObj] = usePageData(lanci[0] ? `${page}/${lanci[0]}` : null);

  const collectionInfo = boxesObj.dynamic_tags?.['product_tag_topsection-6'];

  const dispatch = useDispatch();
  const wishlist = useSelector(getWishlist);
  const token = useSelector(getToken);
  const cart = useSelector(getCart);

  const [isInWish, setIsInWish] = useState(false);
  const jwt = token && JwtDecode(token);

  useEffect(() => {
    setIsInWish(wishlist.includes(slug));
  }, [slug, wishlist]);

  const subProdChange = e => {
    if (e.target.value === '') {
      setSelectedProd(null);
    } else {
      setSelectedProd(e.target.value);
    }
  };

  const toggleWish = () => {
    dispatch({
      type: isInWish ? DEL_WISH_PROD : ADD_WISH_PROD,
      slug,
    });
  };

  const addToCart = () => {
    const subProdRefCode = info?.products?.[selectedProd]?.art_ref_code;

    const exist = cart?.find(
      p => p.slug === slug && p.art_ref_code === subProdRefCode,
    );
    dispatch({
      type: exist ? EDIT_CART_PROD : ADD_CART_PROD,
      slug,
      artCode: info.art_code,
      artRefCode: subProdRefCode,
      qty: exist ? +exist.qty + 1 : null,
    });
    Swal.fire({
      width: 'auto',
      position: 'top-end',
      title: 'Prodotto aggiunto al carrello',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <>
      <style>
        {`.swal2-title {
        font-size: 1em;
      }`}
      </style>
      <article className="c-product">
        <div className="l-container">
          <header className="c-product__header">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1
                className="c-title"
                style={{ marginRight: '.3em', paddingRight: 0 }}
              >
                {info.title}
              </h1>
              {token && (
                <div
                  role="button"
                  onClick={toggleWish}
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{
                      fontSize: '1.2em',
                      color: isInWish ? '#D51A25' : '#dddddd',
                    }}
                  />
                </div>
              )}
            </div>

            <div dangerouslySetInnerHTML={{ __html: info.short_desc }} />

            {checkStatus(info, selectedProd, 'V') && (
              <span className="c-new">Novità</span>
            )}
          </header>

          <figure className="c-product__picture">
            <img
              alt="prodotto"
              src={
                selectedProd !== null
                  ? info?.products?.[selectedProd]?.image
                  : info.image
              }
            />
          </figure>

          <div className="c-product__description">
            {info.products && info.products.length && (
              <div className="c-select-container">
                <select
                  className="c-select"
                  value={selectedProd !== null ? selectedProd : ''}
                  onChange={subProdChange}
                >
                  <option value="">
                    Seleziona un prodotto per aggiungerlo al carrello
                  </option>
                  {info.products.map((prod, index) => (
                    <option key={prod.art_ref_code} value={index}>
                      {prod.title}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {info.products && (
              <div className="c-color-picker">
                {info.products.map((prod, index) => (
                  <span
                    tabIndex={0}
                    role="button"
                    key={prod.art_ref_code}
                    className={`c-color ${
                      index === +selectedProd && selectedProd !== null
                        ? 'selected'
                        : ''
                    }`}
                    style={{
                      backgroundImage: `url('${prod.thumb}')`,
                    }}
                    onClick={() => setSelectedProd(index)}
                  />
                ))}
              </div>
            )}
            <h4 className="c-price">
              Prezzo al {jwt?.list || 'Pubblico'}
              <span>
                {`${info.price}€`}
                {jwt?.list_inc_vat === false && ' + IVA'}
              </span>
            </h4>

            {checkStatus(info, selectedProd, 'G') && (
              <h4 className="c-price">In uscita dal catalogo</h4>
            )}

            {token &&
              jwt.can_buy &&
              !(
                checkStatus(info, selectedProd, 'W') ||
                checkStatus(info, selectedProd, 'R') ||
                checkStatus(info, selectedProd, 'X')
              ) && (
                <div
                  style={{
                    backgroundColor:
                      selectedProd || !info.products ? '#E4002B' : null,
                    color: '#fff',
                    border: 'none',
                  }}
                  className="c-button c-button c-button--ghost"
                  onClick={selectedProd || !info.products ? addToCart : null}
                  title={
                    !selectedProd
                      ? 'Seleziona un prodotto per aggiungerlo al carrello'
                      : 'Aggiungi al carrello'
                  }
                >
                  Aggiungi al carrello
                </div>
              )}
            {!token && (
              <Link
                style={{
                  backgroundColor: '#E4002B',
                  color: '#fff',
                  border: 'none',
                }}
                className="c-button c-button c-button--ghost"
                to="/signup"
                title={
                  !selectedProd
                    ? 'Seleziona un prodotto per aggiungerlo al carrello'
                    : 'Aggiungi al carrello'
                }
              >
                Aggiungi al carrello
              </Link>
            )}
            {checkStatus(info, selectedProd, 'R') && (
              <h4 className="c-price">Esaurito</h4>
            )}

            {checkStatus(info, selectedProd, 'W') && (
              <h4 className="c-price">
                Disponibile dal{' '}
                {selectedProd
                  ? info.products[selectedProd].ref_colore.W
                  : info.ref_colore.W}
              </h4>
            )}

            <footer className="c-product__footer">
              <Link className="c-button" to="/centri_mufe">
                Cercalo in negozio
              </Link>
              <p>Trova il rivenditore Make Up For Ever più vicino a te.</p>
            </footer>
          </div>
        </div>
      </article>

      {(info.desc || info.inci) && (
        <div
          className={`l-container ${info.desc && info.inci ? 'l-2col' : ''}`}
        >
          {info.desc && (
            <article className="c-content">
              <header className="c-content__header">
                <h2 className="c-subtitle">Descrizione</h2>
              </header>
              <div className="c-content__text">
                <div dangerouslySetInnerHTML={{ __html: info.desc }} />
              </div>
            </article>
          )}
          {info.inci && (
            <article className="c-content">
              <header className="c-content__header">
                <h2 className="c-subtitle">Inci</h2>
              </header>
              <div className="c-content__text">
                <div dangerouslySetInnerHTML={{ __html: info.inci }} />
              </div>
            </article>
          )}
        </div>
      )}

      {collectionInfo && (
        <div className="l-container l-1col">
          <article className="c-content">
            {collectionInfo?.video && (
              <video className="c-video" controls>
                <source src={collectionInfo.video} />
              </video>
            )}
            {collectionInfo?.body && (
              <>
                <header className="c-content__header">
                  <h2 className="c-subtitle">La Collezione</h2>
                </header>
                <div className="c-content__text">
                  <p
                    dangerouslySetInnerHTML={{ __html: collectionInfo.body }}
                  />
                </div>
              </>
            )}
          </article>
        </div>
      )}
    </>
  );
}
