import React from 'react';

export default function TopSection({ data: { title, image }, subtitle }) {
  return (
    <header className="c-hero" style={{ display: 'flex' }}>
      <div className="l-container">
        <figure className="c-hero__picture">
          <img alt="header" src={image} />
        </figure>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
          className="c-hero__text-wrapper"
        >
          <h1 className="c-hero__title" style={{ position: 'static' }}>
            {title}
          </h1>
          {subtitle && (
            <h2 className="c-hero__subtititle" style={{ position: 'static' }}>
              {subtitle}
            </h2>
          )}
        </div>
      </div>
    </header>
  );
}
