import React from 'react';
import { useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';

import { getToken } from '../../../store/app.reducer';

export default function AcademyFooter({ data }) {
  const token = useSelector(getToken);

  return (
    !token && (
      <a className="c-cta c-cta--small" href="#">
        <figure className="c-cta__picture">
          <img className="mobile" src={data.image} alt="" />
          <img className="desktop" src={data.image} alt="" />
        </figure>
        <div className="c-cta__text">
          <div
            dangerouslySetInnerHTML={{
              __html: data.body,
            }}
          />
          <HashLink
            smooth
            to="/academy_homepage#ricerca"
            className="c-button c-button--light"
          >
            Iscriviti
          </HashLink>
        </div>
      </a>
    )
  );
}
