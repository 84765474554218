import React from 'react';
import useAnchorOnLoad from 'shared/hooks/useAnchorOnLoad';

import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import TopSection from '../../shared/components/TopSection';
import MainFooter from '../../shared/components/MainFooter';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import usePageData from '../../shared/hooks/usePageData';
import Biografia from './components/Biografia';
import Filosofia from './components/Filosofia';
import Storia from './components/Storia';
import ArtworkSlider from './components/ArtworkSlider';
import MetaTags from '../../shared/components/MetaTags';

export default function CompanyContainer() {
  const [fetched, boxes, generic, boxesObj] = usePageData(
    `${endpoints.page}/company`,
  );
  useAnchorOnLoad(fetched);

  if (fetched) {
    return (
      <div>
        <MetaTags data={generic} />

        <main className="l-main">
          <TopSection data={boxes.static_topsection || {}} />
          <Breadcrumbs />
          <div id="biografia">
            <Biografia data={boxes.static_biografia || []} />
          </div>
          <div id="filosofia">
            <Filosofia data={boxes.static_filosofia || []} />
          </div>
          <div id="gabriele_brunelli">
            <Storia data={boxes.static_gabriele_brunelli || []} />
          </div>
          <ArtworkSlider
            figures={boxes.carousel_gabriele_brunelli_artwork || []}
          />
        </main>
        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
