import { addMonths, format, getDate, setDate } from 'date-fns';
import { it } from 'date-fns/locale';

export const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

export const getNext12Months = () =>
  new Array(12).fill(1).map((_, i) => {
    const monthToReturn = addMonths(new Date(), i);
    const month = setDate(
      monthToReturn,
      i === 0 ? getDate(new Date()) + 1 : getDate(setDate(monthToReturn, 1)),
    );
    return {
      value: format(month, 'yyyy-MM-dd'),
      label: format(month, 'MMMM - yyyy', { locale: it }),
    };
  });
