import React, { Fragment } from 'react';

export default function Location({ info, image }) {
  return (
    <Fragment>
      <header className="c-page-header">
        <h2 className="c-page-title">{info.title}</h2>
      </header>
      <figure className="c-page__picture">
        <img alt="location" src={image} />
      </figure>
      <div className="l-container">
        <div
          className="c-content__text"
          dangerouslySetInnerHTML={{ __html: info.body }}
        />
      </div>
    </Fragment>
  );
}
