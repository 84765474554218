import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import styles from './AddAddress.module.css';
import GenericInput from '../../../Signup/SignupForm/components/GenericInput';
import Api from '../../../../shared/services/api';
import { shippings } from '../../../../shared/constants/endpoints';
import { getDatastatic } from '../../../../store/app.reducer';
import Mandatory from '../../../Signup/SignupForm/components/Mandatory';
import AddressList from '../AddressList/AddressList';

export default function AddAddress() {
  const [formData, setFormData] = useState({ paese: 'IT' });
  const [status, setStatus] = useState('idle');
  const [errors, setErrors] = useState({});
  const { provs, countries } = useSelector(getDatastatic);

  const setForm = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  async function createShipping() {
    try {
      setStatus('pending');
      const res = await Api.post(shippings, formData).promise;
      Swal.fire(
        'Ottimo!',
        res.message || 'Il messaggio è stato correttamente inviato',
        'success',
      );
      setFormData({});
      setStatus('fulfilled');
    } catch (e) {
      setStatus('rejected');
      setErrors(e);
    }
  }

  return (
    <div className={styles.container}>
      <AddressList />
      <h2>Aggiungi un indirizzo</h2>
      <form>
        <div className={styles.inputsContainer}>
          <GenericInput
            error={errors.children?.dest?.errors?.[0]}
            label="Destinatario"
            name="dest"
            value={formData}
            setForm={setForm}
          />
          <GenericInput
            error={errors.children?.indirizzo?.errors?.[0]}
            label="Indirizzo"
            name="indirizzo"
            value={formData}
            setForm={setForm}
          />
          <GenericInput
            error={errors.children?.cap?.errors?.[0]}
            label="Cap"
            name="cap"
            value={formData}
            setForm={setForm}
          />
          <GenericInput
            error={errors.children?.telefono?.errors?.[0]}
            label="Telefono"
            name="telefono"
            value={formData}
            setForm={setForm}
          />

          <GenericInput
            error={errors.children?.citta?.errors?.[0]}
            label="Città"
            name="citta"
            value={formData}
            setForm={setForm}
          />

          <label htmlFor="paese">
            <span>
              Nazione
              <Mandatory />
            </span>
            <select
              name="paese"
              value={formData.paese}
              onChange={setForm}
              style={{ margin: '0', flex: 0 }}
            >
              <option value="">Nazione</option>
              {countries.map(country => (
                <option key={country.key} value={country.key}>
                  {country.value}
                </option>
              ))}
            </select>
            <p
              style={{
                color: '#E50043',
                fontSize: '.9em',
                fontWeight: 'normal',
              }}
            >
              {errors.children?.paese?.errors?.[0]}
            </p>
          </label>

          <label htmlFor="prov">
            <span>
              Provincia
              <Mandatory />
            </span>

            <select
              name="prov"
              value={formData.prov}
              onChange={setForm}
              style={{ margin: '0', flex: 0 }}
            >
              <option value="">Provincia</option>
              {provs.map(prov => (
                <option key={prov.key} value={prov.key}>
                  {prov.value}
                </option>
              ))}
            </select>
            <p
              style={{
                color: '#E50043',
                fontSize: '.9em',
                fontWeight: 'normal',
              }}
            >
              {errors.children?.prov?.errors?.[0]}
            </p>
          </label>

          <GenericInput
            error={errors.children?.ref_nome?.errors?.[0]}
            label="Nome referente"
            name="ref_nome"
            value={formData}
            setForm={setForm}
            notMandatory
          />

          <GenericInput
            error={errors.children?.ref_tel?.errors?.[0]}
            label="Telefono referente"
            name="ref_tel"
            value={formData}
            setForm={setForm}
            notMandatory
          />
        </div>

        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            margin: '2em 0 4em 0',
          }}
        >
          <button
            type="submit"
            className="c-button"
            onClick={e => {
              e.preventDefault();
              return status === 'pending' ? null : createShipping(formData);
            }}
            style={{ opacity: status === 'pending' ? 0.5 : 1 }}
          >
            Aggiungi indirizzo
          </button>
        </div>
      </form>
    </div>
  );
}
