import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

import MainFooter from '../../shared/components/MainFooter';
import Api from '../../shared/services/api';
import * as endpoints from '../../shared/constants/endpoints';
import styles from './PasswordResetAnon.module.css';

function Field({ children, error, style = {} }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
      {children}
      <p style={{ color: '#fff' }}>{error}</p>
    </div>
  );
}

export default function PasswordResetAnon({ location }) {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const token = new URLSearchParams(location.search).get('token');

  const setForm = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    setFormData(s => ({ ...s, token }));
  }, [token]);

  const confirmPwdReset = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await Api.post(endpoints.pwdResetConfirm, {
        token: formData.token,
        plainPassword: {
          first: formData.first,
          second: formData.second,
        },
      }).promise;

      Swal.fire('Ottimo!', res.message || 'Password cambiata', 'success');
    } catch (e) {
      if (e.message) {
        Swal.fire('Errore', e.message, 'error');
      }
      console.log(e);
      setErrors(e?.errors?.children || {});
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <main className="l-main">
        <div className={styles.content}>
          <div className="l-container">
            <h1 style={{ color: '#E4002B', marginTop: '1em' }}>
              Recupero Password
            </h1>
            <form>
              <div style={{ margin: 20 }}>
                <Field
                  error={errors?.plainPassword?.children?.first?.errors?.[0]}
                  style={{ width: '320px', margin: '1em 0' }}
                >
                  <p>Nuova password</p>
                  <input
                    className="c-form__field__input"
                    type="password"
                    name="first"
                    placeholder="Nuova password*"
                    onChange={setForm}
                    value={formData.first || ''}
                  />
                </Field>
                <Field
                  error={errors?.plainPassword?.children?.second?.errors?.[0]}
                  style={{ width: '320px', margin: '1em 0' }}
                >
                  <p>Ripeti nuova password</p>
                  <input
                    className="c-form__field__input"
                    type="password"
                    name="second"
                    placeholder="Ripeti nuova password*"
                    onChange={setForm}
                    value={formData.second || ''}
                  />
                </Field>

                <input
                  value="Invia"
                  type="submit"
                  className="c-button c-button--light"
                  disabled={loading}
                  onClick={confirmPwdReset}
                />
              </div>
            </form>
          </div>
        </div>
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
}
