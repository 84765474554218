import React from 'react';

import './Filters.module.css';

export default function Filters({
  selectedType,
  selectedProv,
  onTypeChange,
  onProvChange,
  provs,
}) {
  return (
    <div className="c-filter-container" style={{ marginBottom: '20px', display: 'flex' }}>
      <select value={selectedType} onChange={onTypeChange}>
        <option value="all">Filtra per centro</option>
        <option value="A">LES ARTISTES DU MAQUILLAGE</option>
        <option value="S">LES STYLISTES DU VISAGE</option>
        <option value="M">MAKE UP CENTER</option>
      </select>
      <select value={selectedProv} onChange={onProvChange}>
        <option value="">Tutte le province</option>
        {provs.map(prov => (
          <option key={prov} value={prov}>
            {prov}
          </option>
        ))}
      </select>
    </div>
  );
}
