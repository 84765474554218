import React from 'react';

export default function ProductHeader({ title, image, selectedProd }) {
  return (
    <header className="c-header-product j-sticky">
      <div className="l-container">
        <span
          className="c-color"
          style={{
            backgroundImage: `url('${
              selectedProd ? selectedProd.thumb : image
            }')`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
          }}
        />
        <h2 className="c-title">{title}</h2>
        {selectedProd && <span>{selectedProd.title}</span>}
      </div>
    </header>
  );
}
