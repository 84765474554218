import {
  LOGIN_SUCCESS,
  LOGOUT,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
} from '../../Access/access.action-types';
import { PROFILE_SUCCESS, EDIT_PROFILE_SUCCESS } from './profile.action-types';

export default (
  state = {
    token: null,
    refreshToken: null,
    profile: null,
    newsletter: null,
    loginLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        loginLoading: false,
      };
    }
    case LOGIN_REQUEST: {
      return {
        ...state,
        loginLoading: true,
      };
    }
    case PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.profile,
        email: action.email,
        loginLoading: false,
        newsletter: action.newsletter,
      };
    }
    case EDIT_PROFILE_SUCCESS: {
      return {
        ...state,
        ...action.edits,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        profile: null,
        token: null,
        refreshToken: null,
      };
    }
    default: {
      return state;
    }
  }
};

export const getProfile = state => state.profile;
export const getToken = state => state.token;
export const getRefreshToken = state => state.refreshToken;
export const getNewsletter = state => !!state.newsletter;
export const getLoginLoading = state => state.loginLoading;
