import React, { Fragment } from 'react';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';

export default function AcademyTopSection({ data = {}, header = {} }) {
  return (
    <Fragment>
      <header
        className="c-hero c-hero--academy-small"
        style={{ backgroundImage: `url(${header.image})` }}
      >
        <div className="c-hero__container l-container">
          <h1 className="c-title c-hero__title">{header.title}</h1>
        </div>
      </header>

      <section className="c-content-block c-content-block--dark">
        <Breadcrumbs white />
        <div className="l-container c-content-block__container l-2col">
          <video className="c-video" controls>
            <source src={data.video} />
          </video>
          <article className="c-content__text">
            <h2 className="c-title c-title--big">{data.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: data.body }} />
          </article>
        </div>
      </section>
    </Fragment>
  );
}
