import React from 'react';

export default function Comments({ comments, info }) {
  return (
    <section className="c-content-block">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header">
          <h2 className="c-title c-title--big c-title--black">Commenti</h2>
        </header>
        <div className="c-content">
          <p
            dangerouslySetInnerHTML={{
              __html: info.body,
            }}
          />

          <div className="c-comments">
            {comments.map(comm => (
              <blockquote className="c-comment" key={comm.name}>
                <div
                  className="c-comment__text c-content__text"
                  dangerouslySetInnerHTML={{
                    __html: comm.body,
                  }}
                />
                <footer className="c-comment__footer">{comm.title}</footer>
              </blockquote>
            ))}
            {/*          <footer className="c-comments__footer">
              <a href="#" className="c-other-comments j-more">
                Mostra altri commenti
              </a>
            </footer> */}
          </div>
        </div>
      </div>
    </section>
  );
}
