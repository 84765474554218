import { useEffect, useState } from 'react';

import { transformBoxes, transformBoxesToObjs } from '../services/utils';
import Api from '../services/api';

export default function usePageData(endpoint) {
  const [data, setData] = useState({
    fetched: false,
    boxes: {},
    boxesObj: {},
    generic: {},
    content: {},
    lanci: [],
  });

  useEffect(() => {
    if (endpoint) {
      const request = Api.get(endpoint);
      // I'm converting the boxes into an object of this shape
      // group: [ all elements with the same group but different name]
      // OR group: {...real_data} if there is no other element with the same group
      async function fetchPageData() {
        try {
          const pageData = await request.promise;
          const transBoxes = transformBoxes(pageData.boxes);
          const transBoxesObj = transformBoxesToObjs(pageData.boxes);
          let tempContent;
          if (pageData.content && Array.isArray(pageData.content)) {
            tempContent = pageData.content;
          } else {
            tempContent = {
              ...pageData.content,
              inci: pageData.inci,
              lanci: pageData.lanci,
              short_desc: pageData.short_desc,
              body: pageData.body,
              media: pageData.media,
            };
          }
          setData(d => ({
            ...d,
            content: tempContent,
            boxes: transBoxes,
            boxesObj: transBoxesObj,
            fetched: true,
            generic: {
              desc: pageData.desc,
              title: pageData.title,
              slug: pageData.slug,
              og_title: pageData.og_title,
              og_type: pageData.og_type,
              og_desc: pageData.og_desc,
              og_image: pageData.og_image,
            },
            lanci: pageData.lanci
              ? pageData.lanci.map(l => l.split(':')[1])
              : [],
          }));
        } catch (e) {
          console.log(e);
          setData(d => ({
            ...d,
            fetched: true,
            content: false,
            boxes: {},
            boxesObj: {},
            generic: {
              desc: '',
              title: '',
              slug: '',
              og_title: '',
              og_type: '',
              og_desc: '',
              og_image: '',
            },
            lanci: [],
          }));
        }
      }

      fetchPageData();

      return request.cancel;
    }
    return undefined;
  }, [endpoint]);

  return [
    data.fetched,
    data.boxes,
    data.generic,
    data.boxesObj,
    data.content,
    data.lanci,
  ];
}
