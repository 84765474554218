import React from 'react';

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M23.237 12.042a11.185 11.185 0 01-11.195 11.195A11.184 11.184 0 01.848 12.042C.848 5.852 5.852.848 12.042.848c6.106 0 11.195 5.004 11.195 11.194zm.848 0C24.085 5.428 18.657 0 12.042 0 5.428 0 0 5.428 0 12.042 0 18.657 5.428 24 12.042 24c6.615 0 12.043-5.343 12.043-11.958z" />
      <path d="M12.042 12.466h-.17a.466.466 0 01-.254-.424V3.053c0-.254.17-.424.424-.424.255 0 .424.17.424.424v7.972l3.477-3.477a.41.41 0 01.594 0 .41.41 0 010 .593l-4.155 4.156c-.17.085-.255.17-.34.17z" />
    </svg>
  );
}

export default ClockIcon;
