import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Api from '../../services/api';
import * as endpoints from '../../constants/endpoints';

export const AgentsAutoComplete = ({ style }) => {
  const [term, setTerm] = useState('');
  const [results, setResults] = useState('');

  useEffect(() => {
    async function fetchResults() {
      const res = await Api.post(endpoints.agentsSearch, { search: term })
        .promise;
      setResults(res.data);
    }

    if (term) {
      fetchResults();
    }
  }, [term]);

  return (
    <footer className="c-retailers" style={style}>
      <div className="l-container" style={{ padding: 0 }}>
        <div className="c-retailers__box">
          <p>Inserisci provincia, regione</p>
          <a className="c-button" href="#">
            <span className="icon icon-search" />
          </a>
        </div>

        <div className="c-retailers__field" style={{ position: 'relative' }}>
          <input
            className="c-form__field__input"
            type="text"
            placeholder="Inserisci provincia, regione"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
          {!!results.length && term && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                backgroundColor: '#fff',
                maxHeight: '150px',
                overflowY: 'scroll',
                border: '1px solid #606060',
                zIndex: 9999,
                color: '#606060',
              }}
            >
              {results.map(el => (
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    display: 'block',
                    margin: '1rem 2rem',
                    color: '#606060',
                  }}
                  to={`/distribuzione/${el.key}`}
                  key={el.key}
                >
                  {el.value}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default function AgentsBox({ data }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        backgroundImage: `url(${data.image})`,
        height: '400px',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.6)',
          display: 'flex',
          flexDirection: 'column',
          padding: '2em 4em',
        }}
      >
        <div
          style={{
            width: '300px',
            alignSelf: 'flex-end',
          }}
        >
          <h1 className="c-title" style={{ fontSize: '2.5em' }}>
            {data.title}
          </h1>
          <div
            style={{
              color: '#ffffff',
              fontFamily: 'Roboto',
              fontSize: 18,
              fontWeight: 400,
              marginBottom: '4em',
            }}
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </div>
        <AgentsAutoComplete />
      </div>
    </div>
  );
}
