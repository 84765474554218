import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import MainFooter from '../../shared/components/MainFooter';
import Api from '../../shared/services/api';
import * as endpoints from '../../shared/constants/endpoints';
import styles from './ForgotPassword.module.css';

function Field({ children, error, style = {} }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
      {children}
      <p style={{ color: '#E4002B' }}>{error}</p>
    </div>
  );
}

export default function ForgotPassword({ history }) {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const setForm = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const resetPwd = async () => {
    try {
      setLoading(true);
      if (!formData.username) {
        setErrors({
          ...errors,
          username: "Inserisci l'username per recuperare la password",
        });
        return;
      }
      const token = await executeRecaptcha();

      const res = await Api.post(
        endpoints.pwdResetRequest,
        { ...formData, 'g-recaptcha': token },
        false,
      ).promise;
      Swal.fire(
        'Ottimo!',
        res.message || 'Una mail è stata inviata per resettare la tua password',
        'success',
      );
      history.push('/');
    } catch (e) {
      if (e.message) {
        Swal.fire('Errore', e.message, 'error');
      } else {
        console.log(e);
        setErrors(errors);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <main className="l-main">
        <div className={styles.content}>
          <div className="l-container">
            <h1 style={{ color: '#E4002B', marginTop: '1em' }}>
              Recupera la tua password
            </h1>
            <form>
              <div style={{ margin: 20 }}>
                <Field
                  error={errors?.username?.children?.errors?.[0]}
                  style={{ width: '320px', margin: '1em 0' }}
                >
                  <p>La tua e-mail</p>
                  <input
                    className="c-form__field__input"
                    type="username"
                    name="username"
                    placeholder="La tua e-mail*"
                    onChange={setForm}
                    value={formData.username || ''}
                  />
                </Field>

                <input
                  value="Invia"
                  type="submit"
                  disabled={loading}
                  className="c-button c-button--light"
                  onClick={resetPwd}
                />
              </div>
            </form>
          </div>
        </div>
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
}
