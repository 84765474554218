import React from 'react';

import DurationIcon from '../../../assets/icons/DurationIcon';
import SkillIcon from '../../../assets/icons/SkillIcon';
import EuroIcon from '../../../assets/icons/EuroIcon';
import ClockIcon from '../../../assets/icons/ClockIcon';

export default function CourseDescription({ data }) {
  return (
    <section className="c-content-block c-content-block--grey">
      <div className="l-container c-content-block__container l-2col">
        <article className="c-content__text">
          <div dangerouslySetInnerHTML={{ __html: data.desc }} />

          <div className="c-course-details">
            <div className="c-course-details__item">
              <DurationIcon />
              <strong>{data.timespan}</strong>
            </div>
            <div className="c-course-details__item">
              <SkillIcon />
              <strong>{data.skill}</strong>
            </div>
            <div className="c-course-details__item">
              <EuroIcon />
              <strong>{data.price} + IVA</strong>
            </div>
          </div>
          <div
            className="c-course-details__item c-course-details__item--small"
            style={{ marginLeft: '-3px' }}
          >
            <ClockIcon />
            <div dangerouslySetInnerHTML={{ __html: data.timespan_desc }} />
          </div>
        </article>
        <article className="c-content__text c-content c-content--rose c-content--program">
          <h2 className="c-title c-title--small">Programma</h2>
          <div dangerouslySetInnerHTML={{ __html: data.program }} />
        </article>
      </div>
    </section>
  );
}
