import React from 'react';
import { CircleLoader } from 'react-spinners';

export default function Loader() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '8em',
      }}
    >
      <CircleLoader size={80} color="#E4002B" />
    </div>
  );
}
