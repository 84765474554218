import React from 'react';
import { HashLink } from 'react-router-hash-link';

export default function SmallNav({ items }) {
  return (
    <nav className="c-category-nav">
      <ul className="c-category-nav__menu">
        {items.map(
          item =>
            item.title && (
              <li className="c-category-nav__menu__item" key={item.name}>
                <HashLink smooth to={`#${item.name}`}>
                  {item.title}
                </HashLink>
              </li>
            ),
        )}
      </ul>
    </nav>
  );
}
