import React from 'react';
import { useSelector } from 'react-redux';

import styles from './../Cart/Cart.module.css';
import { getWishlist } from '../../../store/app.reducer';
import useProducts from '../../../shared/hooks/useProducts';
import Loader from '../../../shared/components/Loader';
import CommonFrame from '../Cart/components/CommonFrame/CommonFrame';
import ProductRow from '../Cart/components/ProductRow';

export default function Wishlist() {
  const products = useSelector(getWishlist);

  const [fetched, prodsData] = useProducts(products);

  if (!fetched) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }

  return (
    <CommonFrame title="Wishlist" subtitle="Articoli">
      <div className="l-container">
        {fetched && !!prodsData?.length && (
          <div className={styles.products}>
            <table>
              <tbody>
                {prodsData.map((p, i) => (
                  <ProductRow
                    mode="wish"
                    info={{ ...p, qty: products?.[i]?.qty }}
                    key={p.art_code}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}

        {fetched && !prodsData?.length && (
          <div
            style={{
              margin: '5em 0',
              textAlign: 'center',
            }}
          >
            <h1 className="c-title c-title--black">
              Devi ancora aggiungere prodotti nella tua wishlist
            </h1>
          </div>
        )}
      </div>
    </CommonFrame>
  );
}
