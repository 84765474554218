import React from 'react';

import MainFooter from '../../shared/components/MainFooter';
import TypologyTab from './components/TypologyTab';
import { CentersAutoComplete } from '../../shared/components/Distribution/CentriBox';
import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import usePageData from '../../shared/hooks/usePageData';
import MetaTags from '../../shared/components/MetaTags';
import style from './CentriMufe.module.css';
import TypologyTabMobile from './components/TypologyTabMobile';
import NotFound from 'Features/NotFound/NotFound';

export default function CentriMufe() {
  const [fetched, , generic, boxesObj, content] = usePageData(
    `${endpoints.page}/${endpoints.centriMufePage}`,
  );

  if (fetched) {
    if (!content) return <NotFound />;

    return (
      <div>
        <MetaTags data={generic} />
        <main className="l-main">
          <div className={`c-distribuzione ${style.container}`}>
            <div className={style.mufeBox}>
              <header className="c-distribution-search__header">
                <h2 className="c-distribution-search__header__title">
                  centri make up forever in italia
                </h2>
              </header>
              <div className="c-distribution-search__content">
                <p>
                  Ecco i centri dove troverai i nostri prodotti e personale
                  formato nella nostra academy
                </p>
              </div>
              <CentersAutoComplete />
              <TypologyTab />
            </div>
          </div>
          <TypologyTabMobile />
        </main>
        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
