import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

import Courses from './Courses';
import { getNext12Months } from '../../../shared/constants/dates';
import useCourses from '../../../shared/hooks/useCourses';
import { getDatastatic } from '../../../store/app.reducer';

export default function SearchCourses({ data }) {
  const { academyLocations, academyTypes } = useSelector(getDatastatic);

  const [month, setMonth] = useState(null);
  const [location, setLocation] = useState(null);
  const [selType, setSelType] = useState(null);

  const courses = useCourses(location, month, selType);
  return (
    <Fragment>
      <section className="c-academy-search" id="ricerca">
        <header
          className="c-academy-search__header"
          style={{
            backgroundImage: `url(${data.academy_search.image})`,
          }}
        >
          <div className="c-academy-search__header-text">
            <h2 className="c-academy-search__title">
              {data.academy_search.title}
            </h2>
            <h3 className="c-academy-search__subtitle">filtra per</h3>
          </div>
        </header>

        <form action="" className="c-form c-form--filter-academy">
          <div className="c-form__field">
            <div className="c-select-container">
              <select
                className="c-select"
                value={location || ''}
                onChange={e => setLocation(e.target.value)}
              >
                <option value="">Città</option>
                {academyLocations.map(loc => (
                  <option key={loc.key} value={loc.key}>
                    {loc.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="c-form__field">
            <div className="c-select-container">
              <select
                className="c-select"
                value={month || ''}
                onChange={e => setMonth(e.target.value)}
              >
                <option value="">Data</option>
                {getNext12Months().map(el => (
                  <option key={el.value} value={el.value}>
                    {el.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="c-form__field">
            <div className="c-select-container">
              <select
                className="c-select"
                value={selType || ''}
                onChange={e => setSelType(e.target.value)}
              >
                <option value="">Corso</option>
                {academyTypes.map(type => (
                  <option key={type.slug} value={type?.content?.id}>
                    {type?.content?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </section>
      {(month || location || selType) && !!courses.length && (
        <div style={{ margin: 'auto', width: '80%' }}>
          <Courses courses={courses} />
        </div>
      )}
    </Fragment>
  );
}
