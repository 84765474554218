import React from 'react';
import { Link } from 'react-router-dom';

import { formatLink } from '../../../shared/components/MainMenu/MenuItem';

export default function Box({ data }) {
  return (
    <Link to={`/${formatLink(data)}`} className="c-excerpt">
      <figure className="c-excerpt__figure">
        <img src={data.image} alt="truccheria make up academy" />
      </figure>
      <article className="c-excerpt__content">
        <h3 className="c-excerpt__title c-title">{data.title}</h3>
        <div
          className="c-excerpt__text"
          dangerouslySetInnerHTML={{
            __html: data.body,
          }}
        />
        <footer className="c-excerpt__footer">
          <span className="c-button c-button--light">Scopri tutti i corsi</span>
        </footer>
      </article>
    </Link>
  );
}
