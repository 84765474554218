import { useEffect, useState } from 'react';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';

export default function useMufeCenters(lancioSlug = '') {
  const [rawCenters, setRawCenters] = useState([]);
  const [orderedCenters, setOrderedCenters] = useState({
    mCenters: [],
    aCenters: [],
    sCenters: [],
  });
  // TODO refactor to use param from function
  const [selectedProv, setSelectedProv] = useState('');

  useEffect(() => {
    if (!selectedProv) return () => null;
    const request = Api.get(
      !!lancioSlug
        ? `${endpoints.mufeByLancio}/${lancioSlug}`
        : endpoints.mufeByProv,
      { search: selectedProv },
      false,
    );

    async function fetchCenters() {
      try {
        const result = await request.promise;

        const ordering = {
          mCenters: [],
          aCenters: [],
          sCenters: [],
        };

        result.forEach(center => {
          if (center.type === 'S') ordering.sCenters.push(center);
          if (center.type === 'A') ordering.aCenters.push(center);
          if (center.type === 'M') ordering.mCenters.push(center);
        });

        setRawCenters(result);
        setOrderedCenters(ordering);
      } catch (err) {
        console.log('Errore nel riprendere i centri', err);
      }
    }

    fetchCenters();

    return request.cancel;
  }, [selectedProv, lancioSlug]);

  return [selectedProv, setSelectedProv, rawCenters, orderedCenters];
}
