import React from 'react';

import MainFooter from '../../shared/components/MainFooter';
import styles from './NotFound.module.css';
import Link from '../../shared/components/Link';
import { Helmet } from 'react-helmet';

export default function NotFound() {
  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div>
        <main className="l-main">
          <div className={styles.content}>
            <div className="l-container">
              <h1
                style={{
                  color: '#E4002B',
                  textAlign: 'center',
                }}
              >
                Pagina non trovata
              </h1>
              <Link className="c-button" to="/">
                Torna alla home
              </Link>
            </div>
          </div>
        </main>
        <MainFooter dynamicFooter={{}} />
      </div>
    </>
  );
}
