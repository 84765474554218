import React from 'react';

import useShippings from '../../../../shared/hooks/useShippings';
import styles from './AddressList.module.css';

function AddressList() {
  const { shippings } = useShippings();

  return (
    <ul className={styles['address-list']}>
      {shippings?.map(s => (
        <li key={s.sped_id} style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <p>{s.dest}</p>
            <p>
              Indirizzo: {s.indirizzo}
              {s.citta ? `, ${s.citta}` : ''}
              {s.cap ? `, ${s.cap}` : ''}
              {s.prov ? `, ${s.prov}` : ''}
            </p>
            <p>Tel: {s.telefono}</p>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default AddressList;
