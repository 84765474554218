import React from 'react';

import { ProdItem } from '../Catalog/Category';

export default function ProdsList({ products }) {
  return (
    <div className="l-container l-products l-3col">
      {products.map(prod => (
        <ProdItem
          key={prod.slug}
          prod={prod}
          linkTo={
            prod?.content?.tags?.[0] &&
            `/product/${prod.slug}?tag=${prod.content.tags[0]}`
          }
        />
      ))}
    </div>
  );
}
