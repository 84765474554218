/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile } from '@fortawesome/free-solid-svg-icons';
import downloadjs from 'downloadjs';

import * as endpoints from '../../../../shared/constants/endpoints';
import Api from '../../../../shared/services/api';
import styles from './MyDownloads.module.css';
import SlidingDownloadSection from './SlidingDownloadSection';

async function downloadFile(id, fileName) {
  try {
    const blobFile = await Api.downloadFile(`${endpoints.resource}/${id}`);
    downloadjs(blobFile, fileName);
  } catch (e) {
    console.log(e);
  }
}

function DownloadItem({ item, resources }) {
  const [opened, setOpened] = useState(false);
  const childrenRes = resources.filter(child => child.parent === item.id);
  return (
    <div
      style={item.type !== 'dir' ? { maxHeight: '3em' } : {}}
      className={[styles.item, opened ? styles.opened : []].join(' ')}
      onClick={
        childrenRes && childrenRes.length
          ? e => {
              e.stopPropagation();
              setOpened(!opened);
            }
          : null
      }
      role="button"
      tabIndex={0}
    >
      <FontAwesomeIcon
        icon={item.type === 'dir' ? faFolder : faFile}
        style={{ fontSize: 24 }}
      />
      <div>
        {item.type === 'dir' ? (
          <p>{item.name}</p>
        ) : (
          <a
            onClick={e => {
              e.stopPropagation();
              downloadFile(
                item.id,
                `${item.name}${item.extension && `.${item.extension}`}`,
              );
            }}
          >
            {item.name}
          </a>
        )}
        <p className={styles['item-desc']}>{item.body}</p>
        {childrenRes &&
          childrenRes.map(child => (
            <DownloadItem key={child.id} item={child} resources={resources} />
          ))}
      </div>
    </div>
  );
}

export default function MyDownloads() {
  const [resources, setResources] = useState([]);
  const [topResources, setTopResources] = useState([]);

  useEffect(() => {
    async function fetchResources() {
      try {
        const data = await Api.get(endpoints.resource).promise;
        setTopResources(data.filter(res => res.tags.includes('top')));
        setResources(data.filter(res => !res.tags.includes('top')));
      } catch (e) {
        console.log(e);
      }
    }

    fetchResources();
  }, []);

  return (
    <Fragment>
      <SlidingDownloadSection
        title="in evidenza"
        openedByDefault={!!topResources.length}
      >
        <div
          style={{
            width: '80%',
            margin: '1em auto',
          }}
        >
          {topResources.map(res => (
            <DownloadItem key={res.id} item={res} resources={topResources} />
          ))}
        </div>
      </SlidingDownloadSection>
      <div style={{ width: '80%', margin: '1em auto' }}>
        {resources.map(
          res =>
            !res.parent && (
              <DownloadItem key={res.id} item={res} resources={resources} />
            ),
        )}
      </div>
    </Fragment>
  );
}
