import React from 'react';
import Loader from 'shared/components/Loader';
import * as endpoints from 'shared/constants/endpoints';
import MainFooter from 'shared/components/MainFooter';
import usePageData from 'shared/hooks/usePageData';
import MetaTags from 'shared/components/MetaTags';

import styles from './GenericPrivacyPage.module.css';

export default function GenericPrivacyPage({ match, objProps }) {
  const [fetched, , generic, boxesObj] = usePageData(
    `${endpoints.page}${match.path}`,
  );

  const body = objProps?.split('.')?.reduce((acc, el) => {
    if (!acc) {
      return boxesObj?.[el];
    }
    return acc?.[el];
  }, null);

  if (fetched) {
    return (
      <div>
        <MetaTags data={generic} />
        <main className="l-main">
          <div className="l-container">
            <h1 className={styles.title}>{body?.title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: body?.body,
              }}
              className={styles.paragraph}
            />
          </div>
        </main>
        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
