import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo({ img }) {
  return (
    <Link className="c-site-header__logo" to="/">
      <img alt="logo" src={img} />
    </Link>
  );
}
