import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Api from '../../../shared/services/api';
import * as endpoints from '../../../shared/constants/endpoints';
import { fetchProfile } from '../store/profile.action-creators';
import { getProfile } from '../../../store/app.reducer';
import SubscribeTopSection from './components/SubscribeTopSection';
import Anagraphic from '../components/Anagraphic/Anagraphic';
import SlidingSection from '../components/SlidingSection/SlidingSection';
import PaymentTypes from './components/PaymentTypes';

function Subscribe({ match, fetchProfile: doReq, profile, history }) {
  const [payType, setPayType] = useState('');

  useEffect(() => {
    doReq();
  }, [doReq]);

  const onRadioPress = ({ target }) => setPayType(target.value);

  const subscribe = async () => {
    try {
      // TODO make pay type choosable
      const res = await Api.post(
        endpoints.subscribeCourse + match.params.course,
        {
          payment_type: payType,
        },
      ).promise;

      if (!res.payment_id) {
        throw res;
      }

      history.push(`/payment/${res.payment_id}`);
    } catch (e) {
      Swal.fire('Errore', 'Si è verificato un errore', 'error');
      console.log(e);
    }
  };

  return (
    <div>
      <main className="l-main">
        <SubscribeTopSection />
        <section className="c-content-block l-container">
          <SlidingSection title="scheda anagrafica" larger startOpen>
            <Anagraphic profile={profile || {}} />
          </SlidingSection>
          <SlidingSection title="metodi di pagamento" larger startOpen>
            <PaymentTypes onRadioPress={onRadioPress} payType={payType} />
            <a
              onClick={subscribe}
              className="c-button c-button--long"
              style={{ maxWidth: '3em', margin: '1em auto', display: 'block' }}
            >
              Procedi all'acquisto
            </a>
          </SlidingSection>
        </section>
      </main>
    </div>
  );
}

export default connect(
  state => ({ profile: getProfile(state) }),
  { fetchProfile },
)(Subscribe);
