import React from 'react';

import Link from '../../../shared/components/Link';

export default function PartnersDetail({ data }) {
  if (!data?.partners_links?.length) return null;

  return (
    <section className="c-content-block c-content-block--center">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header">
          <h2 className="c-title c-title--small c-title--black">Partners</h2>
        </header>
        <div className="c-content">
          <div
            dangerouslySetInnerHTML={{
              __html: data.partners_detail,
            }}
          />
          <div className="c-logo-grid">
            {data.partners_links?.map(el => (
              <Link key={el.link} to={el.link} className="c-logo-grid__logo">
                <img src={el.image} style={{ height: '3rem' }} alt="partner" />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
