import { useEffect, useState } from 'react';

import Api from '../services/api';

export default function useCourseDates(endpoint) {
  const [fetching, setFetching] = useState(false);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      try {
        const data = await Api.get(endpoint).promise;
        setDates(data);
      } catch (e) {
        console.log(e);
      } finally {
        setFetching(false);
      }
    }

    fetchData();
  }, [endpoint]);

  return { fetching, dates };
}
