import React from 'react';

function EuroIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M12 23.07C5.915 23.07.93 18.085.93 12S5.83.93 12 .93C18.169.93 23.07 5.915 23.07 12S18.085 23.07 12 23.07zM12 0C5.408 0 0 5.408 0 12s5.408 12 12 12 11.915-5.408 11.915-12S18.592 0 12 0z" />
      <path d="M16.901 15.972a.512.512 0 00-.676 0c-1.014 1.183-2.366 1.774-3.802 1.774-2.451 0-4.564-1.859-5.155-4.394h5.662c.253 0 .422-.169.422-.422 0-.254-.169-.423-.422-.423H7.183v-1.014h5.747c.253 0 .422-.169.422-.423 0-.253-.169-.422-.422-.422H7.268c.591-2.535 2.704-4.394 5.155-4.394 1.436 0 2.788.591 3.802 1.774.17.17.423.17.676 0 .17-.169.17-.422 0-.676C15.718 6 14.113 5.24 12.423 5.24c-2.958 0-5.493 2.282-6.085 5.324H5.239c-.253 0-.422.17-.422.423s.169.422.422.422h1.015v1.014H5.239c-.253 0-.422.169-.422.422 0 .254.169.423.422.423h1.099c.592 3.042 3.127 5.324 6.085 5.324 1.69 0 3.295-.761 4.478-2.113.254-.085.17-.338 0-.507z" />
    </svg>
  );
}

export default EuroIcon;
