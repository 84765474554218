import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Api from '../../services/api';
import * as endpoints from '../../constants/endpoints';

export const CentersAutoComplete = ({ style, lancioSlug }) => {
  const [term, setTerm] = useState('');
  const [results, setResults] = useState('');

  useEffect(() => {
    async function fetchResults() {
      const res = await Api.post(
        !!lancioSlug ? endpoints.lanciSearch : endpoints.mufeSearch,
        {
          search: term,
        },
      ).promise;
      setResults(res.data);
    }

    if (term) {
      fetchResults();
    }
  }, [term]);

  return (
    <footer className="c-retailers" style={style}>
      <div className="l-container l-container--retailers">
        <div className="c-retailers__box">
          <p>Inserisci provincia, regione</p>
          <a className="c-button" href="#">
            <span className="icon icon-search" />
          </a>
        </div>

        <div className="c-retailers__field" style={{ position: 'relative' }}>
          <input
            className="c-form__field__input"
            type="text"
            placeholder="Inserisci provincia, regione"
            value={term}
            onChange={e => setTerm(e.target.value)}
          />
          {!!results.length && term && (
            <div
              style={{
                position: 'absolute',
                width: '100%',
                backgroundColor: '#fff',
                height: '150px',
                overflowY: 'scroll',
                zIndex: 9999,
                color: '#606060',
              }}
            >
              {results.map(el => (
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  style={{ display: 'block', margin: '1rem 2rem' }}
                  to={
                    !!lancioSlug
                      ? `/${lancioSlug}?key=${el.key}`
                      : `/centri_mufe/${el.key}`
                  }
                  key={el.key}
                >
                  {el.value}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default function CentriBox({ data }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        flex: 1,
        backgroundImage: `url(${data.image})`,
        height: '400px',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.6)',
        }}
      >
        <div style={{ margin: '2em', marginLeft: '4.2em' }}>
          <h1 className="c-title" style={{ fontSize: '2.5em' }}>
            {data.title}
          </h1>
          <div
            style={{
              color: '#ffffff',
              fontFamily: 'Roboto',
              fontSize: 18,
              fontWeight: 400,
            }}
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </div>
        <CentersAutoComplete />
      </div>
    </div>
  );
}
