import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './Payment.module.css';
import Links from './Links';
import CommonFrame from '../components/CommonFrame/CommonFrame';
import Divider from '../../components/Divider';

function TransferBody() {
  return (
    <div className={styles.body}>
      <div className={`${styles.box} l-container`}>
        <FontAwesomeIcon
          icon={faMoneyCheckAlt}
          style={{ fontSize: '1.7em', color: '#212121' }}
        />
        <p>Bonifico Bancario</p>
      </div>
      <Divider thickness="1px" />
      <div className={`l-container ${styles['info-text']}`}>
        <p className={styles.text}>Eseguire un Bonifico Bancario a favore di</p>
        <p className={styles.info}>
          La Truccheria Srl
          <br />
          Numero di conto: 010000009784
          <br />
          IBAN: IT37B0303202400010000009784
          <br />
          SWIFT: BACRIT22
          <br />
          Istituto bancario: CREDITO EMILIANO S.P.A.
          <br />
          Filiale: Sede di Bologna
        </p>
        <p className={styles.text}>
          ATTENZIONE: inserire obbligatoriamente il <b>Numero Ordine</b> nella
          causale del bonifico.
          <br />
          In mancanza di tale informazione l’ordine verrà evaso in tempi più
          lunghi.
          <br />
          <br />
          <b>L’ordine verrà evaso dopo la ricezione del pagamento.</b>
        </p>
        <Link to="/profile">
          <span
            className="c-button c-button--light"
            style={{
              fontWeight: 'bold',
              margin: '2em 0',
              fontSize: '1.5em',
            }}
          >
            Conferma il pagamento
          </span>
        </Link>
      </div>
    </div>
  );
}

function PaypalBody() {
  return (
    <div className={styles.body}>
      <p>Test</p>
    </div>
  );
}

export default function Result({ match }) {
  const transfer = match.params.type === 'transfer';
  return (
    <CommonFrame links={<Links />} title="cassa" subtitle="Pagamento">
      {transfer ? <TransferBody /> : <PaypalBody />}
    </CommonFrame>
  );
}
