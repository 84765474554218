import React from 'react';

import Link from '../../../shared/components/Link';

export default function Subscription({ data }) {
  if (!data.subscription_products?.length && !data.subscription_detail)
    return null;

  return (
    <section className="c-content-block c-content-block--center">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header" />
        <h2 className="c-title c-title--small c-title--black">Iscrizione</h2>
        <div className="c-content c-content--small">
          <div
            dangerouslySetInnerHTML={{
              __html: data.subscription_detail,
            }}
          />
          <div className="l-2col c-offers">
            {data.subscription_products &&
              data.subscription_products.map(prod => (
                <Link to={prod.link || ''} className="c-offer" key={prod.link}>
                  <figure className="c-offer__picture">
                    <img src={prod.image} alt="prodotto" />
                  </figure>
                  {prod.text && <span className="c-button">{prod.text}</span>}
                </Link>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
}
