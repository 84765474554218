import React from 'react';

import styles from './MufeBox.module.css';
import { CentersAutoComplete } from '../../shared/components/Distribution/CentriBox';

export default function MufeBox({ tot, lancioSlug }) {
  return (
    <div className={styles.container}>
      <div className={styles.searchBox}>
        <header className="c-distribution-search__header">
          <h2 className="c-distribution-search__header__title">
            {tot && tot + ' '}Rivenditori
          </h2>
        </header>
        <div className="c-distribution-search__content">
          <p>
            Ecco i centri dove troverai i nostri prodotti e personale formato
            nella nostra academy
          </p>
        </div>
        <CentersAutoComplete lancioSlug={lancioSlug} />
      </div>
    </div>
  );
}
