import React from 'react';
import { HashLink } from 'react-router-hash-link';

export default function CourseProgram({ data }) {
  return (
    <section className="c-content-block c-content-block--grey">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header">
          <h2 className="c-title c-title--small c-title--black">
            Programma del corso
          </h2>
        </header>
        <div
          className={`${
            data.program_images && data.program_images.length
              ? 'l-2col l-2col--small'
              : 'c-content__text c-content__text--2col '
          }`}
        >
          <div className="c-program-course">
            <div
              className="c-program-course__item"
              dangerouslySetInnerHTML={{ __html: data.program_detail }}
            />
          </div>
          <div className="c-gallery-program">
            {data.program_images &&
              data.program_images.map(el => (
                <figure className="c-gallery-program__item" key={el.image}>
                  <img src={el.image} alt="programma" />
                </figure>
              ))}
          </div>
        </div>
        <footer className="c-content-block__footer">
          <HashLink
            smooth
            to="/academy_homepage#ricerca"
            className="c-button c-button--long"
          >
            Iscriviti
          </HashLink>
        </footer>
      </div>
    </section>
  );
}
