import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { transformBoxesToObjs } from 'shared/services/utils';
import Api from 'shared/services/api';
import * as endpoints from 'shared/constants/endpoints';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import MainFooter from 'shared/components/MainFooter';
import useCategory from 'shared/hooks/useCategory';
import MetaTags from 'shared/components/MetaTags';
import Loader from 'shared/components/Loader';
import NotFound from 'Features/NotFound/NotFound';

function CategoryItem({ cat, match, location }) {
  const [, products, loading] = useCategory(cat.slug);

  if (loading) return <Loader />;

  const prefixUrl = match?.isExact ? match?.url : location?.pathname;

  return (
    <>
      <Link
        to={`${prefixUrl}/${cat.slug}`}
        className="c-title"
        style={{
          margin: '0em auto 0em auto',
          textAlign: 'center',
          display: 'block',
        }}
      >
        {cat.content.title}
      </Link>

      <div className="l-container l-products l-3col">
        {products.map(prod => (
          <ProdItem key={prod.slug} prod={prod} />
        ))}
      </div>
    </>
  );
}

const checkStatus = (prod, status) =>
  typeof prod.content?.ref_colore?.[status] !== 'undefined';

const StatusText = ({ children }) => (
  <h4 style={{ fontWeight: 'bold', color: '#E4002B' }}>{children}</h4>
);

export function ProdItem({ prod, linkTo = null }) {
  return (
    <article className="c-product-item">
      {checkStatus(prod, 'V') && (
        <span
          style={{
            position: 'absolute',
            left: -20,
            top: -20,
            borderRadius: 75,
            fontSize: 18,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 70,
            height: 70,
            backgroundColor: '#E4002B',
            color: '#fff',
            zIndex: 9999,
          }}
        >
          Novità
        </span>
      )}
      <Link to={linkTo || `/product/${prod.slug}`}>
        <figure className="c-product-item__picture">
          <img alt="catalog" src={prod.content.image} />
        </figure>
        <h2 className="c-title">{prod.content.title}</h2>
        {checkStatus(prod, 'R') && <StatusText>Esaurito</StatusText>}
        {checkStatus(prod, 'W') && (
          <StatusText>Disponibile dal {prod.content.ref_colore.W}</StatusText>
        )}
        {checkStatus(prod, 'G') && (
          <StatusText>In uscita dal catalogo</StatusText>
        )}
      </Link>
    </article>
  );
}

function Categories({ categories, match, location }) {
  const slug = match?.isExact ? match?.params?.slug : location?.pathname?.split('/').pop();
  const currentCat = categories.find(el => el.slug === slug);
  const [content, products] = useCategory(slug);
  const newRoute = '/catalog/' + location.pathname.replace(/^\/catalog\//, '').split('/').map(function() { return ':slug'; }).join('/');

  return !currentCat ? <NotFound /> : (
    <>
      {
        <>
          <MetaTags data={content} />
          <div className="l-container l-products l-3col">
            {products.map(prod => (
              <ProdItem key={prod.slug} prod={prod} />
            ))}
          </div>
          {(categories.map(
            cat =>
              cat.content.parent === currentCat.content.cat_id && (
                <CategoryItem
                  key={cat.slug}
                  cat={cat}
                  match={match}
                  location={location}
                  currentCat={currentCat}
                />
              ),
          ))}
        </>
      }
      <Route
        exact
        path={`${newRoute}/:slug`}
        component={props => <Categories {...props} categories={categories} />}
      />
    </>
  );
}

export default function Category({ match, location }) {
  const [categories, setCatalog] = useState(null);
  const [boxesObj, setBoxes] = useState({});

  useEffect(() => {
    async function fetchCat() {
      try {
        const res = await Api.get(endpoints.catalog).promise;
        if (res.content) {
          setCatalog(res.content);
          const transBoxesObj = transformBoxesToObjs(res.boxes);
          setBoxes(transBoxesObj);
        } else {
          throw res;
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchCat();
  }, []);

  if (categories) {
    return (
      <div>
        <main className="l-main">
          <Breadcrumbs />
          <Categories categories={categories} match={match} location={location} />
        </main>

        <MainFooter dynamicFooter={boxesObj.static_footer} />
      </div>
    );
  }

  return <Loader />;
}
