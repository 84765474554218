import React from 'react';

import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import TopSection from '../../shared/components/TopSection';
import MainFooter from '../../shared/components/MainFooter';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import BoutiqueServices from './components/BoutiqueServices';
import Location from './components/Location';
import Contacts from './components/Contacts';
import usePageData from '../../shared/hooks/usePageData';
import MetaTags from '../../shared/components/MetaTags';
import { CentersAutoComplete } from '../../shared/components/Distribution/CentriBox';
import NotFound from 'Features/NotFound/NotFound';

export default function BoutiqueContainer() {
  const [fetched, , generic, boxesObj, content] = usePageData(
    `${endpoints.page}/boutique`,
  );

  if (fetched) {
    if (!content) {
      return <NotFound />;
    }
    return (
      <div>
        <MetaTags data={generic} />
        <main className="l-main">
          <TopSection
            data={
              (boxesObj.boutique_topsection &&
                boxesObj.boutique_topsection.boutique_topsection) ||
              {}
            }
          />
          <Breadcrumbs />
          <BoutiqueServices services={boxesObj.boutique_services} />
          <section className="c-page-block c-page-block--picture">
            <Location
              info={boxesObj.boutique_location?.boutique_location_map || {}}
              image={
                boxesObj.boutique_location?.boutique_location_picture?.image ||
                {}
              }
            />
            <Contacts
              data={
                (boxesObj.boutique_location &&
                  boxesObj.boutique_location.boutique_location_desc) ||
                {}
              }
              mapData={
                (boxesObj.boutique_location &&
                  boxesObj.boutique_location.boutique_location_picture) ||
                {}
              }
            />
            <p
              style={{
                color: '#E4002B',
                fontSize: '1.5em',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Cerca il rivenditore MAKE UP FOREVER più vicino a te
            </p>

            <CentersAutoComplete
              style={{ width: '60%', margin: '1em auto 4em auto' }}
            />
          </section>
        </main>
        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
