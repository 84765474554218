import React, { useEffect, useState } from 'react';
import Api from 'shared/services/api';
import usePageData from 'shared/hooks/usePageData';
import { page } from 'shared/constants/endpoints';
import Loader from 'shared/components/Loader';
import MetaTags from 'shared/components/MetaTags';
import MainFooter from 'shared/components/MainFooter';
import Breadcrumbs from 'shared/components/Breadcrumbs';
import * as endpoints from 'shared/constants/endpoints';
import NotFound from 'Features/NotFound/NotFound';

import ProdsList from './ProdsList';
import MufeBox from './MufeBox';
import MufeMap from '../CentriMufe/MufeMap/MufeMap';

export default function Lancio({ slug, location }) {
  const [tot, setTot] = useState(0);
  const [fetched, , generic, boxesObj, content] = usePageData(
    `${page}/${slug}`,
  );

  useEffect(() => {
    async function fetchTotCenter() {
      try {
        const tot = await Api.get(`${endpoints.mufeByLancio}/${slug}`).promise;
        setTot(tot.total);
      } catch (e) {
        console.log(e);
      }
    }

    fetchTotCenter();
  }, [slug]);

  let info = null;

  if (boxesObj?.dynamic_tags) {
    let infoKey = '';
    Object.keys(boxesObj.dynamic_tags).forEach(k => {
      if (k.includes('product_tag_topsection')) {
        infoKey = k;
      }
    });

    info = boxesObj.dynamic_tags?.[infoKey];
  }

  if (!fetched && !info) return <Loader />;

  if (!content) return <NotFound />;

  return (
    <>
      <MetaTags data={generic} />
      <img
        alt="immagine collezione"
        src={info.image}
        style={{ width: '100%', objectFit: 'cover', height: 315 }}
      />
      <main className="l-main">
        <Breadcrumbs />
        <h2
          className="c-title"
          style={{
            color: '#484848',
            textAlign: 'center',
            marginTop: 12,
          }}
        >
          {info.title}
        </h2>
        <ProdsList products={content} />
        {tot && (
          <>
            {!location.search.split('key=')?.[1] ? (
              <MufeBox tot={tot} lancioSlug={slug} />
            ) : (
              <MufeMap
                lancioSlug={slug}
                excludeFooter
                excludeContact
                match={{
                  params: { search: location.search.split('key=')?.[1] },
                }}
              />
            )}
          </>
        )}
      </main>
      <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
    </>
  );
}
