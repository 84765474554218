import React, { useState } from 'react';

export default function SearchOverlay({ visible, toggle, history }) {
  const [term, setTerm] = useState('');
  if (visible) {
    return (
      <form
        className="c-form c-form--search"
        onSubmit={e => {
          e.preventDefault();
          history.push(`/cerca/${term}`);
          toggle();
        }}
      >
        <div className="l-container">
          <a
            style={{ cursor: 'pointer' }}
            onClick={toggle}
            className="c-form-close"
          >
            X
          </a>
          <fieldset>
            <header className="c-form__header">
              <legend className="c-form__title">Ricerca</legend>
            </header>

            <div className="c-form__field">
              <input
                style={{ color: '#fff' }}
                className="c-form__field__input"
                type="text"
                name="cerca"
                onChange={e => setTerm(e.target.value)}
              />
              <span className="icon icon-search" />
            </div>

            <footer className="c-form__footer">
              <button type="submit" className="c-button">
                <span className="icon icon-search" />
                <span>Cerca</span>
              </button>
            </footer>
          </fieldset>
        </div>
      </form>
    );
  }

  return null;
}
