export const SET_DATASTATIC = 'DATASTATIC@SET_DATASTATIC';
export const DATASTATIC_REQUEST = 'DATASTATIC@DATASTATIC_REQUEST';
export const DATASTATIC_FAILURE = 'DATASTATIC@DATASTATIC_FAILURE';

export default (
  state = {
    contactsCats: [],
    provs: [],
    countries: [],
    jobs: [],
    academyLocations: [],
    academyFamilies: [],
    academySchedules: [],
    academyTypes: [],
    lanci: [],
    reqStatus: 'idle',
  },
  action,
) => {
  switch (action.type) {
    case DATASTATIC_REQUEST: {
      return {
        ...state,
        reqStatus: 'pending',
      };
    }
    case SET_DATASTATIC: {
      return { ...action.datastatic, reqStatus: 'fulfilled' };
    }
    case DATASTATIC_FAILURE: {
      return {
        ...state,
        reqStatus: 'rejected',
      };
    }
    default: {
      return state;
    }
  }
};

export const getDatastatic = state => state;
