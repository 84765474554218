/* eslint-disable no-inner-declarations */
import { useEffect, useState } from 'react';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';

export default function useSlides() {
  const [slides, setSlides] = useState([]);
  useEffect(() => {
    const request = Api.get(endpoints.slides);
    async function fetchPageData() {
      try {
        const data = await request.promise;
        setSlides(data);
      } catch (e) {
        console.log(e);
        // todo what to do in error?
      }
    }

    fetchPageData();

    return request.cancel;
  }, []);

  return slides;
}
