import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import Link from 'shared/components/Link';

import img from '../../../assets/img/banner-signup.png';
import styles from './SignupForm.module.css';
import CatSelection from './components/CatSelection/CatSelection';
import DocInput from './components/DocInput/DocInput';
import Mandatory from './components/Mandatory';
import GenericInput from './components/GenericInput';
import MainFooter from '../../../shared/components/MainFooter';
import * as endpoints from '../../../shared/constants/endpoints';
import { formatUrl } from '../../../shared/services/api';
import PaymentTypes from '../../Profile/Subscribe/components/PaymentTypes';
import { getDatastatic } from '../../../store/app.reducer';

export default function SignupForm({ match, setLoginVisible, history, location }) {
  const courseID = match?.params?.courseID;
  const courseName = match?.params?.courseName;
  const signupType = match?.params?.type;
  const signupPubblico = signupType === 'pubblico';
  const getSearch = location.search.replace('?', '');
  const getElems = getSearch.split('&');
  let getParams = {};
  getElems.map((elem) => {
    const keyValue = elem.split('=');
    getParams[keyValue[0]] = keyValue[1];
  });

  const [data, setData] = useState({
    ...(courseID ? { academy_course: courseID } : {}),
    ...(signupPubblico ? { prof: process.env.REACT_APP_DEFAULT_PROF } : {}),
    ...(getParams.referrer ? { ref_id: getParams.referrer } : {}),
    paese: 'IT',
  });
  const [errors, setErrors] = useState({});
  const [reqStatus, setReqStatus] = useState('idle');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { provs, countries, jobs } = useSelector(getDatastatic);

  const setForm = e => setData({ ...data, [e.target.name]: e.target.value });
  const doSignup = async () => {
    setReqStatus('pending');
    try {
      const formData = new FormData();
      const token = await executeRecaptcha('signup_page');
      formData.append('g-recaptcha', token);
      for (const prop in data) {
        formData.append(prop, data[prop]);
      }

      const req = await fetch(
        formatUrl(process.env.REACT_APP_API_URL) + endpoints.signup,
        {
          method: 'POST',
          body: formData,
        },
      );
      const res = await req.json();

      setReqStatus('fullfilled');
      if (res.payment_id) {
        history.push(`/payment/${res.payment_id}`);
      } else {
        if (res.status === 200) {
          Swal.fire(
            'Ottimo!',
            res.message ||
              'Iscrizione avvenuta con successo. Vai alla home e clicca su "login" per entrare!',
            'success',
          );
          history.push(`/`);
        }
        throw res;
      }
    } catch (e) {
      if (e) {
        if (e.errors && e.errors.length) {
          Swal.fire('Errore', e.errors[0], 'error');
        }
        setErrors(e);
      }
      setReqStatus('rejected');
    }
  };

  return (
    <div>
      <main className="l-main">
        <header
          className={styles.header}
          style={{
            background: `url(${img})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className="l-container" style={{ paddingBottom: '8em' }}>
            <h1 style={{ marginBottom: 0 }}>Registrazione</h1>
            {courseID && (
              <h2 style={{ fontSize: '2em' }}>
                Al corso {decodeURI(courseName)}
              </h2>
            )}
          </div>
        </header>
        <div className={`l-container ${styles.content}`}>
          {!courseID && (
            <p style={{ margin: '2em 0 1em 0' }} className={styles.simpleText}>
              La tua registrazione al sito è soggetta all’approvazione del
              nostro staff che vaglierà al più presto l’idoneità della
              documentazione da te fornita.
              <br />
              Riceverai la mail di conferma di avvenuta approvazione con la password.
              Questa procedurà potrà richiedere dai 2 ai 7 giorni.
            </p>
          )}
          <a
            style={{
              color: '#E4002B',
              fontSize: '1.3em',
              textDecoration: 'underline',
            }}
            onClick={() => setLoginVisible(true)}
          >
            Se sei già iscritto fai login
          </a>

          <h2>
            Registrazione Dati
            <br />
            nuovo utente
          </h2>

          <form>
            {(!courseID && !signupPubblico) && (
              <>
                <div className={styles.row}>
                  <CatSelection>
                    <select name="prof" onChange={setForm}>
                      <option value="">Categoria</option>
                      {jobs.map(job => (
                        <option key={job.key} value={job.key}>
                          {job.value}
                        </option>
                      ))}
                    </select>
                  </CatSelection>
                </div>

                <DocInput
                  prof={data.category}
                  onChange={e => {
                    const file = e.target.files[0];
                    setData(d => ({ ...d, file }));
                  }}
                />
              </>
            )}

            <div className={styles.row}>
              <GenericInput
                error={errors.children?.nome?.errors?.[0]}
                label="Nome"
                name="nome"
                value={data}
                setForm={setForm}
              />

              <GenericInput
                error={errors.children?.cognome?.errors?.[0]}
                label="Cognome"
                name="cognome"
                value={data}
                setForm={setForm}
              />
            </div>

            <div className={styles.row}>
              <GenericInput
                error={errors.children?.codfiscale?.errors?.[0]}
                label="Codice Fiscale"
                name="codfiscale"
                notMandatory
                value={data}
                setForm={setForm}
              />
              <GenericInput
                error={errors.children?.mail?.errors?.[0]}
                label="Indirizzo email"
                name="mail"
                value={data}
                setForm={setForm}
              >
                <p className={styles.subtext}>
                  Un indirizzo e-mail valido. Il sistema invierà tutte le e-mail
                  a questo indirizzo. L’indirizzo e-mail non sarà pubblico e
                  verrà utilizzato soltanto se desideri ricevere una nuova
                  password o se vuoi ricevere notizie e avvisi via e-mail.
                </p>
              </GenericInput>
            </div>

            <div className={styles.row}>
              <GenericInput
                error={errors.children?.ragsoc?.errors?.[0]}
                notMandatory
                label="Ragione Sociale"
                name="ragsoc"
                value={data}
                setForm={setForm}
              />
              <GenericInput
                error={errors.children?.partitaiva?.errors?.[0]}
                label="Partita IVA"
                name="partitaiva"
                notMandatory
                value={data}
                setForm={setForm}
              />
              <GenericInput
                error={errors.children?.sdi?.errors?.[0]}
                label="SDI"
                name="sdi"
                notMandatory
                value={data}
                setForm={setForm}
              />
              <GenericInput
                error={errors.children?.pec?.errors?.[0]}
                label="PEC"
                name="pec"
                notMandatory
                value={data}
                setForm={setForm}
              />
            </div>

            <div className={styles.row}>
              <GenericInput
                error={errors.children?.indirizzo?.errors?.[0]}
                label="Indirizzo"
                name="indirizzo"
                flex="4"
                value={data}
                setForm={setForm}
              />

              <GenericInput
                error={errors.children?.cap?.errors?.[0]}
                label="CAP"
                name="cap"
                value={data}
                setForm={setForm}
              />
            </div>

            <div className={styles.row}>
              <GenericInput
                error={errors.children?.citta?.errors?.[0]}
                label="Città"
                name="citta"
                flex="3"
                value={data}
                setForm={setForm}
              />

              <label htmlFor="prov">
                <span>
                  Provincia
                  <Mandatory />
                </span>

                <select
                  name="prov"
                  value={data.prov}
                  onChange={setForm}
                  style={{ margin: '0', flex: 0 }}
                >
                  <option value="">Provincia</option>
                  {provs.map(prov => (
                    <option key={prov.key} value={prov.key}>
                      {prov.value}
                    </option>
                  ))}
                </select>
                <p
                  style={{
                    color: '#E50043',
                    fontSize: '.9em',
                    fontWeight: 'normal',
                  }}
                >
                  {errors.children?.prov?.errors?.[0]}
                </p>
              </label>
              <label htmlFor="paese">
                <span>
                  Nazione
                  <Mandatory />
                </span>
                <select
                  name="paese"
                  value={data.paese}
                  onChange={setForm}
                  style={{ margin: '0', flex: 0 }}
                >
                  <option value="">Nazione</option>
                  {countries.map(country => (
                    <option key={country.key} value={country.key}>
                      {country.value}
                    </option>
                  ))}
                </select>
                <p
                  style={{
                    color: '#E50043',
                    fontSize: '.9em',
                    fontWeight: 'normal',
                  }}
                >
                  {errors.children?.paese?.errors?.[0]}
                </p>
              </label>
            </div>

            <div className={styles.row}>
              <GenericInput
                label="Telefono"
                name="telefono"
                flex="initial"
                value={data}
                setForm={setForm}
                error={errors.children?.telefono?.errors?.[0]}
              >
                <p className={styles.subtext}>
                  Solo numeri senza altro caratteri o spazi. Esempio: 051229171
                  o 33312345678.
                </p>
              </GenericInput>
            </div>

            <div className={`${styles.checkboxes}`}>
              <label
                htmlFor="privacy"
                style={
                  errors.children?.privacy?.errors?.[0] && {
                    textDecoration: 'underline',
                    color: 'red',
                  }
                }
              >
                <input
                  type="checkbox"
                  name="privacy"
                  checked={data.privacy}
                  onChange={e =>
                    setData({
                      ...data,
                      privacy: e.currentTarget.checked ? 1 : 0,
                    })
                  }
                />
                Accetto i termini. Informativa trattamento dati sulla
                privacy.&nbsp;{' '}
              </label>
              <Link to="/privacy" style={{ textDecoration: 'underline' }}>
                Leggi il testo sulla Privacy.
              </Link>
            </div>

            {courseID && (
              <>
                <h2>Metodo di pagamento</h2>
                <PaymentTypes
                  style={{ marginTop: '4em' }}
                  onRadioPress={e =>
                    setData({ ...data, payment_type: e.target.value })
                  }
                  payType={data.payment_type}
                />
              </>
            )}

            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                margin: '2em 0 4em 0',
              }}
            >
              <div
                className="c-button"
                onClick={() =>
                  reqStatus === 'pending' ? null : doSignup(data)
                }
                style={{ opacity: reqStatus === 'pending' ? 0.5 : 1 }}
              >
                Invia registrazione
              </div>
            </div>
          </form>
        </div>
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
}
