import { useState, useEffect } from 'react';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';

export default function useOrder(id) {
  const [fetching, setFetching] = useState(false);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    async function fetchOrder() {
      setFetching(true);
      try {
        const res = await Api.get(endpoints.order(id)).promise;
        setOrder(res);
      } catch (e) {
        console.log(e);
      } finally {
        setFetching(false);
      }
    }

    fetchOrder();
  }, [id]);

  return { fetching, order };
}
