import React from 'react';
import { useSelector } from 'react-redux';

import { getDatastatic } from './store/app.reducer';
import NotFound from './Features/NotFound/NotFound';
import Loader from './shared/components/Loader';
import Lancio from './Features/Lanci/Lancio';

export default function CatchAllRoute(props) {
  const { lanci, reqStatus } = useSelector(getDatastatic);

  if (reqStatus === 'pending' || reqStatus === 'idle') {
    return <Loader />;
  }

  if (!lanci.find(l => l.slug === props.location.pathname.split('/')?.[1])) {
    // window.location.href = '/404.php';
    return <NotFound {...props} />;
  }

  return <Lancio {...props} slug={props.location.pathname.split('/')?.[1]} />;
}
