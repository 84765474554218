import React from 'react';

import RedLogo from '../../../assets/img/logo-red.png';
import styles from './Contacts.module.css';

export default function Contacts({ data, mapData }) {
  return (
    <div className="c-contact-container">
      <div className="l-container">
        <article className="c-contact">
          <header className="c-contact__header">
            <img
              alt="red logo"
              className="c-contact__header__logo"
              src={RedLogo}
            />
            <h4 className="c-title">{data.title}</h4>
          </header>

          <div
            style={{
              fontSize: '20px',
              color: '#606060',
              lineHeight: '40px',
              marginBottom: '15px',
            }}
            className={styles.info}
            dangerouslySetInnerHTML={{ __html: data.body }}
          />
        </article>

        <div className="c-map">
          <div className="j-map">
            <iframe
              title="mappa"
              src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d522.824982062779!2d11.342152154235107!3d44.498365041136765!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sit!2sit!4v1516206085044"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  );
}
