import React, { useEffect, useState } from 'react';

import usePageData from '../../shared/hooks/usePageData';
import { page, utilsLanci } from '../../shared/constants/endpoints';
import Loader from '../../shared/components/Loader';
import MetaTags from '../../shared/components/MetaTags';
import MainFooter from '../../shared/components/MainFooter';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import Api from '../../shared/services/api';
import Link from '../../shared/components/Link';

export default function Novita({ slug, location }) {
  const [fetched, , generic, boxesObj] = usePageData(`${page}/novita`);
  const [lanci, setLanci] = useState([]);

  useEffect(() => {
    async function fetchAllLanci() {
      const res = await Api.get(utilsLanci).promise;
      setLanci(res);
    }

    fetchAllLanci();
  }, []);

  // console.log(lanci);

  const info = boxesObj.novita?.body_novita;

  if (!fetched) return <Loader />;

  return (
    <>
      <MetaTags data={generic} />
      <main className="l-main">
        <Breadcrumbs />
        <h2
          className="c-title"
          style={{
            color: '#484848',
            textAlign: 'center',
            marginTop: 12,
          }}
        >
          {info?.title}
        </h2>
        <section className="c-content-block c-content-block--center">
          <div className="l-container c-content-block__container">
            {lanci.map(l => (
              <Link to={l.link} className="c-class" key={l.link}>
                <div className="c-classes">
                  <figure className="c-class__picture">
                    <img src={l.image} alt="banner lancio" />
                  </figure>
                  <div className="c-class__content">
                    <h3 className="c-class__title c-title c-title--black">
                      {l.name}
                    </h3>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </section>
      </main>
      <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
    </>
  );
}
