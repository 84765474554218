import React from 'react';

import Mandatory from './Mandatory';

export default function GenericInput({
  label,
  name,
  width,
  flex,
  marginLeft,
  notMandatory,
  children,
  value,
  error,
  setForm,
}) {
  return (
    <label
      htmlFor={name}
      style={{ width, flex, ...(marginLeft && { marginLeft: '1em' }) }}
    >
      <span>
        {label}
        {!notMandatory && <Mandatory />}
      </span>

      <input type="text" name={name} value={value[name]} onChange={setForm} />
      {error && (
        <p style={{ color: '#E50043', fontSize: '.9em', fontWeight: 'normal' }}>
          {error}
        </p>
      )}
      {children}
    </label>
  );
}
