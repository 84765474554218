import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { login as loginAction } from '../../../../Features/Access/access.action-creators';
import { getLoginLoading } from '../../../../store/app.reducer';

function LoginForm({ visible, toggle, location, history }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });
  // TODO unused

  const loading = useSelector(getLoginLoading);

  const dispatch = useDispatch();

  const doLogin = async () => {
    toggle();
    dispatch(loginAction(username, password, location, history));
  };

  if (visible) {
    return (
      <form className="c-form c-form--login" style={{ zIndex: 10 }}>
        <div className="l-container">
          <a
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
            onClick={toggle}
            className="c-form-close"
          >
            X
          </a>
          <fieldset>
            <header className="c-form__header">
              <legend className="c-form__title">Login</legend>
              <span>Bentornato! Accedi all&apos;area riservata</span>
            </header>

            <div
              className="c-form__field"
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <div
                className="c-form__field__label"
                style={{ flex: '0.5', width: 'auto' }}
              >
                Mail
              </div>
              <div style={{ flex: 3 }}>
                <input
                  style={{ width: '100%' }}
                  className="c-form__field__input"
                  type="text"
                  onChange={e => setUsername(e.target.value)}
                  value={username}
                />
                {errors.username && (
                  <p style={{ color: '#E4002B' }}>{errors.username}</p>
                )}
              </div>
            </div>

            <div
              className="c-form__field"
              style={{ display: 'flex', alignItems: 'flex-start' }}
            >
              <div
                className="c-form__field__label"
                style={{ flex: '0.5', width: 'auto' }}
              >
                Password
              </div>
              <div style={{ flex: 3 }}>
                <input
                  style={{ width: '100%' }}
                  className="c-form__field__input"
                  type="password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                />
                {errors.password && (
                  <p style={{ color: '#E4002B' }}>{errors.password}</p>
                )}
              </div>
            </div>

            {/*     <div className="c-form__field c-form__field--checkbox">
                <input type="checkbox" name="" id="remember" />
                <span />
                <label htmlFor="remember" className="c-form__field__label">
                  Ricordami.
                </label>
              </div> */}

            <footer className="c-form__footer" style={{ margin: '3rem auto' }}>
              <button
                type="submit"
                className="c-button c-button--ghost"
                onClick={doLogin}
                disabled={loading}
              >
                Accedi
              </button>
              <Link to="/password/reset">Ho dimenticato la password.</Link>
            </footer>
          </fieldset>
          <p>
            Non sei ancora registrato?
            <Link to="/signup" className="c-form-link" onClick={toggle}>
              {' '}
              Crea il tuo account
            </Link>
          </p>
        </div>
      </form>
    );
  }

  return null;
}

export default LoginForm;
