import React, { useEffect, useState } from 'react';

import styles from './MainFooter.module.css';
import Api from '../../services/api';
import * as endpoints from '../../constants/endpoints';
import NewsFrame from './NewsFrame';
import Link from '../Link';

export default function MainFooter({ dynamicFooter }) {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    async function fetchFooter() {
      try {
        setFooterData(await Api.get(endpoints.footer).promise);
      } catch (e) {
        console.log(e);
      }
    }
    fetchFooter();
  }, []);

  if (footerData && dynamicFooter) {
    return (
      <div className="l-footer">
        <footer className="c-site-footer">
          <div className="l-container">
            <h5 className="c-site-footer__title">
              Entra nella tribe. Rimani aggiornato sulle novità make up for ever
            </h5>

            <div className={styles.footerWrapper}>
              <NewsFrame />

              <div className={styles.socialItems}>
                {dynamicFooter &&
                  Object.keys(dynamicFooter).map(
                    el =>
                      el.includes('soc') && (
                        <Link
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          className="c-social__item"
                          to={dynamicFooter[el].link}
                          key={JSON.stringify(dynamicFooter[el])}
                        >
                          <img
                            alt="social"
                            src={dynamicFooter[el].image}
                            style={{ width: '35px' }}
                          />
                        </Link>
                      ),
                  )}
              </div>
            </div>

            <ul className="c-site-footer__menu">
              {Object.keys(footerData).map(key => (
                <li className="c-site-footer__menu__item" key={key}>
                  <Link to={footerData[key].link}>{footerData[key].text}</Link>
                </li>
              ))}
            </ul>

            <div className="c-site-footer__contact">
              {dynamicFooter.footer_company &&
                dynamicFooter.footer_company.image && (
                  <img
                    alt="company logo"
                    className="c-site-footer__contact__logo"
                    src={dynamicFooter.footer_company.image}
                  />
                )}
              <div
                className="c-site-footer__contact__caption"
                dangerouslySetInnerHTML={{
                  __html:
                    dynamicFooter.footer_company &&
                    dynamicFooter.footer_company.body,
                }}
              />
            </div>

            <ul className="c-site-footer__policy">
              {dynamicFooter &&
                Object.keys(dynamicFooter).map(
                  el =>
                    el.includes('link') && (
                      <li
                        key={JSON.stringify(dynamicFooter[el])}
                        className="c-site-footer__policy__item"
                      >
                        <Link to={dynamicFooter[el].link}>
                          {dynamicFooter[el].title}
                        </Link>
                      </li>
                    ),
                )}
            </ul>
          </div>
        </footer>
      </div>
    );
  }

  return null;
}
