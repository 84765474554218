import React from 'react';
import { HashLink } from 'react-router-hash-link';

import { months } from '../../../shared/constants/dates';

export default function NextSessions({ sessions, history }) {
  const subscribe = el => {
    history.push(`/academy/corso/${el.slug}`);
  };

  return (
    <section className="c-content-block c-content-block--center">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header">
          <h2 className="c-title c-title--small c-title--black">
            Le prossime sessioni del corso
          </h2>
        </header>
        <div className="c-next-courses l-4col l-4col--small">
          {sessions.map(el => {
            const date = new Date(el.content.date);
            const dateString = `${date.getDate()} ${
              months[date.getMonth()]
            } ${date.getFullYear()}`;
            return (
              <div
                role="button"
                tabIndex={0}
                className="c-button c-button--where u-mobile"
                key={el.slug}
                onClick={() => subscribe(el)}
              >
                {el.content.date && <span>{dateString}</span>}
                {el.content.location.city}
                <strong onClick={() => subscribe(el)}>Iscriviti</strong>
              </div>
            );
          })}
        </div>
        <footer className="c-content-block__footer">
          <HashLink
            smooth
            to="/academy_homepage#ricerca"
            className="c-button c-button--long"
          >
            Iscriviti
          </HashLink>
        </footer>
      </div>
    </section>
  );
}
