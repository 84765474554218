import React from 'react';
import Link from 'shared/components/Link';

export default function CoursesInfo({ data }) {
  return (
    <section className="c-content-block">
      <header className="c-page-header">
        <div className="l-container">
          <h2 className="c-page-title">{data.academy_individual.title}</h2>
        </div>
      </header>
      <div className="c-content l-container">
        <div className="c-content__text--2col c-content__item">
          <figure className="c-picture">
            <img src={data.academy_individual.image} alt="corsi di gruppo" />
          </figure>

          <div
            className="c-content__text"
            dangerouslySetInnerHTML={{
              __html: data.academy_individual.body,
            }}
          />
        </div>
        <footer className="c-content__footer">
          <Link
            to={data.academy_individual_button.link}
            className="c-button c-button--small"
          >
            {data.academy_individual_button.title}
          </Link>
        </footer>
      </div>
    </section>
  );
}
