import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './MyOrders.module.css';
import Api from '../../../../shared/services/api';
import * as endpoints from '../../../../shared/constants/endpoints';

function SingleRow({ order }) {
  const date = new Date(order.created);
  return (
    <>
      <tr>
        <td>{`${date.getDate()}/${date.getMonth() +
          1}/${date.getFullYear()}`}</td>
        <td>{order.ord_id}</td>
        <td>Completato</td>
        <td>{order.total_gross}</td>
        <td>
          <Link to={`/ordine/${order.ord_id}`}>dettagli</Link>
        </td>
      </tr>
    </>
  );
}

export default function MyOrders() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    async function fetchOrders() {
      try {
        const data = await Api.get(endpoints.orders, null, true).promise;
        setOrders(data);
      } catch (e) {
        console.log(e);
      }
    }

    fetchOrders();
  }, []);

  return (
    <table className={styles['orders-table']}>
      <thead>
        <tr>
          <td>data</td>
          <td>ordine</td>
          <td>stato</td>
          {/*<td>prodotti</td>*/}
          <td>totale</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {orders.map(o => (
          <SingleRow order={o} key={o.ord_id} />
        ))}
      </tbody>
    </table>
  );
}
