import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JwtDecode from 'jwt-decode';
import Swal from 'sweetalert2';

import styles from './Cart.module.css';
import Links from './Links';
import CommonFrame from './components/CommonFrame/CommonFrame';
import ProductRow from './components/ProductRow';
import ShipmentRow from './components/ShipmentRow';
import { getCart, getToken } from '../../../store/app.reducer';
import useProducts from '../../../shared/hooks/useProducts';
import Loader from '../../../shared/components/Loader';
import PaymentTypes from '../Subscribe/components/PaymentTypes';
import Api from '../../../shared/services/api';
import { orders, shippingCost } from '../../../shared/constants/endpoints';
import IVA from '../../../shared/constants/iva';
import { RESET_CART } from '../../../store/ecommerce.reducer';
import useShippings from '../../../shared/hooks/useShippings';
import Link from '../../../shared/components/Link';

export default function Cart({ history }) {
  const [shipCost, setShipCost] = useState(0);
  const products = useSelector(getCart);
  const token = useSelector(getToken);
  const jwt = JwtDecode(token);
  const dispatch = useDispatch();
  const { shippings, fetchShippings } = useShippings();
  const [fetched, prodsData, cartTotal] = useProducts(products);

  const [formData, setFormData] = useState({});

  const onAddressChange = e => {
    const { target } = e;
    if (target.value) {
      setFormData(f => ({ ...f, sped_id: target.value }));
    }
  };

  useEffect(() => {
    async function fetchShipCosts() {
      try {
        const res = await Api.get(shippingCost, null, false).promise;
        setShipCost(res.cost);
      } catch (e) {
        console.log(e);
      }
    }
    fetchShipCosts();
  }, []);

  async function postOrder() {
    if (!products.length || !formData.payment_type) {
      Swal.fire(
        'Errore',
        'Per inviare un ordine, seleziona un metodo di pagamento e aggiungi almeno un prodotto',
      );
      return;
    }
    try {
      const prodData = products.map(p => ({
        product: p.art_ref_code
          ? `${p.art_code}/${p.art_ref_code}`
          : p.art_code,
        qty: p.qty,
      }));
      const res = await Api.post(orders, {
        rows: prodData,
        payment_type: formData.payment_type,
        sped_id: formData.sped_id,
      }).promise;
      if (res.payment_id) {
        history.push(`/payment/${res.payment_id}`);
        dispatch({
          type: RESET_CART,
        });
      }
    } catch (e) {
      Swal.fire('Errore', "Si è verificato un errore nell'invio dell'ordine");
      console.log(e);
    }
  }

  return (
    <CommonFrame
      links={<Links confirm={postOrder} />}
      title="Carrello"
      subtitle="Articoli"
    >
      <div className="l-container">
        <div className={styles.products}>
          {!fetched && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Loader />
            </div>
          )}
          {fetched && (
            <table>
              <tbody>
                {prodsData.map((p, i) => (
                  <ProductRow
                    cartProd={products[i]}
                    mode="cart"
                    info={{ ...p, qty: products?.[i]?.qty }}
                    key={`${p.art_code}/${products?.[i]?.art_ref_code}`}
                  />
                ))}
                <ShipmentRow shipCost={shipCost} />
              </tbody>

              <tfoot>
                {!jwt.list_inc_vat && (
                  <tr>
                    <td />
                    <td />
                    <td>IVA 22%</td>
                    <td>€ {((cartTotal / 100) * IVA).toFixed(2)}</td>
                  </tr>
                )}
                <tr>
                  <td />
                  <td />
                  <td>TOTALE</td>
                  <td>
                    €
                    {(
                      +cartTotal +
                      +shipCost +
                      (!jwt.list_inc_vat ? +((cartTotal / 100) * IVA) : 0)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          )}

          <div className={`l-container ${styles.payment}`}>
            <div className={styles['payment-types']}>
              <h2>SCEGLI IL METODO DI PAGAMENTO</h2>
              <PaymentTypes
                onRadioPress={({ target }) =>
                  setFormData(d => ({ ...d, payment_type: target.value }))
                }
                payType={formData.payment_type}
              />
            </div>
            <div className={styles['address']}>
              <h2>SCEGLI L'INDIRIZZO DI SPEDIZIONE</h2>
              <Link
                to="/profile?address"
                onClick={fetchShippings}
                style={{ textDecoration: 'underline' }}
              >
                Se vuoi aggiungere un indirizzo, clicca qui
              </Link>
              {!shippings && <Loader />}
              <ul className={styles['address-list']}>
                {shippings?.map(s => (
                  <li
                    key={s.sped_id}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <input
                      type="radio"
                      value={s.sped_id}
                      name="sped_id"
                      checked={formData.sped_id === s.sped_id}
                      onChange={onAddressChange}
                    />
                    <div>
                      <p>{s.dest}</p>
                      <p>
                        Indirizzo: {s.indirizzo}
                        {s.citta ? `, ${s.citta}` : ''}
                        {s.cap ? `, ${s.cap}` : ''}
                        {s.prov ? `, ${s.prov}` : ''}
                      </p>
                      <p>Tel: {s.telefono}</p>
                    </div>
                  </li>
                ))}
              </ul>
              <a
                onClick={fetchShippings}
                style={{
                  textDecoration: 'underline',
                  marginTop: 10,
                  display: 'block',
                }}
              >
                Se non trovi il tuo indirizzo, clicca qui
              </a>
            </div>
          </div>
        </div>
      </div>
    </CommonFrame>
  );
}
