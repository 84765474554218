import React from 'react';

import styles from './TypologyTabMobile.module.css';
import cat1Img from '../../../assets/img/centri-cat1.png';
import cat2Img from '../../../assets/img/centri-cat2.png';
import cat3Img from '../../../assets/img/centri-cat3.png';

export default function TypologyTabMobile() {
  return (
    <div className={styles.typologyTabMobile}>
      <div className={styles.item}>
        <img alt="first category" src={cat1Img} />
        <p className="c-card__content__title">Make Up Center</p>
      </div>
      <p>
        L’appartenenza a questa categoria è riservata a make up artist che
        desiderano fare dell’amore per il trucco la loro principale attività
        proponendo servizi e vendita di make up nella cornice di un locale
        esclusivo dove Make Up For Ever è l’unico brand di trucco. Troverai nei
        Make Up Center più di 500 referenze Make Up For Ever, tutti i look
        stagionali e personale in grado di fornire servizi di trucco ad
        altissimo livello.
      </p>
      <div className={styles.item}>
        <img alt="second category" src={cat2Img} />
        <p>Les Artistes Du Maquillage</p>
      </div>
      <p>
        L’appartenenza a questa categoria è riservata a make up artist che
        desiderano fare dell’amore per il trucco la loro principale attività
        proponendo servizi e vendita di make up nella cornice di un locale
        esclusivo dove Make Up For Ever è l’unico brand di trucco. Troverai nei
        Make Up Center più di 500 referenze Make Up For Ever, tutti i look
        stagionali e personale in grado di fornire servizi di trucco ad
        altissimo livello.
      </p>
      <div className={styles.item}>
        <img alt="third category" src={cat3Img} />
        <p>Les stylistes du Visage</p>
      </div>
      <p>
        L’appartenenza a questa categoria è riservata a make up artist che
        desiderano fare dell’amore per il trucco la loro principale attività
        proponendo servizi e vendita di make up nella cornice di un locale
        esclusivo dove Make Up For Ever è l’unico brand di trucco. Troverai nei
        Make Up Center più di 500 referenze Make Up For Ever, tutti i look
        stagionali e personale in grado di fornire servizi di trucco ad
        altissimo livello.
      </p>
    </div>
  );
}
