import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/index';
import { Link } from 'react-router-dom';

import styles from '../Cart.module.css';

export default function Links() {
  return (
    <div className={styles['footer-link']}>
      <FontAwesomeIcon
        icon={faCaretLeft}
        style={{
          color: '#E4002B',
          fontSize: '1.2em',
          marginRight: '.5em',
        }}
      />
      <Link to="/" style={{ marginRight: '.5em' }}>
        Torna allo shop
      </Link>
    </div>
  );
}
