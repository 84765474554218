import React from 'react';

import styles from './AgentiList.module.css';
import EuropeSvg from '../../shared/components/europe.svg';
import MainFooter from '../../shared/components/MainFooter';
import { AgentsAutoComplete } from '../../shared/components/Distribution/AgentsBox';
import Loader from '../../shared/components/Loader';
import MetaTags from '../../shared/components/MetaTags';
import * as endpoints from '../../shared/constants/endpoints';
import usePageData from '../../shared/hooks/usePageData';

export default function Agenti({ history }) {
  const [fetched, , generic, boxesObj] = usePageData(
    `${endpoints.page}/${endpoints.distributionPage}`,
  );

  if (fetched) {
    return (
      <div>
        <MetaTags data={generic} />
        <div className={`c-agenti-container ${styles['main-container']}`}>
          <div className={styles['italy-map']}>
            <EuropeSvg history={history} />
          </div>

          <article
            style={{
              zIndex: 9,
              flex: 1,
              paddingTop: '25px',
            }}
          >
            <div
              className="l-container"
              style={{
                maxWidth: '480px',
                paddingLeft: '40px',
                paddingRight: '40px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <h1
                style={{
                  color: '#000000',
                  fontFamily: 'century-gothic',
                  fontSize: 32,
                  fontWeight: 700,
                  margin: '50px 0 30px',
                  textAlign: 'center',

                  textTransform: 'uppercase',
                }}
              >
                Distribuzione
              </h1>
              <div style={{ margin: '8px 0 12px', fontSize: 16 }}>
                <b>Cerca l’Agente o il Distributore più vicino a te.</b>
                <p>
                  Clicca sulla cartina oppure digita la città o regione
                  interessate.
                </p>
              </div>
              <p style={{ fontSize: 16 }}>
                Agenti e Distributori MAKE UP FOR EVER per l’Estetica e
                l’Acconciatura Per tutte le aree non espressamente menzionate la
                vendita dei prodotti viene effettuata direttamente dalla sede
                centrale. Per informazioni e ordini contattare La Truccheria.
              </p>
            </div>

            <AgentsAutoComplete
              style={{
                margin: '20px auto',
                paddingLeft: '40px',
                paddingRight: '40px',
              }}
            />
          </article>
        </div>

        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
