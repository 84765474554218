import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';

import Link from '../../../shared/components/Link';
import { getToken, getDatastatic } from '../../../store/app.reducer';

const states = [0, 4, 5];
const stateTitles = {
  0: 'corso annullato',
  4: 'ultimi posti disponibili',
  5: 'corso completo',
};
const stateDescr = {
  0: 'Non è possibile iscriversi a questo corso perchè è stato cancellato.',
  5: 'Non è possibile iscriversi a questo perchè è gà stato raggiunto il numero massimo di partecipanti.',
};

function Text({ title, descr, eligible, course }) {
  const token = useSelector(getToken);

  return (
    <>
      {title && (
        <p
          style={{
            fontWeight: 'bold',
            fontSize: '2em',
            color: '#fff',
            marginTop: '2em',
            marginBottom: '0px',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </p>
      )}
      {descr && (
        <p style={{ fontSize: '1.5em', color: '#fff', margin: 0 }}>
          Corso riservato alle seguenti professioni: <br />
          {descr}
        </p>
      )}

      <HashLink
        smooth
        to="/academy_homepage#ricerca"
        className="c-button"
        style={{
          margin: '2em',
          backgroundColor: '#8B8B8B',
          border: 'none',
        }}
      >
        Torna all'academy
      </HashLink>

      {eligible !== false && (
        <Link
          to={
            token
              ? `/subscribe/${course.id}`
              : `/academy_signup/${course.id}/${course.type.name}`
          }
          className="c-button"
          style={{
            margin: '2em',
            marginLeft: 0,
          }}
        >
          {token ? 'Iscriviti al corso' : 'Iscriviti'}
        </Link>
      )}
    </>
  );
}

export default function SubscribeRationale({ course, onlyText }) {
  const { jobs } = useSelector(getDatastatic);

  const restrictedProfString = course.type?.restricted_prof
    ?.map(p => jobs.find(job => job.key === p)?.value)
    .join(', ');

  if (onlyText) {
    return (
      <>
        {course.state === 0 || course.state === 5 ? (
          <>
            <p>iscrizione non consentita</p>
            <p>{stateDescr[course.state]}</p>
          </>
        ) : (
          <>
            <b>{stateTitles[course.state]}</b>
            <p>
              Corso riservato alle seguenti professioni: <br />
              {restrictedProfString}
            </p>
          </>
        )}
      </>
    );
  }

  return (
    <div
      style={{ color: '#fff', textAlign: 'center', backgroundColor: '#000' }}
    >
      {states.some(s => course.state === s) && (
        <h3
          style={{
            textTransform: 'uppercase',
            color: '#E4003D',
            fontSize: '2em',
            margin: '2em 0',
          }}
        >
          {stateTitles[course.state]}
        </h3>
      )}

      <div style={{ paddingBottom: '4em' }}>
        {course.state === 0 || course.state === 5 ? (
          <Text
            eligible={false}
            title="iscrizione non consentita"
            descr={stateDescr[course.state]}
          />
        ) : (
          <Text
            eligible={course.eligible}
            course={course}
            title={!course.type.restricted_prof && 'Iscrizione'}
            descr={restrictedProfString}
          />
        )}
      </div>
    </div>
  );
}
