import React from 'react';

import * as endpoints from '../../../shared/constants/endpoints';
import usePageData from '../../../shared/hooks/usePageData';
import TopSection from '../../../shared/components/TopSection';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import MainFooter from '../../../shared/components/MainFooter';
import Loader from '../../../shared/components/Loader';
import { CentersAutoComplete } from '../../../shared/components/Distribution/CentriBox';
import MetaTags from '../../../shared/components/MetaTags';
import SmallNav from './components/SmallNav';
import ServiceItem from './components/ServiceItem';
import ServiceContacts from './components/ServiceContacts';
import NotFound from 'Features/NotFound/NotFound';

export default function BoutiqueService({ match }) {
  const [fetched, boxes, generic, boxesObj, content] = usePageData(
    `${endpoints.page}/${match.params.service}`,
  );

  if (fetched) {
    if (!content) {
      return <NotFound />;
    }
    return (
      <div>
        <MetaTags data={generic} />

        <main className="l-main">
          <TopSection
            data={boxes.boutique_service_layout || { title: generic.title }}
          />
          <SmallNav
            items={Object.keys(boxes.boutique_servizi).map(prop => ({
              title: boxes.boutique_servizi[prop].title,
              name: boxes.boutique_servizi[prop].title,
            }))}
          />
          <Breadcrumbs />
          <div className="l-container l-services">
            {boxes.boutique_servizi.map(service => (
              <ServiceItem service={service} key={service.name} />
            ))}
          </div>
          <ServiceContacts
            info={
              (boxesObj.boutique_location &&
                boxesObj.boutique_location.boutique_location_desc) ||
              {}
            }
          />

          <p
            style={{
              color: '#E4002B',
              fontSize: '1.5em',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            Cerca il rivenditore MAKE UP FOREVER più vicino a te
          </p>

          <CentersAutoComplete
            style={{ width: '60%', margin: '1em auto 4em auto' }}
          />
        </main>
        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
