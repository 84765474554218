import React, { Fragment } from 'react';

import styles from './CommonFrame.module.css';
import Divider from '../../../components/Divider';
import TitleBar from '../../../../../shared/components/TitleBar';

export default function CommonFrame({ children, links, title, subtitle }) {
  return (
    <Fragment>
      <TitleBar title={title} />
      <div className="l-container">
        <div className={styles['header']}>
          <h2 className={styles['header-title']}>{subtitle}</h2>
          {links}
        </div>
      </div>
      <Divider />
      {children}
      <Divider />
      <div className="l-container">{links}</div>
    </Fragment>
  );
}
