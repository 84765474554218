import React from 'react';

export default function NewsFrame() {
  const code = process.env.REACT_APP_API_URL.includes('sandbox-api')
    ? '8Yo'
    : 'jUV';
  return (
    <iframe
      style={{ flex: 0.5 }}
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0"
      src={`https://app.mailjet.com/widget/iframe/1cqB/${code}`}
    />
  );
}
