import { useState, useEffect } from 'react';

import { product } from '../constants/endpoints';
import Api from '../services/api';

const getCartTotal = (cart = [], prods) =>
  cart
    .reduce((tot, p) => {
      const prod_price = prods.find(prod => prod.slug === p.slug)?.price;
      tot += prod_price * p.qty;
      return tot;
    }, 0)
    .toFixed(2);

export default function useProducts(slugs = []) {
  const [fetched, setFetched] = useState(true);
  const [prodsData, setProdsData] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  useEffect(() => {
    async function fetchProds() {
      const proms = slugs.map(
        s => Api.get(`${product}/${s?.slug || s}`).promise,
      );

      try {
        const data = await Promise.all(proms);
        setFetched(true);

        const prods = data.map(p => ({ ...p.content, slug: p.slug }));
        setProdsData(prods);
        setCartTotal(getCartTotal(slugs, prods));
      } catch (e) {
        console.log(e);
      }
    }

    if (slugs.length) {
      fetchProds();
    } else {
      setProdsData([]);
      setCartTotal(0);
    }
  }, [slugs]);

  return [fetched, prodsData, cartTotal];
}
