import React from 'react';

function DurationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M17.724 6.446l5.088 1.357 1.272-5.088-1.018-.255-.848 3.223A12.117 12.117 0 0011.958 0C5.342-.084 0 5.259 0 11.958A11.98 11.98 0 0012.042 24c4.41 0 8.31-2.374 10.346-5.936l-.933-.424c-1.95 3.222-5.427 5.342-9.413 5.342-6.106-.084-11.024-5.003-11.024-11.024C1.018 5.938 5.936.934 12.042.934c4.07 0 7.547 2.205 9.498 5.427l-3.477-.933z" />
    </svg>
  );
}

export default DurationIcon;
