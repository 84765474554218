import React from 'react';
import { useDispatch } from 'react-redux';

import styles from './ProductRow.module.css';
import {
  DEL_WISH_PROD,
  DEL_CART_PROD,
  EDIT_CART_PROD,
} from '../../../../store/ecommerce.reducer';
import Link from '../../../../shared/components/Link';

export default function ProductRow({ info, mode, cartProd = {} }) {
  const dispatch = useDispatch();

  const del = () => {
    dispatch({
      type: mode === 'cart' ? DEL_CART_PROD : DEL_WISH_PROD,
      slug: info.slug,
      artRefCode: cartProd.art_ref_code,
    });
  };

  const editCartQty = qty => {
    dispatch({
      type: EDIT_CART_PROD,
      slug: info.slug,
      artRefCode: cartProd.art_ref_code,
      qty,
    });
  };

  return (
    <tr>
      <td>
        <img
          src={info.image}
          style={{ width: 100, marginRight: 100 }}
          alt="prodotto"
        />
      </td>
      <td style={{ textAlign: 'left' }}>
        <div>
          <p>{info.title}</p>
          <p>
            Cod. {info.art_code}
            {cartProd.art_ref_code ? `/${cartProd.art_ref_code}` : ''}
          </p>
        </div>
      </td>
      <td>
        <div className={styles.qty}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {mode === 'cart' && (
              <input
                type="number"
                min="1"
                defaultValue={info.qty || 0}
                onChange={e => editCartQty(e.target.value)}
                style={{ width: 40, height: 30, margin: '0 10px' }}
              />
            )}
            {mode !== 'cart' && (
              <Link
                className="c-button c-button--light"
                to={`/product/${info.slug}`}
              >
                Vai al prodotto
              </Link>
            )}
            <div className="c-button c-button--light" onClick={del}>
              elimina
            </div>
          </div>
        </div>
      </td>
      <td>€ {info.price}</td>
    </tr>
  );
}
