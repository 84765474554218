import React from 'react';

import cartShipment from '../../../../assets/img/shipment-icon.png';

export default function ShipmentRow({ shipCost }) {
  return (
    <tr>
      <td>
        <img style={{ marginRight: 100 }} src={cartShipment} alt="spedizione" />
      </td>
      <td style={{ textAlign: 'left' }}>
        <div>
          <p>SPESE DI SPEDIZIONE</p>
        </div>
      </td>
      <td />
      <td>€ {shipCost}</td>
    </tr>
  );
}
