import { useState, useEffect } from 'react';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';
import usePageData from './usePageData';

export default function useCategory(catSlug) {
  const [loading, setLoading] = useState(false);

  const [, , generic] = usePageData(endpoints.contentCatalog(catSlug));
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const productsRes = await Api.get(endpoints.catalogsProduct(catSlug))
          .promise;
        setProducts(productsRes);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [catSlug]);

  return [generic, products, loading];
}
