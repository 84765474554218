export function transformBoxesToObjs(boxes) {
  const data = {};
  boxes.forEach(el => {
    if (data[el.group]) {
      data[el.group] = { ...data[el.group], [el.name]: el };
    } else {
      data[el.group] = { [el.name]: el };
    }
  });

  return data;
}

export function transformBoxes(boxes) {
  const data = {};
  boxes.forEach(el => {
    if (data[el.group]) {
      if (Array.isArray(data[el.group])) {
        data[el.group] = [...data[el.group], el];
      } else {
        data[el.group] = [data[el.group], el];
      }
    } else {
      data[el.group] = el;
    }
  });

  return data;
}

export const trimString = (string, length) =>
  string.length > length ? string.substring(0, length) + '...' : string;
