import React, { useState } from 'react';

import TopSection from '../../../shared/components/TopSection';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import * as endpoints from '../../../shared/constants/endpoints';
import Loader from '../../../shared/components/Loader';
import MainFooter from '../../../shared/components/MainFooter';
import CourseProgram from '../components/CourseProgram';
import SubscriptionProducts from '../components/SubscriptionProducts';
import Sidebar from './Sidebar';
import NextSessions from '../components/NextSessions';
import usePageData from '../../../shared/hooks/usePageData';
import useCourseDates from '../../../shared/hooks/useCourseDates';
import MetaTags from '../../../shared/components/MetaTags';
import Description from '../components/CourseDescription';
import PartnersDetail from '../components/PartnersDetail';
import NotFound from 'Features/NotFound/NotFound';

export default function LaTruccheriaCourse({ match, history }) {
  const [fetched, , generic, boxesObj, content] = usePageData(
    endpoints.courseType(match.params.course),
  );

  const { fetching: datesFetching, dates } = useCourseDates(
    endpoints.courseDetail(match.params.course),
  );

  const [sidebar, setSidebar] = useState(false);

  if (fetched) {
    if (content) {
      return (
        <div
          className={`l-page l-page--sidebar ${sidebar ? 'j-sidebar-open' : ''}`}
        >
          <MetaTags data={generic} />
          <main className="l-main">
            <TopSection
              data={{
                title: content.name,
                image: content.image,
              }}
              subtitle={content.sub_title}
            />
            <Breadcrumbs />
            <Description data={content || {}} />
            <NextSessions
              sessions={dates || []}
              datesFetching={datesFetching}
              history={history}
            />
            <CourseProgram data={content || {}} />
            <PartnersDetail data={content || {}} />
            <SubscriptionProducts data={content || {}} />
          </main>
          <Sidebar toggleOpen={() => setSidebar(!sidebar)} />

          <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
        </div>
      );
    } else {
      return <NotFound />;
    }
  }

  return <Loader />;
}
