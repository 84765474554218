export const SET_ACTIVE_PRODUCT = 'ECOMMERCE@SET_ACTIVE_PRODUCT';

export const ADD_WISH_PROD = 'ECOMMERCE@ADD_WISH_PROD';
export const DEL_WISH_PROD = 'ECOMMERCE@DEL_WISH_PROD';

export const ADD_CART_PROD = 'ECOMMERCE@ADD_CART_PROD';
export const EDIT_CART_PROD = 'ECOMMERCE@EDIT_CART_PROD';
export const DEL_CART_PROD = 'ECOMMERCE@DEL_CART_PROD';

export const RESET_CART = 'ECOMMERCE@RESET_CART';

export default (
  state = {
    activeProduct: null,
    wishlist: [], // array of product slug
    cart: [], // array of productslug/subproduct
  },
  action,
) => {
  switch (action.type) {
    case SET_ACTIVE_PRODUCT: {
      return {
        ...state,
        activeProduct: action.product,
      };
    }
    case ADD_WISH_PROD: {
      return {
        ...state,
        wishlist: [...state.wishlist, action.slug],
      };
    }
    case DEL_WISH_PROD: {
      return {
        ...state,
        wishlist: state.wishlist.filter(p => p !== action.slug),
      };
    }
    case ADD_CART_PROD: {
      return {
        ...state,
        cart: [
          ...state.cart,
          {
            slug: action.slug,
            qty: 1,
            art_ref_code: action.artRefCode,
            art_code: action.artCode,
          },
        ],
      };
    }
    case EDIT_CART_PROD: {
      return {
        ...state,
        cart: state.cart.map(p => {
          if (p.slug === action.slug && p.art_ref_code === action.artRefCode) {
            return { ...p, qty: +action.qty };
          }
          return p;
        }),
      };
    }
    case DEL_CART_PROD: {
      return {
        ...state,
        cart: state.cart.filter(
          p =>
            !(p.slug === action.slug && p.art_ref_code === action.artRefCode),
        ),
      };
    }
    case RESET_CART: {
      return {
        ...state,
        cart: [],
      };
    }
    default: {
      return state;
    }
  }
};

export const getActiveProduct = state => state.activeProduct;
export const getWishlist = state => state.wishlist;
export const getCart = state => state.cart;
export const getCartLength = state => state.cart.length;
