import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { trimString } from 'shared/services/utils';

import location from '../../assets/icons/Location.png';
import tel from '../../assets/icons/Tel.png';
import usePageData from '../../shared/hooks/usePageData';
import { mufeDetail } from '../../shared/constants/endpoints';
import MainFooter from '../../shared/components/MainFooter';
import Loader from '../../shared/components/Loader';
import styles from './CentroMufe.module.css';
import MetaTags from '../../shared/components/MetaTags';
import Link from '../../shared/components/Link';
import HeroSlider from '../Home/components/HeroSlider';
import NotFound from 'Features/NotFound/NotFound';

function CentroMufe({ match }) {
  const [googleApi, setGoogleApi] = useState({});

  const [fetched, , generic, boxesObj, content] = usePageData(
    `${mufeDetail}/${match.params.slug}`,
  );

  useEffect(() => {
    const { map, maps } = googleApi;

    if (map && maps) {
      new maps.Marker({
        position: {
          lat: parseInt(content.latitudine),
          lng: parseInt(content.longitudine),
        },
        map,
        title: trimString(content.ragsoc, 20),
      });
    }
  }, [googleApi, content]);

  if (!fetched) return <Loader />;

  if (!content) return <NotFound />;

  return (
    <div>
      <MetaTags data={generic} />
      <main className="l-main">
        <div
          style={{
            backgroundColor: '#E4002B',
            height: '8em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="l-container">
            <div className={styles.headerWrapper}>
              <h1>{content.ragsoc}</h1>
            </div>
          </div>
        </div>
        <HeroSlider
          hideOverlay
          slides={content.media.map(m => ({ image: m.file_path }))}
        />
        <div
          className="l-container"
          style={{ textAlign: 'center', marginBottom: '2em' }}
        >
          <div className={styles.content}>
            <div
              className={styles.leftContent}
              dangerouslySetInnerHTML={{ __html: content.body }}
            />
            <div className={styles.rightContent}>
              <h1>{content.ragsoc}</h1>
              <ul className={styles.infoList}>
                <li>
                  <img
                    src={location}
                    alt="location icon"
                    style={{ marginRight: '.2em' }}
                  />
                  {content.indirizzo}, {content.cap}, {content.citta} (
                  {content.prov})
                </li>
                <li>
                  <img
                    src={tel}
                    alt="location icon"
                    style={{ marginRight: '.2em' }}
                  />
                  Tel. {content.telefono}
                </li>
              </ul>
              <div style={{ width: '100%', height: '300px' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_MAPS_KEY,
                  }}
                  defaultCenter={{
                    lat: content.latitudine || 45.4626482,
                    lng: content.longitudine || 9.0376489,
                  }}
                  defaultZoom={11}
                  onGoogleApiLoaded={api => setGoogleApi(api)}
                  yesIWantToUseGoogleMapApiInternals
                />
              </div>
            </div>
          </div>
          <Link to="/centri_mufe" className={styles.link}>
            Torna alla ricerca
          </Link>
        </div>
      </main>
      <MainFooter dynamicFooter={boxesObj.static_footer} />
    </div>
  );
}

export default withRouter(CentroMufe);
