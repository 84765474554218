import React, { Fragment } from 'react';

export default function SubscribeTopSection({ image, title, level }) {
  return (
    <Fragment>
      <header
        className="c-hero"
        style={{ display: 'flex', height: 'auto', minHeight: 'initial' }}
      >
        <div className="l-container">
          <figure className="c-hero__picture">
            {image && <img alt="header" src={image} />}
          </figure>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            className="c-hero__text-wrapper"
          >
            <h2 className="c-hero__before-title">Pagamento in corso</h2>
            {title && (
              <h1 className="c-hero__title" style={{ position: 'static' }}>
                {title}
              </h1>
            )}

            {level && (
              <h2 className="c-hero__subtititle" style={{ position: 'static' }}>
                {level}
              </h2>
            )}
          </div>
        </div>
      </header>
      <section className="c-content-block c-content-block--dark">
        <div className="l-container c-content-block__container l-2col">
          {/*           <article className="c-content__text">
            <h2 className="c-title c-title--big">{data.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: data.body }} />
          </article> */}
        </div>
      </section>
    </Fragment>
  );
}
