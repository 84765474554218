import React, { useState } from 'react';

import styles from './TypologyTab.module.css';
import cat1Img from '../../../assets/img/centri-cat1.png';
import cat2Img from '../../../assets/img/centri-cat2.png';
import cat3Img from '../../../assets/img/centri-cat3.png';

export default function TypologyTab() {
  const [selectedTab, setSelectedTab] = useState('M'); // M, A, S

  return (
    <div className={styles.typologyTab}>
      <div className={styles['items-container']}>
        <div
          className={`${styles.item} ${
            selectedTab === 'M' ? styles['item-selected'] : ''
          }`}
          onClick={() => setSelectedTab('M')}
        >
          <img alt="first category" src={cat1Img} />
          <p>Make Up Center</p>
        </div>
        <div
          className={`${styles.item} ${
            selectedTab === 'A' ? styles['item-selected'] : ''
          }`}
          onClick={() => setSelectedTab('A')}
        >
          <img alt="second category" src={cat2Img} />
          <p>Les Artistes Du Maquillage</p>
        </div>
        <div
          className={`${styles.item} ${
            selectedTab === 'S' ? styles['item-selected'] : ''
          }`}
          onClick={() => setSelectedTab('S')}
        >
          <img alt="third category" src={cat3Img} />
          <p>Les stylistes du Visage</p>
        </div>
      </div>
      <div className={styles['text-container']}>
        {selectedTab === 'M' && (
          <p>
            L’appartenenza a questa categoria è riservata a make up artist che
            desiderano fare dell’amore per il trucco la loro principale attività
            proponendo servizi e vendita di make up nella cornice di un locale
            esclusivo dove Make Up For Ever è l’unico brand di trucco. Troverai
            nei Make Up Center più di 500 referenze Make Up For Ever, tutti i
            look stagionali e personale in grado di fornire servizi di trucco ad
            altissimo livello.
          </p>
        )}
        {selectedTab === 'A' && (
          <p>
            Sono centri caratterizzati da una forte vocazione al make up, con
            spazio dedicato, ampia scelta di prodotti e personale qualificato.
            Nei centri Les Artistes du Maquillage troverai oltre 180 referenze
            Make Up For Ever e ti potrai lasciar consigliare e guidare
            all’acquisto da personale formato presso la nostra Academy.
          </p>
        )}
        {selectedTab === 'S' && (
          <p>
            Sono attività in cui il trucco è uno dei servizi offerti ed è svolto
            da personale qualificato. Nei centri appartenenti al circuito Les
            Stylistes du Visage troverai espositori Make Up For Ever dedicati
            alla pelle, agli occhi e alle labbra e i look stagionali.
          </p>
        )}
      </div>
    </div>
  );
}
