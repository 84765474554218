import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

import styles from './OrderDetail.module.css';
import Divider from '../components/Divider';
import TitleBar from '../../../shared/components/TitleBar';
import useOrder from '../../../shared/hooks/useOrder';
import Loader from '../../../shared/components/Loader';
import { getProfile } from '../../../store/app.reducer';
import useShippings from '../../../shared/hooks/useShippings';

function ShippingInfo({ shipping, profile }) {
  const targetObj = shipping || profile;

  return (
    <>
      <p>
        {targetObj.dest || targetObj.nome} {!shipping && targetObj.cognome}
      </p>
      <p>{targetObj.indirizzo}</p>
      <p>
        {targetObj.cap}, {targetObj.citta} {targetObj.prov}
      </p>
      {shipping && (
        <p>
          {shipping.ref_nome} {shipping.ref_tel}
        </p>
      )}
    </>
  );
}

function Row({ info = {} }) {
  return (
    <tr>
      <td>{info.product}</td>
      <td>{info.qty}</td>
      <td />
      <td>
        {info.net_price} + {info.vat_price} IVA
      </td>
    </tr>
  );
}

export default function OrderDetail({ match }) {
  const ordId = match.params.ordID;
  const profile = useSelector(getProfile);

  const { fetching, order } = useOrder(ordId);
  const { shippings } = useShippings();

  return (
    <div>
      <TitleBar title="dettagli ordine" />
      <div className="l-container">
        <h2 className={styles['order-number']}>Ordine {order?.ord_id}</h2>
      </div>
      <Divider />
      <div className="l-container">
        {order && !fetching && (
          <div className={styles['info-container']}>
            <div className={styles['info-item']}>
              <h4>Indirizzo di spedizione</h4>
              <p>{profile.ragsoc}</p>
              <ShippingInfo
                profile={profile}
                shipping={shippings?.find(s => s.sped_id === order.sped_id)}
              />
              <p>P.IVA {profile.partitaiva}</p>
            </div>
            <div className={styles['info-item']}>
              <h4>Informazioni</h4>
              <p>ID transazione: {order?.ord_id}</p>
              <p>Stato dell'ordine: {order?.docs ? 'Evaso' : 'Non evaso'}</p>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className="l-container">
        <div className={styles.products}>
          <h4>prodotti</h4>
          {order && !fetching && (
            <table>
              <thead>
                <tr>
                  {/* <th>Codice</th> */}
                  <th>Prodotto</th>
                  <th>Qt.</th>
                  <th />
                  <th>Prezzo Totale</th>
                </tr>
              </thead>
              <tbody>
                {order?.rows?.map((p, id) => <Row key={id} info={p} />)}
                {/* <tr>
                  <td>
                      <p>SPESE DI SPEDIZIONE</p>
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>€ 83,94</td>
                </tr> */}
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td>IVA 22%</td>
                  <td>{order.total_vat}</td>
                </tr>
                <tr>
                  <td />
                  <td />
                  <td>TOTALE</td>
                  <td>{order.total_gross}</td>
                </tr>
              </tfoot>
            </table>
          )}

          {fetching && <Loader />}
          <div className={styles['footer-link']}>
            <FontAwesomeIcon
              icon={faCaretLeft}
              style={{
                color: '#E4002B',
                fontSize: '1.2em',
                marginRight: '.5em',
              }}
            />
            <Link to="/profile">Torna al profilo</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
