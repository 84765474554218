import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { months } from '../../../shared/constants/dates';
import SubscribeRationale from '../SingolaData/SubscribeRationale';

export default function Courses({ courses, header }) {
  return (
    <Fragment>
      <header className="c-content-block__header">
        {header && (
          <h2 className="c-title c-title--small c-title--black">
            I prossimi corsi
          </h2>
        )}
      </header>
      <div className="c-courses l-container">
        {(!courses || courses.length <= 0) && <p>Nessun corso disponibile</p>}
        {courses && courses.length > 0 && courses.map(el => {
          const date = new Date(el.content.date);
          const dateString = `${date.getDate()} ${months[date.getMonth()]
            } ${date.getFullYear()}`;
          return (
            <article className="c-course" key={el.slug}>
              <figure className="c-course__figure">
                <img src={el.content.type.image} alt="corso" />
              </figure>
              <div className="c-course__content">
                <h3 className="c-course__title">{el.title}</h3>
                <h4 className="c-course__lead">
                  {`Corso per ${el.content.type.skill}`}
                </h4>
                <small className="c-course__date">{dateString}</small>
                <small className="c-course__date">
                  {el.content.location.city}
                </small>
                {el.content.eligible ? (
                  <Link
                    to={`/academy/corso/${el.slug}`}
                    className="c-course__button"
                  >
                    Iscriviti
                  </Link>
                ) : (
                  <SubscribeRationale course={el.content} onlyText />
                )}
              </div>
            </article>
          );
        })}
      </div>
    </Fragment>
  );
}
