import React from 'react';

export default function Header({ city, info = {} }) {
  return (
    <header className="c-hero c-hero--city">
      <div className="c-hero__container l-container">
        <h1 className="c-title c-hero__title">{city}</h1>
        <div className="c-hero__detail">
          <div className="c-hero__detail-text">
            <h2 className="c-hero__detail-title">{info.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: info.body }} />
            {/*     <a href="#">bemarbeauty@gmail.com</a>
            <a href="#" className="c-link">
              Link a sito web
            </a> */}
          </div>
        </div>
      </div>
    </header>
  );
}
