import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';
import { SET_CATALOG } from '../../store/catalog.reducer';

export default function useFetchCatalog() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchCat() {
      try {
        const res = await Api.get(endpoints.catalog, null, false).promise;
        if (res.content) {
          dispatch({
            type: SET_CATALOG,
            catalog: res.content,
          });
        } else {
          throw res;
        }
      } catch (e) {
        console.log(e);
      }
    }

    fetchCat();
  }, [dispatch]);
}
