import React, { useEffect, useState } from 'react';

import style from './DocInput.module.css';
import Mandatory from '../Mandatory';
import Api from '../../../../../shared/services/api';
import * as endpoints from '../../../../../shared/constants/endpoints';

export default function DocInput({ prof, onChange }) {
  const [doc, setDoc] = useState('Seleziona una professione');

  useEffect(() => {
    async function fetchDoc() {
      try {
        const data = await Api.get(endpoints.jobDoc(prof), null, true).promise;
        setDoc(data.text);
      } catch (e) {
        console.log(e);
        setDoc(
          'Si è verificato un errore nel riprendere la documentazione da fornire',
        );
      }
    }

    if (prof) {
      fetchDoc();
    }
  }, [prof]);

  return (
    <>
      <span className={style.label}>
        Documentazione
        <Mandatory />
      </span>
      <div className={style.document}>
        <div className={style.left}>
          <p>Allega la documentazione richiesta</p>
          <input style={{ width: '100%' }} type="file" onChange={onChange} />
          <p className={style.subtext}>
            Carica uno dei documenti richiesti per la tua registrazione al sito.
            Estensioni consentite: GIF JPG JPEG PNG DOC DOCX PDF. Dimensione
            massima del file: 1.5 MB.
          </p>
        </div>
        <div className={style.right}>
          <div className={style.box}>
            <span className={style.label}>Documentazione Richiesta</span>
            <div dangerouslySetInnerHTML={{ __html: doc }} />
          </div>
        </div>
      </div>
    </>
  );
}
