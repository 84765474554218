import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';
import { SET_DATASTATIC } from '../../store/datastatic.reducer';

export default function useFetchDatastatics() {
  const dispatch = useDispatch();

  useEffect(() => {
    const contactsCats = Api.get(endpoints.contactsCats, false, false);
    const provsReq = Api.get(endpoints.dataProvs, false, false);
    const jobsReq = Api.get(endpoints.dataJobs, false, false);
    const countriesReq = Api.get(endpoints.dataCountries, false, false);
    const academyLocationsReq = Api.get(
      endpoints.academyLocations,
      false,
      false,
    );
    const academyFamiliesReq = Api.get(endpoints.academyFamilies, false, false);
    const academyTypesReq = Api.get(endpoints.academyTypes, false, false);
    const academySchedulesReq = Api.get(
      endpoints.academySchedules,
      false,
      false,
    );
    const lanciReq = Api.get(endpoints.lanci, false, false);

    async function fetchDatastatics() {
      try {
        const data = await Promise.all([
          contactsCats.promise,
          provsReq.promise,
          academyLocationsReq.promise,
          academyFamiliesReq.promise,
          academyTypesReq.promise,
          academySchedulesReq.promise,
          countriesReq.promise,
          jobsReq.promise,
          lanciReq.promise,
        ]);
        dispatch({
          type: SET_DATASTATIC,
          datastatic: {
            contactsCats: data[0],
            provs: data[1].data,
            academyLocations: data[2].data,
            academyFamilies: data[3].data,
            academyTypes: data[4],
            academySchedules: data[5],
            countries: data[6].data,
            jobs: data[7].data,
            lanci: data[8].data,
          },
        });
      } catch (err) {
        console.log(err);
        // alert('Errore nel riprendere i dati statici');
      }
    }

    fetchDatastatics();
  }, [dispatch]);
}
