import { useEffect, useState } from 'react';

import Api from '../services/api';
import * as endpoints from '../constants/endpoints';

export default function useCourses(location, month, selType, getAll = false) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const params = {
      ...(location && { location }),
      ...(month && { date_min: month /* date_max: month */ }),
      ...(selType && { type: selType }),
    };
    const coursesReq = Api.get(endpoints.courses, params, false);

    async function doReq() {
      const res = await coursesReq.promise;
      setCourses(res);
    }

    if (month || location || selType || getAll) {
      doReq();
    }

    return coursesReq.cancel;
  }, [month, location, selType, getAll]);

  return courses;
}
