// Home

export const slides = 'slide';

// Pages data

export const page = 'content/page';
export const product = 'content/product';
export const menu = 'menu';
export const footer = 'menu/footer';

// catalog

export const catalog = 'content/page/catalog';
export const contentCatalog = slug => `content/catalog/${slug}`;
export const catalogsProduct = slug => `content/catalog/${slug}/product`;

// Profile

export const signup = 'subscription';
export const login = 'login';
export const profile = 'profile';
export const newsletter = 'newsletter';
export const delegate = 'delegate';
export const resource = 'resource';
export const subscribeCourse = 'academy/course/';
export const doneCourses = 'academy';
export const payment = 'payment/';
export const pwdReset = 'password/reset/confirm';
export const pwdResetRequest = 'password/reset/request';
export const pwdResetConfirm = 'password/reset/confirm';
export const pwdChange = 'password/change';
export const orders = 'order';
export const sales = 'sales';
export const shippings = 'account/shipping';
export const order = id => `order/${id}`;

// Datastatics

const datastatic = 'datastatic';
export const contactsCats = `${datastatic}/contact/categories`;
export const dataProvs = `${datastatic}/italy`;
export const dataJobs = `${datastatic}/jobs`;
export const dataCountries = `${datastatic}/countries`;
export const academyLocations = `${datastatic}/academy/locations`;
export const academyFamilies = `${datastatic}/academy/families`;
export const academySchedules = `${datastatic}/academy/schedules`;
export const academyTypes = `${datastatic}/academy/types`;
export const jobDoc = prof => `utils/job_doc/${prof}`;
export const lanci = `${datastatic}/product/tags`;
export const shippingCost = `${datastatic}/ecommerce/shipping_cost`;
export const utilsLanci = `utils/lanci`;

// Academy

export const courses = 'utils/academy/courses';
export const courseDetail = slug => `content/academy_type/${slug}/course`;
export const courseType = slug => `content/academy_type/${slug}`;
export const courseDateDetail = slug => `content/academy_course/${slug}`;

// Centri Mufe and agenti

export const mufeSearch = 'utils/locations/search/centri_mufe';
export const lanciSearch = 'utils/locations/search/lanci';
export const centriMufePage = 'centri_mufe';
export const mufeDetail = 'content/centro_mufe';
export const distributionPage = 'distribuzione';
export const agentsSearch = 'utils/locations/search/distribuzione';
export const mufeByProv = 'utils/centri_mufe';
export const mufeByLancio = 'utils/lancio';
export const distribution = 'utils/distribuzione';

// Contacts

export const contact = 'contact';
