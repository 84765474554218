import React from 'react';

export default function Mandatory() {
  return (
    <span
      style={{
        fontFamily: 'Roboto',
        fontSize: ' 20px',
        fontWeight: ' 400',
        lineHeight: ' 22px',
        color: '#e40043',
      }}
    >
      *
    </span>
  );
}
