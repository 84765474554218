import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as endpoints from '../../../shared/constants/endpoints';
import MainFooter from '../../../shared/components/MainFooter';
import usePageData from '../../../shared/hooks/usePageData';
import Header from './components/Header';
import Courses from '../components/Courses';
import { CitiesTable } from '../components/CoursesCities';
import MetaTags from '../../../shared/components/MetaTags';
import Loader from '../../../shared/components/Loader';
import { getDatastatic } from '../../../store/app.reducer';

export default function DoveSiamo({ match }) {
  const { academyLocations } = useSelector(getDatastatic);
  const city = academyLocations.find(el => el.slug === match.params.citta);
  const [fetched, , generic, boxesObj, content] = usePageData(
    city && `${endpoints.page}/${city.slug}`,
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!fetched) {
    return <Loader />;
  }

  return (
    <div>
      <MetaTags data={generic} />

      <main className="l-main">
        <Header
          city={city && city.value}
          info={
            boxesObj?.dynamic_academy_locations?.[
              Object.keys(boxesObj?.dynamic_academy_locations || {}).find(key =>
                key.includes('academy_locations'),
              )
            ]
          }
        />
        <section className="c-content-block c-content-block--center">
          <div className="l-container c-content-block__container">
            <Courses header courses={content} />
          </div>
        </section>

        <section className="c-content-block c-content-block--center c-content-block--grey u-desktop">
          <div className="l-container c-content-block__container">
            <h2 className="c-title">Dove siamo</h2>
            <div className="c-content l-container">
              Scopri in quali città d’Italia organizziamo i nostri corsi
              professionali di trucco.
              <CitiesTable />
            </div>
          </div>
        </section>
      </main>
      <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
    </div>
  );
}
