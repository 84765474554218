import React, { useState } from 'react';
import Swal from 'sweetalert2';

import MainFooter from '../../../../shared/components/MainFooter';
import Api from '../../../../shared/services/api';
import * as endpoints from '../../../../shared/constants/endpoints';
import styles from './MailChange.module.css';

function Field({ children, error, style = {} }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
      {children}
      <p style={{ color: '#E4002B' }}>{error}</p>
    </div>
  );
}

export default function MailChange() {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const setForm = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const confirmMailChange = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await Api.put(endpoints.profile, formData).promise;
      console.log(res);

      Swal.fire('Ottimo!', res.message || 'Mail cambiata', 'success');
    } catch (e) {
      if (e.message) {
        Swal.fire('Errore', e.message, 'error');
      }
      console.log(e);
      setErrors(e?.errors?.children || {});
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <main className="l-main">
        <div className={styles.content}>
          <div className="l-container">
            <h1 style={{ color: '#E4002B', marginTop: '1em' }}>Cambio Mail</h1>
            <form>
              <div style={{ margin: 20 }}>
                <Field
                  error={errors?.current_password?.children?.errors?.[0]}
                  style={{ width: '320px', margin: '1em 0' }}
                >
                  <p>Password Attuale</p>
                  <input
                    className="c-form__field__input"
                    type="password"
                    name="current_password"
                    placeholder="Password Attuale*"
                    onChange={setForm}
                    value={formData.current_password || ''}
                  />
                </Field>
                <Field
                  error={errors?.email?.children?.errors?.[0]}
                  style={{ width: '320px', margin: '1em 0' }}
                >
                  <p>Nuova email</p>
                  <input
                    className="c-form__field__input"
                    type="text"
                    name="email"
                    placeholder="Nuova email*"
                    onChange={setForm}
                    value={formData.email || ''}
                  />
                </Field>

                <input
                  value="Invia"
                  type="submit"
                  disabled={loading}
                  className="c-button c-button--light"
                  onClick={confirmMailChange}
                />
              </div>
            </form>
          </div>
        </div>
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
}
