import React from 'react';

export default function ServiceItem({ service }) {
  return (
    <article className="c-service-item" id={service.title}>
      <figure className="c-service-item__picture">
        <img src={service.image} alt="servizio" />
      </figure>
      <div className="c-content">
        <h3 className="c-title">{service.title}</h3>

        <div
          className="c-service-detail"
          dangerouslySetInnerHTML={{
            __html: service.body,
          }}
        />
      </div>
    </article>
  );
}
