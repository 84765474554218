import React from 'react';
import { Link } from 'react-router-dom';

import img from '../../assets/img/banner-signup.png';
import styles from './Signup.module.css';
import MainFooter from '../../shared/components/MainFooter';

export default function Signup() {
  return (
    <div>
      <main className="l-main">
        <div
          className={styles.content}
          style={{
            background: `url(${img})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className="l-container">
            <h1>Registrazione</h1>
            <div>
              <p>Registrati come:</p>
              <div className={styles.boxes}>
                <Link to="/signup/pubblico">pubblico</Link>
                <Link to="/signup/professionista">professionista</Link>
                <Link to="/signup/allievo">allievo</Link>
                <Link to="/signup/scuola">scuola</Link>
              </div>
              <div className={styles.text}>
                {/*<p>*/}
                {/*  Possono registrarsi al sito ed effettuare l’acquisto online*/}
                {/*  esclusivamente i clienti professionisti, le/gli allieve/i de*/}
                {/*  La Truccheria MAKE UP ACADEMY e le/gli allievi di altre*/}
                {/*  scuole.*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*  Per coloro che non rientrano in queste categorie è possibile*/}
                {/*  comprare i prodotti MAKE UP FOR EVER presso i CENTRI MUFE in*/}
                {/*  tutta Italia clicca qui per scoprire quello più vicino a te.*/}
                {/*</p>*/}
              </div>
            </div>
          </div>
        </div>
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
}
