import React, { Fragment } from 'react';

import Link from '../../../shared/components/Link';

export default function HomeItemLarge({ items }) {
  return (
    <Fragment>
      {items.map(el => (
        <Link className="c-cta" to={el.link} key={JSON.stringify(el)}>
          <figure className="c-cta__picture">
            <img alt="large item" className="mobile" src={el.image} />
            <img alt="large item" className="desktop" src={el.image} />
          </figure>
          <div className="c-cta__text">
            <h3 className="c-title">{el.title}</h3>
            <div
              className="c-subtitle"
              dangerouslySetInnerHTML={{
                __html: el.body,
              }}
            />
            <span className="c-button c-button--light">Scopri di più</span>
          </div>
        </Link>
      ))}
    </Fragment>
  );
}
