/* eslint-disable no-inner-declarations */
import { useEffect, useState } from 'react';

import Api from '../services/api';
import { shippings as shippingEndpoints } from '../constants/endpoints';

export default function useShippings() {
  const [shippings, setShippings] = useState(null);

  async function fetchShippings() {
    try {
      setShippings(null);
      const res = await Api.get(shippingEndpoints).promise;
      setShippings(res);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchShippings();
  }, []);

  return { shippings, fetchShippings };
}
