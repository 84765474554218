import React from 'react';

function SkillIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
    >
      <path d="M12.636 0l3.901 7.887 8.735 1.272-6.276 6.19L20.438 24l-7.802-4.07L4.834 24l1.526-8.65L0 9.159l8.735-1.272z" />
    </svg>
  );
}

export default SkillIcon;
