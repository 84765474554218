import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import styles from './Cart.module.css';

export default function Links({ confirm }) {
  return (
    <div className={styles['footer-link']}>
      <FontAwesomeIcon
        icon={faCaretLeft}
        style={{
          color: '#E4002B',
          fontSize: '1.2em',
          marginRight: '.5em',
        }}
      />
      <Link to="/profile" style={{ marginRight: '.5em' }}>
        Torna al profilo
      </Link>
      <div onClick={confirm} className="c-button">
        conferma
      </div>
    </div>
  );
}
