import { useEffect, useState } from 'react';

export default function useAnchorOnLoad(fetched) {
  const [currentHash, setCurrentHash] = useState('');

  const { hash } = window.location;

  if (currentHash !== hash) {
    setCurrentHash(hash);
  }

  useEffect(() => {
    if (currentHash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element)
        element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [currentHash, fetched, hash]);
}
