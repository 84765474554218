import React from 'react';
import Link from 'shared/components/Link';

export default function CoursesQuote({ data }) {
  return (
    <section className="c-content-block">
      <header className="c-page-header">
        <div className="l-container">
          <h2 className="c-page-title">{data.academy_custom_button.title}</h2>
        </div>
      </header>
      <div className="l-container">
        <div className="c-content c-content--picture">
          <div
            dangerouslySetInnerHTML={{
              __html: data.academy_custom_top.body,
            }}
          />

          <figure className="c-content__figure">
            <img src={data.academy_custom_mid.image} alt="truccheria" />
          </figure>
          <div className="c-content__text">
            <div
              dangerouslySetInnerHTML={{
                __html: data.academy_custom_mid.body,
              }}
            />
            <footer className="c-content__footer">
              <Link
                to={data.academy_custom_button.link}
                className="c-button c-button--small"
              >
                {data.academy_custom_button.title}
              </Link>
            </footer>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: data.academy_custom_bottom.body,
          }}
        />
      </div>
    </section>
  );
}
