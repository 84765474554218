import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import Breadcrumbs from '../../shared/components/Breadcrumbs';
import MainFooter from '../../shared/components/MainFooter';
import usePageData from '../../shared/hooks/usePageData';
import MetaTags from '../../shared/components/MetaTags';
import { getCatalog } from '../../store/app.reducer';

function CatalogItem({ cat, match, catalog }) {
  return (
    <article className="c-product-item">
      <Link to={`${match.url}/${cat.slug}`}>
        <figure className="c-product-item__picture">
          <img alt="catalog" src={cat && cat.content.image} />
        </figure>
        <h2 className="c-title">{cat && cat.content.title}</h2>
      </Link>
      <ul className="c-products-list">
        {catalog.map(
          el =>
            el.content.parent === cat?.content?.cat_id && (
              <li className="c-products-list__item" key={el.slug}>
                {el?.content?.title}
              </li>
            ),
        )}
      </ul>
    </article>
  );
}

export default function CatalogComp({ match }) {
  const [catPageFetched, , generic, boxesObj] = usePageData(
    `${endpoints.page}/catalog`,
  );

  const catalog = useSelector(getCatalog);

  if (catalog && catPageFetched) {
    return (
      <div>
        <MetaTags data={generic} />
        <main className="l-main">
          <Breadcrumbs />
          <div className="l-container l-products l-3col">
            {catalog.map(
              cat =>
                !cat.content.parent && (
                  <CatalogItem
                    key={cat.slug}
                    cat={cat}
                    match={match}
                    catalog={catalog}
                  />
                ),
            )}
          </div>
        </main>

        <MainFooter dynamicFooter={boxesObj.static_footer} />
      </div>
    );
  }

  return <Loader />;
}
