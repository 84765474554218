import React from 'react';
import { useSelector } from 'react-redux';

import { months } from '../../../shared/constants/dates';
import SubscribeRationale from './SubscribeRationale';
import { getDatastatic } from '../../../store/app.reducer';

function Box({ name, withoutBord, children }) {
  return (
    <div className="c-box-item">
      <p
        style={{
          textTransform: 'uppercase',
          marginBottom: '0.5em',
        }}
      >
        {name}
      </p>
      {children}
    </div>
  );
}

export default function InfoRow({ content }) {
  const dates = content.dates.map(d => new Date(d.date));
  const dateStrings = dates.map(
    date =>
      `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`,
  );

  const { academySchedules } = useSelector(getDatastatic);

  return (
    <div style={{ width: '100%', backgroundColor: '#000' }}>
      <div className="c-box-container">
        <Box name="Codice">
          <p>{content.code}</p>
        </Box>
        <Box name="Data">
          {dateStrings.map(d => (
            <p key={d} style={{margin: 0 }}>
              {d}
            </p>
          ))}
        </Box>
        <Box name="Orari">
          <p>
            {academySchedules[content.schedule]}
          </p>
        </Box>
        <Box name="Presso">
          <p>
            {`${content.location?.location} ${content.location?.address} ${
              content.location.city
            } ${content.location.phone}`}
          </p>
        </Box>
        <Box name="Prezzo" withoutBord>
          <p style={{ fontWeight: 'bold', margin: 0 }}>
            {content.type.price}+IVA
          </p>
          <p style={{margin: 0 }}>
            (acconto {content.type.deposit})
          </p>
        </Box>
      </div>
      <SubscribeRationale course={content} />
    </div>
  );
}
