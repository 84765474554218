import React from 'react';
import ReactPixel from 'react-facebook-pixel';

export default class FacebookPixel extends React.Component {

    componentDidMount() {
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);
        ReactPixel.pageView();
    }

    render() {
        ReactPixel.pageView();
        return null;
    }
}