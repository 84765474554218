import React from 'react';
import { Link } from 'react-router-dom';

import img from '../../../assets/img/banner-signup.png';
import img2 from '../../../assets/img/checked.png';
import styles from './SignupSuccess.module.css';
import MainFooter from '../../../shared/components/MainFooter';

export default function SignupSuccess() {
  return (
    <div>
      <main className="l-main">
        <div
          className={styles.content}
          style={{
            background: `url(${img})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
          <div className={styles.container}>
            <h1>REGISTRAZIONE AVVENUTA CON SUCCESSO</h1>
            <img src={img2} alt="checked" />

            <p>
              Grazie per esserti registrato su La Truccheria - MAKE UP FOR EVER.
            </p>
            <p>
              La tua password e ulteriori informazioni sono state inviate al tuo
              indirizzo e-mail.
            </p>

            <Link to="/">Torna alla homepage</Link>
          </div>
        </div>
      </main>
      <MainFooter dynamicFooter={{}} />
    </div>
  );
}
