import React, { useState } from 'react';

import styles from './RicercaDetail.module.css';

export default function Search({ match, history }) {
  const [term, setTerm] = useState(match.params.term);

  return (
    <>
      <main className={`${styles.main} l-main`}>
        <div className="l-container">
          <header className={styles.header}>
            <h1 className={styles.title}>Risultati della ricerca</h1>
            <p>
              Parole chiave: <strong>{term}</strong>
            </p>
          </header>
          <section>
            <a className={styles.item}>
              <h3 className={styles.item_title}>
                Double Ended Angled Eyebrow And Eyelush Brush Gb
              </h3>
              <p>
                ... Double Ended Angled Eyebrow And Eyelush Brush Gb Codice
                prodotto: 1212 ...
              </p>
            </a>
            <a className={styles.item}>
              <h3 className={styles.item_title}>
                Double Ended Angled Eyebrow And Eyelush Brush Gb
              </h3>
              <p>
                ... Double Ended Angled Eyebrow And Eyelush Brush Gb Codice
                prodotto: 1212 ...
              </p>
            </a>
            <a className={styles.item}>
              <h3 className={styles.item_title}>
                Double Ended Angled Eyebrow And Eyelush Brush Gb
              </h3>
              <p>
                ... Double Ended Angled Eyebrow And Eyelush Brush Gb Codice
                prodotto: 1212 ...
              </p>
            </a>
            <a className={styles.item}>
              <h3 className={styles.item_title}>
                Double Ended Angled Eyebrow And Eyelush Brush Gb
              </h3>
              <p>
                ... Double Ended Angled Eyebrow And Eyelush Brush Gb Codice
                prodotto: 1212 ...
              </p>
            </a>
            <a className={styles.item}>
              <h3 className={styles.item_title}>
                Double Ended Angled Eyebrow And Eyelush Brush Gb
              </h3>
              <p>
                ... Double Ended Angled Eyebrow And Eyelush Brush Gb Codice
                prodotto: 1212 ...
              </p>
            </a>
          </section>
          <footer className={styles.footer}>
            <a className={`${styles.icon} icon icon-arrow`} />
            <p>
              <strong>1</strong> di 5
            </p>
            <a className={`${styles.icon} icon icon-arrow`} />
          </footer>
        </div>
        <form
          className={`${styles.form} c-form c-form--search`}
          onSubmit={e => {
            e.preventDefault();
            history.push(`/cerca/${term}`);
          }}
        >
          <div className="l-container">
            <header className={styles.form_header}>
              <h2 className={styles.form_title}>Effettua un'altra ricerca</h2>
            </header>

            <div className={`${styles.form_field} c-form__field`}>
              <input
                style={{ color: '#fff' }}
                className={`${styles.form_input} c-form__field__input`}
                type="text"
                name="cerca"
                onChange={e => setTerm(e.target.value)}
              />
              <span className="icon icon-search" />
              <button
                type="submit"
                className={`${styles.form_submit} c-button`}
              >
                <span className="icon icon-search" />
                <span>Cerca</span>
              </button>
            </div>
          </div>
        </form>
      </main>
    </>
  );
}
