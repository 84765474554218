import React from 'react';
import { Helmet } from 'react-helmet';

export default function MetaTags({ data }) {
  const { title, og_title, og_type, og_image, og_desc, desc } = data;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="title" content={title} />
      <meta property="description" content={desc} />

      <meta property="og:title" content={og_title} />
      <meta property="og:type" content={og_type} />
      <meta property="og:image" content={og_image} />
      <meta property="og:description" content={og_desc} />
      <meta property="twitter:type" content={og_type} />
      <meta property="twitter:description" content={og_desc} />
      <meta property="twitter:title" content={og_title} />
      <meta property="twitter:image" content={og_image} />
    </Helmet>
  );
}
