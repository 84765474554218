import React from 'react';

import Mandatory from '../Mandatory';
import styles from './CatSelection.module.css';

export default function CatSelection({ children }) {
  return (
    <>
      <label htmlFor="prof" style={{ marginBottom: 5 }}>
        <span>
          Categoria professionale
          <Mandatory />
        </span>
        {children}
      </label>
      <p className={styles.subtext} style={{ alignSelf: 'flex-end' }}>
        In base alla categoria professionale selezionata ti sarà richiesto di
        inviarci la relativa documentazione.
      </p>
    </>
  );
}
