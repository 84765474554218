import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getDatastatic } from '../../../store/app.reducer';

export const CitiesTable = () => {
  const { academyLocations } = useSelector(getDatastatic);
  return (
    <div className="c-where l-5col">
      {academyLocations.map((loc, i) => (
        <Link
          to={`/academy_homepage/dove/${loc.slug}`}
          key={loc.key}
          className="c-button c-button--where"
        >
          {loc.value}
        </Link>
      ))}
    </div>
  );
};

export default function CoursesCalendar({ data }) {
  return (
    <section
      className="c-content-block c-content-block--center"
      style={{ paddingTop: 0, marginTop: 0 }}
    >
      <header className="c-page-header">
        <div className="l-container">
          <h2 className="c-page-title">{data.academy_locations.title}</h2>
        </div>
      </header>
      <div className="c-content l-container">
        <div
          dangerouslySetInnerHTML={{
            __html: data.academy_locations.body,
          }}
        />
        <CitiesTable />
      </div>
    </section>
  );
}
