import React from 'react';
import { Link } from 'react-router-dom';

export default function TruccheriaCourses({ courses, info }) {
  return (
    <section className="c-content-block c-content-block--center">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header">
          <h2 className="c-title c-title--black c-title--big">
            {info && info.title}
          </h2>
        </header>

        <div className="c-classes">
          {courses.map(c => (
            <Link
              to={`/academy-la-truccheria/${c.slug}`}
              className="c-class"
              key={c.slug}
            >
              <figure className="c-class__picture">
                <img src={c.content.image} alt="prodotto" />
              </figure>
              <div className="c-class__content">
                <h3 className="c-class__title c-title c-title--black">
                  {c.content.name}
                </h3>
                <h4 className="c-class__subtitle c-subtitle">
                  {c.content.sub_title}
                </h4>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}
