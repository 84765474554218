import React from 'react';

export default function Teachers({ info, profiles }) {
  return (
    <section className="c-content-block c-content-block--dark">
      <div className="l-container c-content-block__container">
        <header className="c-content-block__header">
          <h2 className="c-title c-title--big">{info.title}</h2>
        </header>
        <div className="c-content">
          <p
            dangerouslySetInnerHTML={{
              __html: info.body,
            }}
          />

          <div className="l-3col c-teachings">
            {profiles.map(
              el =>
                el.image &&
                el.title && (
                  <article className="c-teaching" key={el.name}>
                    <figure className="c-teaching__figure">
                      <img alt="" src={el.image} />
                    </figure>
                    <h3 className="c-title c-teaching__title">{el.title}</h3>
                    <div
                      className="c-content__text"
                      dangerouslySetInnerHTML={{
                        __html: el.body,
                      }}
                    />
                  </article>
                ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
