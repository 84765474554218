import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import Link from '../../../shared/components/Link';

export default function CoursesCalendar({ data }) {
  return (
    <section className="c-content-block c-content-block--rose c-content-block--center">
      <div className="l-container c-content-block__container">
        <h2 className="c-title">
          {data.academy_subsection && data.academy_subsection.title}
        </h2>
        <div className="c-downloads">
          <Link
            to={
              data.academy_subsection_link1 &&
              data.academy_subsection_link1.link
            }
            className="c-button c-button--ghost c-button--big"
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{
                fontSize: '1.2em',
                marginRight: '.1em',
              }}
            />
            {data.academy_subsection_link1 &&
              data.academy_subsection_link1.title}
          </Link>
          <Link
            to={
              data.academy_subsection_link2 &&
              data.academy_subsection_link2.link
            }
            className="c-button c-button--ghost c-button--big"
          >
            <FontAwesomeIcon
              icon={faFilePdf}
              style={{
                fontSize: '1.2em',
                marginRight: '.1em',
              }}
            />
            {data.academy_subsection_link2 &&
              data.academy_subsection_link2.title}
          </Link>
        </div>
      </div>
    </section>
  );
}
