import React from 'react';

import logoRed from '../../../../assets/img/logo-red.png';
import style from './ServiceContacts.module.css';

export default function ServiceContacts({ info }) {
  return (
    <section className="c-page-block">
      <header className="c-page-header">
        <h2 className="c-page-title">{info.title}</h2>
      </header>

      <div className="c-map">
        <div className="j-map">
          <iframe
            title="mappa"
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d522.824982062779!2d11.342152154235107!3d44.498365041136765!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sit!2sit!4v1516206085044"
            width="600"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
      </div>

      <div
        className="l-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <article className="c-contact">
          <header className="c-contact__header">
            <img className="c-contact__header__logo" src={logoRed} alt="logo" />
          </header>

          <div
            style={{
              fontSize: '20px',
              color: '#606060',
              lineHeight: '40px',
              marginBottom: '15px',
            }}
            className={`c-contact-list ${style.info}`}
            dangerouslySetInnerHTML={{ __html: info.body }}
          />
        </article>
      </div>
    </section>
  );
}
