import React from 'react';

import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import MainFooter from '../../shared/components/MainFooter';
import usePageData from '../../shared/hooks/usePageData';
import Header from './components/Header';
import Box from './components/Box';
import SearchCourses from './components/SearchCourses';
import CoursesCalendar from './components/CoursesCalendar';
import CoursesCities from './components/CoursesCities';
import CoursesInfo from './components/CoursesInfo';
import CoursesQuote from './components/CoursesQuote';
import MetaTags from '../../shared/components/MetaTags';
import NotFound from 'Features/NotFound/NotFound';

export default function AcademyCointainer() {
  const [fetched, , generic, boxesObj, content] = usePageData(
    `${endpoints.page}/academy_homepage`,
  );

  if (fetched) {
    if (content) {
      return (
        <div>
          <MetaTags data={generic} />
          <main className="l-main">
            <Header
              image={boxesObj.static_topsection.academy_topsection.image}
              title={boxesObj.static_topsection.academy_topsection.title}
            />
            <section className="c-content-block c-content-block--rose-dark">
              <div className="l-container c-content-block__container l-flex">
                {Object.keys(boxesObj.dynamic_academy_types).map(type => (
                  <Box
                    key={boxesObj.dynamic_academy_types[type].name}
                    data={boxesObj.dynamic_academy_types[type]}
                  />
                ))}
              </div>
            </section>
            <SearchCourses data={boxesObj.static_search || {}} />
            <CoursesCalendar data={boxesObj.static_subsection || {}} />
            <CoursesCities data={boxesObj.static_locations || {}} />
            <CoursesInfo data={boxesObj.static_individual || {}} />
            <CoursesQuote data={boxesObj.static_custom || {}} />
          </main>
          <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
        </div>
      );
    } else {
      return <NotFound />;
    }
  }

  return <Loader />;
}
