import * as types from './access.action-types';
import Api from '../../shared/services/api';
import Swal from 'sweetalert2';
import * as endpoints from '../../shared/constants/endpoints';
import { fetchProfile } from '../Profile/store/profile.action-creators';
import { getRefreshToken } from '../../store/app.reducer';

export function login(username, password, location, history) {
  return async dispatch => {
    dispatch({
      type: types.LOGIN_REQUEST,
    });
    try {
      const res = await Api.post(endpoints.login, { username, password }, false)
        .promise;
      Api.setToken(res.token);
      localStorage.setItem('token', res.token);
      dispatch({
        type: types.LOGIN_SUCCESS,
        token: res.token,
        refreshToken: res.refresh_token,
      });
      dispatch(fetchProfile());
      if (
        location.pathname.includes('signup') ||
        location.pathname.includes('/password/reset') ||
        location.pathname === '/profile/mail_change'
      ) {
        history.push('/');
      }
      return res;
    } catch (e) {
      // console.log(e);
      Swal.fire(
        'Errore',
        e.message,
        'error',
      );
      dispatch({
        type: types.LOGIN_FAILURE,
      });
    }
  };
}

export function logout(history) {
  return async dispatch => {
    localStorage.removeItem('token');
    Api.setToken(null);
    dispatch({
      type: types.LOGOUT,
    });
    if (typeof history === 'object' && typeof history.push === 'function') {
      history.push('/');
    }
  };
}

export function refreshToken(history) {
  return async (dispatch, getState) => {
    console.log('delegate, refreshtoken:', getRefreshToken(getState()));
    try {
      const res = await Api.post(
        endpoints.delegate,
        { refresh_token: getRefreshToken(getState()) },
        false,
      ).promise;
      Api.setToken(res.token);
      localStorage.setItem('token', res.token);
      dispatch({
        type: types.LOGIN_SUCCESS,
        token: res.token,
        refreshToken: res.refresh_token,
      });
      dispatch(fetchProfile());
    } catch (e) {
      console.log(e);
      dispatch(logout(history));
    }
  };
}
