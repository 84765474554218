import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import JwtDecode from 'jwt-decode';
import { differenceInCalendarDays, isFuture } from 'date-fns';

import styles from './Anagraphic.module.css';
import { getToken } from '../../../../store/app.reducer';

export default function Anagraphic({
  profile,
  newsletter,
  setNewsSubscription: setSub,
}) {
  const token = useSelector(getToken);
  const jwt = token && JwtDecode(token);

  console.log(profile, jwt);

  return (
    <div className={styles.wrapper}>
      <div className={styles['anagraphic-container']}>
        <div className={styles['item-container']}>
          <p>Nome</p>
          <p>{profile.nome}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Cognome</p>
          <p>{profile.cognome}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Telefono</p>
          <p>{profile.telefono}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Indirizzo</p>
          <p>{profile.indirizzo}</p>
        </div>
        <div className={styles['item-container']}>
          <p>CAP</p>
          <p>{profile.cap}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Indirizzo e-mail</p>
          <p>{jwt.email}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Città</p>
          <p>{profile.citta}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Provincia</p>
          <p>{profile.prov}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Codice Fiscale</p>
          <p>{profile.codfiscale}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Partita IVA</p>
          <p>{profile.partitaiva}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Ragione sociale</p>
          <p>{profile.ragsoc}</p>
        </div>
        <div className={styles['item-container']}>
          <p>Nazione</p>
          <p>{profile.paese}</p>
        </div>
        <div className={styles['checkbox-container']}>
          <input
            type="checkbox"
            id="newsletter"
            checked={newsletter}
            onChange={e => setSub(e.target.checked)}
          />
          <p>Iscrizione alla newsletter</p>
        </div>
      </div>
      {jwt.list_expiration_timestamp && (
        <div className={styles['bottom-banner']}>
          {isFuture(new Date(jwt.list_expiration_timestamp)) ? (
            <p>
              Tempo residuo per usufruire dell'e-commerce con i prezzi dedicati:{' '}
              <b>
                {differenceInCalendarDays(
                  new Date(),
                  new Date(jwt.list_expiration_timestamp),
                )}{' '}
                giorni
              </b>
            </p>
          ) : (
            <p>
              Non è più possibile di usufruire i prezzi dedicati.
              <br />
              <Link style={{ textDecoration: 'underline' }} to="/contatti">
                Contattare
              </Link>{' '}
              la truccheria per ottenere maggiori informazioni.
            </p>
          )}
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Link
          to="/profile/password_change"
          className="c-button c-button--light"
          style={{ marginRight: '1em' }}
        >
          Cambia la tua password
        </Link>
        <Link to="/profile/mail_change" className="c-button c-button--light">
          Cambia la tua mail
        </Link>
      </div>
    </div>
  );
}
