import * as types from './profile.action-types';
import * as endpoints from '../../../shared/constants/endpoints';
import Api from '../../../shared/services/api';

export function fetchProfile() {
  return async dispatch => {
    dispatch({
      type: types.PROFILE_REQUEST,
    });

    try {
      const res = await Api.get(endpoints.profile).promise;
      const newsRes = await Api.get(endpoints.newsletter).promise;
      dispatch({
        type: types.PROFILE_SUCCESS,
        profile: res.account,
        email: res.email,
        newsletter: newsRes.subscribed,
      });
    } catch (e) {
      dispatch({
        type: types.PROFILE_FAILURE,
      });
    }
  };
}

export function setNewsSubscription(subscribe) {
  // eslint-disable-next-line no-param-reassign
  subscribe = subscribe ? 1 : 0;

  return async dispatch => {
    dispatch({
      type: types.EDIT_PROFILE_REQUEST,
    });
    try {
      const res = await Api.post(endpoints.newsletter, { subscribe }).promise;
      dispatch({
        type: types.EDIT_PROFILE_SUCCESS,
        edits: {
          newsletter: res.subscribed,
        },
      });
    } catch (e) {
      dispatch({
        type: types.EDIT_PROFILE_FAILURE,
      });
    }
  };
}
