import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { getToken } from '../../store/app.reducer';
import { refreshToken } from '../../Features/Access/access.action-creators';
import useFetchCatalog from '../hooks/useFetchCatalog';
import useFetchDatastatics from '../hooks/useFetchDatastatics';

export default function BootstrapLogic({ loader, children, history }) {
  useFetchCatalog();
  useFetchDatastatics();

  const dispatch = useDispatch();
  const token = useSelector(getToken);

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp <= Date.now() / 1000) {
        dispatch(refreshToken(history));
      }
    }
  }, [token, dispatch, loader, history]);

  return children;
}
