import React from 'react';

export default function LoginButton({ toggle }) {
  return (
    <div
      tabIndex={0}
      role="button"
      style={{ cursor: 'pointer' }}
      className="c-site-header__actions__item j-login"
      onClick={toggle}
    >
      Login
    </div>
  );
}
