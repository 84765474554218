import React from 'react';
import { Link } from 'react-router-dom';

export default function ProfileButton({ profile }) {
  if (!profile) {
    return null;
  }
  return (
    <Link
      to="/profile"
      tabIndex={0}
      role="button"
      style={{ cursor: 'pointer' }}
      className="c-site-header__actions__item j-login"
    >
      {profile.nome || profile.cognome
        ? `${profile.nome} ${profile.cognome}`
        : `${profile.ragsoc}`}
    </Link>
  );
}
