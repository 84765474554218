import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../../shared/components/Loader';
import * as endpoints from '../../../shared/constants/endpoints';
import MainFooter from '../../../shared/components/MainFooter';
import usePageData from '../../../shared/hooks/usePageData';
import AcademyFooter from '../components/AcademyFooter';
import Comments from '../components/Comments';
import Teachers from '../components/Teachers';
import AcademyTopSection from '../components/AcademyTopSection';
import TruccheriaCourses from './TruccheriaCourses';
import MetaTags from '../../../shared/components/MetaTags';
import { getDatastatic } from '../../../store/app.reducer';
import NotFound from 'Features/NotFound/NotFound';

export default function LaTruccheria() {
  const [fetched, boxes, generic, boxesObj, content] = usePageData(
    `${endpoints.page}/academy-la-truccheria`,
  );

  const { academyTypes } = useSelector(getDatastatic);

  if (fetched) {
    if (content) {
      return (
        <div>
          <MetaTags data={generic} />

          <main className="l-main">
            <AcademyTopSection
              data={boxesObj.dynamic_academy_types['academy_type_top-truccheria']}
              header={
                boxesObj.dynamic_academy_types?.[
                  'academy_type_topsection-truccheria'
                  ]
              }
            />
            <TruccheriaCourses
              courses={academyTypes.filter(c => c.content?.type === 'truccheria')}
              info={
                boxesObj.dynamic_academy_types['academy_type_types-truccheria']
              }
            />
            <Teachers
              info={
                boxesObj.dynamic_academy_types['academy_type_staff-truccheria']
              }
              profiles={boxes.dynamic_academy_types.filter(el =>
                el.name.includes('academy_type_profile'),
              )}
            />
            <Comments
              comments={boxes.dynamic_academy_types.filter(
                el =>
                  el.name.includes('comment') &&
                  el.name !== 'academy_type_comments-truccheria',
              )}
              info={
                boxesObj.dynamic_academy_types['academy_type_comments-truccheria']
              }
            />
            <AcademyFooter
              data={boxesObj.static_academy_footer.academy_footer || {}}
            />
          </main>
          <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
        </div>
      );
    } else {
      return <NotFound />;
    }
  }

  return <Loader />;
}
