import React from 'react';

import TopSection from '../../../shared/components/TopSection';
import MetaTags from '../../../shared/components/MetaTags';
import * as endpoints from '../../../shared/constants/endpoints';
import usePageData from '../../../shared/hooks/usePageData';
import MainFooter from '../../../shared/components/MainFooter';
import Loader from '../../../shared/components/Loader';
import InfoRow from './InfoRow';
import ContactForm from '../../Contacts/components/ContactForm';
import NotFound from 'Features/NotFound/NotFound';

export default function SingolaData({ match }) {
  const [fetched, , generic, boxesObj, content] = usePageData(
    endpoints.courseDateDetail(match.params.course),
  );

  if (!fetched) return <Loader />;

  if (!content) return <NotFound />;

  return (
    <div>
      <MetaTags data={generic} />
      <main className="l-main">
        <TopSection
          data={{
            title: content.type.name,
            image: content.type.image,
          }}
          subtitle={content.type.sub_title}
        />
        <InfoRow content={content} />
        <div
          style={{
            backgroundColor: '#E4002B',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1
            style={{
              fontSize: '24px',
              margin: '2em 0 1em 0',
              textTransform: 'uppercase',
              color: '#fff',
            }}
          >
            Contattaci per maggiori informazioni.
          </h1>
          <ContactForm
            messagePrefix={`${content.type.name}\n`}
            hideCat
            catID={process.env.REACT_APP_CATEGORY_FORM_ACADEMY}
          />
        </div>
      </main>
      <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
    </div>
  );
}
