import React from 'react';

import Link from '../../../shared/components/Link';

export default function Filosofia({ data }) {
  return (
    <section className="c-content c-content--filosofia">
      <div className="l-container">
        <header className="c-content__header">
          <h2 className="c-section-title">{data[0]?.title}</h2>
        </header>
        <div className="c-content__text">
          <div
            dangerouslySetInnerHTML={{
              __html: data[0]?.body,
            }}
          />

          <div className="l-4col">
            {data.map(cat => {
              if (!cat.name.includes('cat')) {
                return null;
              }
              return (
                <div className="c-filosofy-item" key={cat.name}>
                  <Link to={cat.link}>
                    <figure className="c-filosofy-item__figure">
                      <img alt="immagine categoria" src={cat.image} />
                    </figure>
                  </Link>
                  <div className="c-filosofy-item__content">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cat.body,
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
