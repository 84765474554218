import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import JwtDecode from 'jwt-decode';
import { HashLink } from 'react-router-hash-link';

import { getCart, getToken } from '../../../../../store/app.reducer';
import Link from '../../../Link';

const CartLink = ({ canBuy, ...props }) => {
  return props.canBuy ? (
    <Link {...props}>{props.children}</Link>
  ) : (
    <HashLink smooth {...props}>
      {props.children}
    </HashLink>
  );
};

export default function CartButton() {
  const cart = useSelector(getCart);
  const token = useSelector(getToken);

  const jwt = token && JwtDecode(token);

  return (
    <CartLink
      canBuy={jwt.can_buy}
      to={jwt.can_buy ? '/cart' : '/profile#venditori'}
      className="c-site-header__actions__item"
      onClick={document.body.classList.remove('overflow-blocked')}
      style={{ position: 'relative' }}
    >
      {!!cart.length && (
        <span className="c-site-header__actions__item__cart">
          {cart.length}
        </span>
      )}
      <FontAwesomeIcon icon={faShoppingCart} />
    </CartLink>
  );
}
