import React, { Fragment } from 'react';

export default function Biografia({ data }) {
  return (
    <Fragment>
      <article className="c-content c-content--company">
        <div className="l-container">
          <header className="c-content__header">
            <figure className="c-content__header__figure">
              <img alt="header banner" src={data[0] && data[0].image} />
            </figure>
            <h2 className="c-subtitle">{data[0] && data[0].title}</h2>
          </header>
          <div className="c-content__text c-content__text--2col">
            <div
              dangerouslySetInnerHTML={{
                __html: data[0] && data[0].body,
              }}
            />
          </div>
        </div>
      </article>
      <section className="c-content c-content--makeup">
        <div className="l-container">
          <div className="c-content__text">
            <div
              dangerouslySetInnerHTML={{
                __html: data[1] && data[1].body,
              }}
            />
          </div>
        </div>
      </section>
      <section className="c-content c-content--rose">
        <div className="c-content__text c-content__text--2col">
          <div className="l-container">
            <div
              dangerouslySetInnerHTML={{
                __html: data[2] && data[2].body,
              }}
            />
          </div>
        </div>
      </section>
      <a className="c-cta" href="#">
        <figure className="c-cta__picture">
          <img
            alt="foto azienda"
            className="mobile"
            src={data[3] && data[3].image}
          />
          <img
            alt="foto azienda"
            className="desktop"
            src={data[3] && data[3].image}
          />
        </figure>
      </a>
    </Fragment>
  );
}
