import React, { Fragment } from 'react';

export default function Storia({ data }) {
  return (
    <Fragment>
      <header className="c-hero c-hero--fullwidth">
        <figure className="c-hero__picture">
          <img alt="foto persona" src={data[0] && data[0].image} />
        </figure>
        <div className="l-container">
          <h2 className="c-hero__title">{data[0] && data[0].title}</h2>
        </div>
      </header>

      <div className="l-container">
        <div className="c-content c-content--special">
          <div className="c-content__text">
            <div
              dangerouslySetInnerHTML={{
                __html: data[1] && data[1].body,
              }}
            />

            <figure className="u-align-left">
              <img alt="foto persona" src={data[1] && data[1].image} />
            </figure>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
