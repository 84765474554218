import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Result.module.css';
import Links from './Links';
import checkIcon from '../../../../assets/img/check-icon.png';
import errorIcon from '../../../../assets/img/error-icon.png';
import CommonFrame from '../components/CommonFrame/CommonFrame';

function SuccessBody() {
  return (
    <div className={styles.body}>
      <h2>transazione avvenuta con successo</h2>
      <img src={checkIcon} className={styles.icon} alt="success" />
      <p className={styles.title}>Grazie per aver acquistato!</p>
      <p className={styles.descr}>
        A breve riceverai un'email con la conferma di acquisto
      </p>
      <Link to="/profile">
        <span
          className="c-button c-button--light"
          style={{
            fontWeight: 'bold',
            margin: '2em 0',
            fontSize: '1.5em',
          }}
        >
          Visualizza il mio Profilo
        </span>
      </Link>
    </div>
  );
}

function FailureBody() {
  return (
    <div className={styles.body}>
      <h2>transazione fallita</h2>
      <img src={errorIcon} className={styles.icon} alt="errore" />
      <p className={styles.title}>
        Abbiamo riscontrato problemi durante la transazione.
      </p>
      <p className={styles.descr}>Contatta l'assistenza prima di riprovare</p>
      <Link to="/profile">
        <span
          className="c-button c-button--light"
          style={{
            fontWeight: 'bold',
            margin: '2em 0',
            fontSize: '1.5em',
          }}
        >
          Visualizza il mio Profilo
        </span>
      </Link>
    </div>
  );
}

export default function Result({ match }) {
  const success = match.params.status === 'success';
  return (
    <CommonFrame
      links={<Links />}
      title="esito pagamento"
      subtitle="Conferma transazione"
    >
      <div className="l-container">
        {success ? <SuccessBody /> : <FailureBody />}
      </div>
    </CommonFrame>
  );
}
