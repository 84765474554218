import React from 'react';
import { useSelector } from 'react-redux';

import Link from '../../../shared/components/Link';
import { getDatastatic } from '../../../store/app.reducer';

export default function Sidebar({ toggleOpen }) {
  const { academyTypes } = useSelector(getDatastatic);

  return (
    <aside className="c-sidebar">
      <header className="c-sidebar__header">
        <a className="c-sidebar__toggle" onClick={toggleOpen}>
          <span className="c-sidebar__toggle__item" />
          <span className="c-sidebar__toggle__item" />
          <span className="c-sidebar__toggle__item" />
        </a>
        <h2 className="c-title c-sidebar__title">Tutti i corsi</h2>
      </header>

      <div className="c-sidebar__content">
        <header className="c-sidebar__content-header">
          <h3 className="c-title c-title--black c-sidebar__content-title">
            Gb Pro Make up academy
          </h3>
        </header>
        {academyTypes.map(
          el =>
            el.content.type === 'gbpro' && (
              <article className="c-course">
                <figure className="c-course__figure">
                  <img src={el.content.image} alt="corso" />
                </figure>
                <div className="c-course__content">
                  <h3 className="c-course__title">{el.title}</h3>
                  <h4 className="c-course__lead">{el.content.type.skill}</h4>
                  <Link
                    to={`/academy-gb-pro/${el.slug}`}
                    className="c-course__button"
                  >
                    Scopri di più
                  </Link>
                </div>
              </article>
            ),
        )}
      </div>
      <div className="c-sidebar__content">
        <header className="c-sidebar__content-header">
          <h3 className="c-title c-title--black c-sidebar__content-title">
            La truccheria make up academy
          </h3>
        </header>
        {academyTypes.map(
          el =>
            el.content.type === 'truccheria' && (
              <article className="c-course">
                <figure className="c-course__figure">
                  <img src={el.content.image} alt="corso" />
                </figure>
                <div className="c-course__content">
                  <h3 className="c-course__title">{el.title}</h3>
                  <h4 className="c-course__lead">{el.content.type.skill}</h4>
                  <Link
                    to={`/academy-gb-pro/${el.slug}`}
                    className="c-course__button"
                  >
                    Scopri di più
                  </Link>
                </div>
              </article>
            ),
        )}
      </div>
    </aside>
  );
}
