import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import styles from './SlidingDownloadSection.module.css';

export default function SlidingDownloadSection({
  title,
  children,
  openedByDefault = false,
}) {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(openedByDefault);
  }, [openedByDefault]);

  return (
    <div
      tabIndex={0}
      role="button"
      className={[styles.container, opened ? styles.opened : []].join(' ')}
      onClick={() => setOpened(!opened)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '.8em auto',
        }}
      >
        <h2>{title}</h2>
        <div className={styles['icon-container']}>
          <FontAwesomeIcon
            icon={opened ? faMinus : faPlus}
            className={styles['expand-icon']}
          />
        </div>
      </div>

      <div>{children}</div>
    </div>
  );
}
