import React from 'react';

import Loader from '../../shared/components/Loader';
import * as endpoints from '../../shared/constants/endpoints';
import MainFooter from '../../shared/components/MainFooter';
import usePageData from '../../shared/hooks/usePageData';
import useSlides from '../../shared/hooks/useSlides';
import HeroSlider from './components/HeroSlider';
import HomeItemSmall from './components/HomeItemSmall';
import HomeItemLarge from './components/HomeItemLarge';
import CentriBox from '../../shared/components/Distribution/CentriBox';
import AgentsBox from '../../shared/components/Distribution/AgentsBox';
import MetaTags from '../../shared/components/MetaTags';

export default function CompanyContainer() {
  const [fetched, boxes, generic, boxesObj, content] = usePageData(
    `${endpoints.page}/homepage`,
  );

  const slides = useSlides();

  if (fetched && content) {
    return (
      <div>
        <MetaTags data={generic} />
        <main className="l-main">
          <HeroSlider slides={slides || []} />
          <HomeItemSmall items={boxes.static_topsections || []} />
          <HomeItemLarge items={boxes.static_banners || []} />
          <div className="c-home-retailers" style={{ display: 'flex' }}>
            <CentriBox data={boxesObj.static_subbanners.home_subbanner1} />
            <AgentsBox data={boxesObj.static_subbanners.home_subbanner2} />
          </div>
        </main>
        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </div>
    );
  }

  return <Loader />;
}
