import React, { useRef } from 'react';
import Slider from 'react-slick/lib';

import Link from '../../../shared/components/Link';

function Prev({ onClick }) {
  return (
    <button
      type="button"
      className="slick-truccheria-prev slick-arrow"
      onClick={onClick}
    >
      <span className="icon icon-arrow" />
    </button>
  );
}
function Next({ onClick }) {
  return (
    <button
      type="button"
      className="slick-truccheria-next slick-arrow"
      onClick={onClick}
    >
      <span className="icon icon-arrow" />
    </button>
  );
}

function VideoSlide({ slide, slider }) {
  return (
    <div
      onClick={() => slider.current.slickPause()}
      className="c-slide"
      key={Date.now()}
    >
      <video poster={slide.image} className="c-picture" controls>
        <source src={slide.video} type="video/mp4" />
      </video>
    </div>
  );
}

export default function HeroSlider({ slides, hideOverlay = false }) {
  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <Prev />,
    nextArrow: <Next />,
    focusOnSelect: true,
    pauseOnHover: false,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const slider = useRef(null);

  return (
    <header className="c-hero c-hero--slider">
      <Slider {...settings} ref={slider}>
        {slides.map((slide, i) => {
          if (slide.video) {
            return <VideoSlide slide={slide} key={i} slider={slider} />;
          }

          return (
            <div className="c-slide" key={Date.now()}>
              {slide.image && (
                <figure className="c-picture">
                  <img alt="artworks" src={slide.image} />
                </figure>
              )}
              {!hideOverlay && (
                <article className={`c-slide__article ${slide.type}`}>
                  {slide.image_sub && (
                    <figure
                      className={`c-slide__article__picture ${slide.type}`}
                    >
                      <img src={slide.image_sub} alt="logo" />
                    </figure>
                  )}
                  <div className={`c-slide__article__text ${slide.type}`}>
                    <h2 className="c-title">{slide.title}</h2>
                    <div
                      className="c-subtitle"
                      dangerouslySetInnerHTML={{ __html: slide.desc }}
                    />
                  </div>
                  {slide.link && (
                    <Link
                      style={{ zIndex: 999 }}
                      className="c-button"
                      to={slide.link}
                    >
                      Scopri
                    </Link>
                  )}
                </article>
              )}
            </div>
          );
        })}
      </Slider>
    </header>
  );
}
