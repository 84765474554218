import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import Link from '../../../../shared/components/Link';

export default function ListItem({ item, noInfo = false }) {
  return (
    <li className="c-agenti-distributori__list__item">
      <strong>
        {item.citta} {item.prov}
      </strong>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className="c-agenti-distributori__list__item__name">
          {item.ragsoc}
        </span>
        {!noInfo && item.link && (
          <Link
            to={item.link}
            style={{ marginLeft: '1em', display: 'flex', alignItems: 'center' }}
          >
            <FontAwesomeIcon
              icon={faCaretRight}
              style={{
                color: '#E4002B',
                fontSize: '1.2em',
                marginRight: '.1em',
              }}
            />
            Info
          </Link>
        )}
      </div>

      <span>{item.indirizzo}</span>

      {item.web && <span>Sito web:{item.web}</span>}

      <span>
        Tel.
        {item.telefono}
      </span>

      {item.zones && <b>{item.zones}</b>}

      <Link
        to={
          item.contact_id
            ? `/contatti/${item.contact_id}/${item.ragsoc}#contact-form`
            : '/contatti'
        }
        style={{ margin: '.5rem 0', display: 'inline-block' }}
        className="c-button c-button--light"
      >
        Contattaci
      </Link>

      <a href="#" className="c-agenti-distributori__list__item__link-maps">
        Apri in google maps
      </a>
    </li>
  );
}
