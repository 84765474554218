import React, { useState, Fragment } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

import styles from './ContactForm.module.css';
import Api from '../../../shared/services/api';
import * as endpoints from '../../../shared/constants/endpoints';
import { getDatastatic } from '../../../store/app.reducer';

function Field({ children, error, style = {} }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', ...style }}>
      {children}
      <p style={{ color: '#fff' }}>{error}</p>
    </div>
  );
}

export default function ContactForm({
  catID,
  style,
  FooterComp,
  hideCat,
  messagePrefix,
}) {
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('idle');
  const [formData, setFormData] = useState({ category: catID });
  const { provs, contactsCats } = useSelector(getDatastatic);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendMail = async e => {
    e.preventDefault();
    try {
      setStatus('pending');
      const token = await executeRecaptcha();
      const res = await Api.post(endpoints.contact, {
        ...formData,
        message: messagePrefix
          ? messagePrefix + formData.message
          : formData.message,
        'g-recaptcha': token,
      }).promise;
      if (res.status === 200) {
        Swal.fire(
          'Ottimo!',
          res.message || 'Il messaggio è stato correttamente inviato',
          'success',
        );
        resetForm();
        setStatus('fulfilled');
      }
    } catch (e) {
      setStatus('rejected');
      setErrors(e.children);
      console.log(e);
    }
  };

  const resetForm = () => setFormData({});

  const setForm = e => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setFormData(d => ({ ...d, [name]: value }));
  };

  return (
    <Fragment>
      <form className={styles.form} style={style} id="contact-form">
        <Field error={errors?.name?.errors?.[0]}>
          <input
            className="c-form__field__input"
            type="text"
            name="name"
            placeholder="Nome*"
            onChange={setForm}
            value={formData.name || ''}
          />
        </Field>

        <Field error={errors?.phone?.errors?.[0]}>
          <input
            className="c-form__field__input"
            type="text"
            name="phone"
            placeholder="Cellulare*"
            onChange={setForm}
            value={formData.phone || ''}
          />
        </Field>

        <Field error={errors?.prov?.errors?.[0]}>
          <div className="c-select-container">
            <select
              className="c-select"
              name="prov"
              value={formData.prov || ''}
              onChange={setForm}
              style={{ color: formData.prov ? 'inherit' : '#95989a' }}
            >
              <option value="">Provincia, Regione*</option>
              {provs.map(prov => (
                <option key={prov.key} value={prov.key || ''}>
                  {prov.value}
                </option>
              ))}
            </select>
          </div>
        </Field>

        {!hideCat && (
          <Field error={errors?.category?.errors?.[0]}>
            <div className="c-select-container">
              <select
                className="c-select"
                name="category"
                onChange={setForm}
                value={formData.category || ''}
                placeholder="Categoria*"
                style={{ color: formData.category ? 'inherit' : '#95989a' }}
              >
                <option value="">Categoria*</option>
                {contactsCats.map(cat => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
          </Field>
        )}

        <Field
          error={errors?.email?.errors?.[0]}
          style={{ ...(!hideCat && { width: 'calc(100% - 0.8em)' }) }}
        >
          <input
            className="c-form__field__input"
            type="text"
            name="email"
            placeholder="Email*"
            onChange={setForm}
            value={formData.email || ''}
          />
        </Field>

        <textarea
          style={{ marginTop: '10px' }}
          className="c-form__field__input"
          type="text"
          name="message"
          placeholder="Il tuo messaggio*"
          onChange={setForm}
          value={formData.message || ''}
        />
        {FooterComp && <FooterComp onClick={sendMail} />}
        {!FooterComp && (
          <input
            value="Invia"
            type="submit"
            disabled={status === 'pending'}
            className="c-button"
            style={{ margin: '1em 0 2em 0' }}
            onClick={sendMail}
          />
        )}
      </form>
    </Fragment>
  );
}
