import React, { Fragment } from 'react';
import CentriBox from 'shared/components/Distribution/CentriBox';
import AgentsBox from 'shared/components/Distribution/AgentsBox';

import mappa from '../../assets/img/mappa-contatti-truccheria.png';
import styles from './Contacts.module.css';
import TitleBar from '../../shared/components/TitleBar';
import MainFooter from '../../shared/components/MainFooter';
import Loader from '../../shared/components/Loader';
import usePageData from '../../shared/hooks/usePageData';
import * as endpoints from '../../shared/constants/endpoints';
import ContactForm from './components/ContactForm';
import MetaTags from '../../shared/components/MetaTags';

export default function Contacts({ match }) {
  const [fetched, boxes, generic, boxesObj, content] = usePageData(
    `${endpoints.page}/contatti`,
  );

  if (fetched && content) {
    return (
      <Fragment>
        <MetaTags data={generic} />

        <TitleBar title={boxes.contatti_topsection.title} />
        <main className="l-main">
          <div className={styles.wrapper}>
            <img src={boxes.contatti_topsection.image ? boxes.contatti_topsection.image : mappa} alt="mappa" />
            <div
              className={styles.info}
              dangerouslySetInnerHTML={{
                __html: boxes.contatti_topsection.body,
              }}
            />
          </div>
          <div className={styles['form-wrapper']}>
            <h1
              style={{
                fontSize: '24px',
                margin: '2em 0 1em 0',
                textTransform: 'uppercase',
                color: '#fff',
              }}
            >
              {match?.params?.category
                ? `manda una mail a ${decodeURI(match.params.ragsoc)}`
                : 'mandaci una mail'}
            </h1>
            <ContactForm
              hideCat={match?.params?.ragsoc}
              catID={match?.params?.category}
            />
          </div>
          <div className="c-home-retailers" style={{ display: 'flex' }}>
            <CentriBox data={boxesObj.static_subbanners.home_subbanner1} />
            <AgentsBox data={boxesObj.static_subbanners.home_subbanner2} />
          </div>
        </main>

        <MainFooter dynamicFooter={boxesObj.static_footer || {}} />
      </Fragment>
    );
  }

  return <Loader />;
}
