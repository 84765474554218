import React, { Fragment, useState, useEffect } from 'react';

import MainFooter from '../../shared/components/MainFooter';
import useAgents from '../../shared/hooks/useAgents';
import ListItem from '../CentriMufe/MufeMap/components/ListItem';
import EuropeSvg from '../../shared/components/europe.svg';
import styles from './AgentiList.module.css';
import italianRegions from '../../assets/italian-regions.json';
import { AgentsAutoComplete } from '../../shared/components/Distribution/AgentsBox';
import ContactForm from '../Contacts/components/ContactForm';
import MetaTags from '../../shared/components/MetaTags';
import * as endpoints from '../../shared/constants/endpoints';
import usePageData from '../../shared/hooks/usePageData';

export default function AgentiList({ match, history }) {
  const [agents, distributors] = useAgents(match);
  const [activeID, setActiveID] = useState('');
  const [, , generic, boxesObj] = usePageData(
    `${endpoints.page}/${endpoints.distributionPage}`,
  );

  useEffect(() => {
    if (match.params.search) {
      const activeProvs = decodeURI(match.params.search)?.split('|');
      const activeRegion = Object.keys(italianRegions).find(regID =>
        activeProvs.some(prov => italianRegions[regID].province.includes(prov)),
      );
      if (activeRegion) {
        setActiveID(activeRegion);
      } else {
        setActiveID(match.params.search);
      }
    }
  }, [match.params.search]);

  return (
    <div>
      <MetaTags data={generic} />
      <div className={`c-agenti-container ${styles['main-container']}`}>
        <div className={styles['italy-map']}>
          <EuropeSvg activeID={activeID} history={history} />
        </div>

        <article
          style={{
            overflowY: 'scroll',
            height: '600px',
            flex: 1,
            paddingTop: '25px',
          }}
        >
          <div className="l-container">
            {!!agents.length && (
              <Fragment>
                <header className="c-agenti-distributori__header">
                  <h2 className="c-title">agenti diretti</h2>
                </header>

                <ul className="c-agenti-distributori__list">
                  {agents.map(a => (
                    <ListItem item={a} key={a.ragsoc} noInfo />
                  ))}
                </ul>
              </Fragment>
            )}

            {!!distributors.length && (
              <Fragment>
                <header className="c-agenti-distributori__header">
                  <h2 className="c-title">distributori</h2>
                </header>
                <ul className="c-agenti-distributori__list">
                  {distributors.map(s => (
                    <ListItem item={s} key={s.ragsoc} noInfo />
                  ))}
                </ul>
              </Fragment>
            )}
          </div>
        </article>
      </div>

      <div className="c-distribution-search c-distribution-search--special">
        <div className="c-distribution-search__content l-container">
          <p>Effettua un'altra ricerca.</p>
          <AgentsAutoComplete style={{ margin: '2rem auto' }} />
        </div>
      </div>
      <div className="l-container">
        <section className="c-distribution">
          <header className="c-distribution__header">
            <h2 className="c-distribution__header__title">
              sei interessato a diventare un nostro agente o rivendere i
              prodotti make up for ever?
            </h2>
            <p>
              Compila il form sottostante con i tuoi riferimenti e sarai
              ricontattato al più presto dal nostro Staff per ricevere
              informazioni e assistenza.
            </p>
          </header>
          <ContactForm
            hideCat
            catID={process.env.REACT_APP_CATEGORY_FORM_DISTRIBUZIONE}
            style={{
              backgroundColor: '#dddddd',
              padding: '30px',
              width: '100%',
              justifyContent: 'center',
            }}
            FooterComp={({ onClick }) => (
              <footer
                className="c-agent-form__footer"
                style={{ margin: '2em' }}
              >
                <button type="button" className="c-button" onClick={onClick}>
                  Invia
                </button>
              </footer>
            )}
          />
        </section>
      </div>

      <MainFooter dynamicFooter={boxesObj.static_footer} />
    </div>
  );
}
